import React, { useEffect, useState, useContext } from "react";
import API from "../../../../utils/API";
import UAUserWorkouts from "../../../../utils/components/UAUserWorkouts";
import { TranslateContext } from "../../../../utils/context/TranslateContext";

const Workouts = () => {
  const [workouts, setWorkouts] = useState([]);
  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    API.getSportCategories().then(({ data: res }) => {
      const workoutList = res.data.map((item) => ({
        id: item.id,
        category_name: item.value,
        icon: item.icon,
      }));
      setWorkouts(workoutList.filter((item) => item.id));
    });
  }, []);

  return (
    <div className="CommunityWorkouts single-content-holder">
      <h4 className="content-title text-center fw-bold">
        {uaTranslate("app", "workouts_workouts")}
      </h4>
      <UAUserWorkouts workouts={workouts} />
    </div>
  );
};

export default Workouts;
