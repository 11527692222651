import getIcon from "../../../../utils/helpers/getIcon";

const PageHeader = ({ categoryName, handleShowFilters, uaTranslate }) => {
  return (
    <div className="page-header d-flex align-items-center justify-content-between">
      <h6 className="selected-category-name m-0">{categoryName}</h6>
      <button className="filter-button m-0" onClick={handleShowFilters}>
        {getIcon("filter")} {uaTranslate("app", "workouts_filter")}
      </button>
    </div>
  );
};

export default PageHeader;
