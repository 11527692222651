import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import UAButton from "../../../utils/components/UAButton";
import UAInput from "../../../utils/components/UAInput";
import getRoute from "../../../utils/helpers/getRoute";
import validateInput from "../../../utils/helpers/validateInput";

import { v1 } from "uuid";
import Loading from "../../../utils/components/Loading";
import UACaptchaForm from "../../../utils/components/UACaptchaForm";
import API_V2 from "../../../utils/API_V2";
import API from "../../../utils/API";
import { connect } from "react-redux";
import { login } from "../../../utils/redux/auth/authActions";

import LoginWithSocialMedias from "../../../utils/components/LoginWithSocialMedias";

const Register = ({ translate, login }) => {
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: {
      countryCode: "+90",
      number: "",
    },
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({});

  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [captchaError, setCaptchaError] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (
      userInfo.firstName &&
      userInfo.lastName &&
      userInfo.email &&
      userInfo.password
    ) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }
  }, [userInfo, errors, success]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newUserInfo = { ...userInfo };

    if (name === "phone") {
      newUserInfo.phone.number = value;
    } else {
      newUserInfo[name] = value;
    }

    setUserInfo(newUserInfo);
  };

  const handleSubmit = (e, captcha) => {
    e.preventDefault();

    if (!captcha) return;

    let errors = {};
    let success = {};

    Object.keys(userInfo).forEach((key) => {
      const value =
        key === "phone"
          ? userInfo[key]?.countryCode + userInfo[key]?.number
          : userInfo[key];

      if (key !== "phone" && !value) {
        errors[key] = `${translate(key)} is required  `;
      }

      let validation = validateInput(value, {
        notEmpty: true,
        minLength: key === "password" ? 6 : 2,
      });

      if (validation && key === "email") {
        validation = validateInput(value, {
          isEmail: true,
        });
      }

      if (validation && key === "phone" && value) {
        validation = validateInput(value, {
          isPhone: true,
        });
      }

      if (validation) success[key] = true;
      else {
        if (key === "email") {
          errors[key] = translate("register.errors.email");
        } else if (key === "phone" && userInfo[key].number) {
          errors[key] = translate("register.errors.phone");
        } else if (key === "password") {
          errors[key] = translate("register.errors.password", { 0: 6 });
        } else if (
          !validateInput(value, {
            minLength: 2,
          })
        ) {
          errors[key] = translate("register.errors.short_value", {
            0: translate(key),
            1: 2,
          });
        }
      }
    });

    setSuccess(success);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSending(true);
      setCaptchaError(false);

      const { firstName, lastName, email, phone, password } = userInfo;

      const payload = {
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        captcha_token: captcha,
        device_id: v1(),
        device_token: v1(),
        channel: "web",
      };

      if (phone.number) {
        let gsm = phone.countryCode.replace("+", "") + phone.number;
        payload.gsm = gsm;
      }

      API_V2.register(payload)
        .then(({ data: res }) => {
          API.getAccountData(res.data.access_token).then(
            ({ data: accountRes }) => {
              login({ ...res.data, ...accountRes.data });
            }
          );
        })
        .finally(() => setIsSending(false));
    }
  };

  return (
    <div className="Register d-flex flex-column align-items-center">
      <UACaptchaForm
        onSubmit={handleSubmit}
        className="w-100 d-flex flex-column align-items-center"
        skipValidation
        validationError={captchaError}
        captchaAction="register"
      >
        <>
          {Object.keys(userInfo).map((key, index) => (
            <UAInput
              key={index}
              type={
                key === "password"
                  ? "password"
                  : key === "phone"
                  ? "tel"
                  : "text"
              }
              value={key === "phone" ? userInfo[key].number : userInfo[key]}
              onChange={handleChange}
              name={key}
              label={translate(key)}
              inputProps={{
                inputMode:
                  key === "email" ? "email" : key === "phone" ? "tel" : "text",
              }}
              error={key in errors ? errors[key] : false}
              success={key in success ? success[key] : false}
              countryCode={key === "phone" ? userInfo[key].countryCode : ""}
              onCountryCodeChange={(countryCode) => {
                setUserInfo({
                  ...userInfo,
                  phone: {
                    ...userInfo.phone,
                    countryCode,
                  },
                });
              }}
            />
          ))}

          <UAButton
            label={isSending ? <Loading /> : translate("register.register")}
            htmlType="submit"
            disabled={!isButtonActive || isSending}
            classNames="form-submit-button text-uppercase"
          />
        </>
      </UACaptchaForm>

      <LoginWithSocialMedias />

      <div className="already-user color--white">
        {translate("register.login_link", {
          link: (
            <Link
              to={getRoute("login").path}
              className="color--secondary"
              state={{ from: location.state?.from }}
            >
              {translate("register.login_link_text")}
            </Link>
          ),
        })}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(login(user)),
});

export default connect(null, mapDispatchToProps)(Register);
