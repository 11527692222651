import { useNavigate } from "react-router";
import UAButton from "../../../../utils/components/UAButton";
import getIcon from "../../../../utils/helpers/getIcon";
import getRoute from "../../../../utils/helpers/getRoute";
import { useContext } from "react";
import { TranslateContext } from "../../../../utils/context/TranslateContext";

const PremiumError = ({ setShowExitPrompt }) => {
  const navigate = useNavigate();
  const { uaTranslate } = useContext(TranslateContext);

  return (
    <div className="premium-error position-fixed w-100 h-100 d-flex flex-column align-items-center justify-content-center">
      {getIcon("logo", {
        alternative: "light",
        className: "logo position-absolute",
      })}
      <div className="content-area">
        <h3 className="text-center">
          {uaTranslate("app", "paywall_web_workout_title")}
        </h3>
        <p className="text-center">
          {uaTranslate("app", "paywall_web_workout_content")}
        </p>
        <UAButton
          onClick={() => {
            setShowExitPrompt && setShowExitPrompt(false);
            navigate(getRoute("subscribe")?.path, {
              replace: true,
              state: { from: "watch" },
            });
          }}
          label={uaTranslate("app", "paywall_web_workout_button")}
          classNames="mx-auto"
        />
      </div>
    </div>
  );
};
export default PremiumError;
