import { FaChevronRight } from "react-icons/fa";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import getRoute from "../../../../utils/helpers/getRoute";

const WorkoutCard = ({ workout }) => {
  return (
    <div
      className="workout-card d-flex align-items-end position-relative"
      style={{ backgroundImage: `url("${workout?.image}")` }}
    >
      <div className="day position-absolute d-flex justify-content-center align-items-center fw-medium">
        <Translate id="day" /> {workout?.day}
      </div>
      <div className="workout-info position-relative w-100">
        <h3 className="title d-flex justify-content-start align-items-end">
          <Link
            to={
              getRoute("workout").path.replace(":id", workout?.id) +
              `/${workout?.slug}`
            }
            className="stretched-link"
          >
            {workout?.title}
          </Link>
        </h3>

        <div className="trainer fw-medium">{workout?.trainer_name}</div>
      </div>
      <Link
        to={
          getRoute("workout").path.replace(":id", workout?.id) +
          `/${workout?.slug}`
        }
        className="start-button position-absolute d-flex justify-content-center align-items-center fw-medium"
      >
        <Translate id="start" /> <FaChevronRight />
      </Link>
    </div>
  );
};

export default WorkoutCard;
