import { Link } from "react-router-dom";
import UAButton from "../../../../utils/components/UAButton";
import UAInput from "../../../../utils/components/UAInput";
import getIcon from "../../../../utils/helpers/getIcon";
import getRoute from "../../../../utils/helpers/getRoute";
import appleStore from "../../../../assets/images/appleStore.svg";
import googlePlay from "../../../../assets/images/googlePlay.svg";
import { withLocalize } from "react-localize-redux";
import { setLanguage } from "../../../../utils/redux/app/appActions";
import { connect } from "react-redux";
import API from "../../../../utils/API";
import { updateUser } from "../../../../utils/redux/auth/authActions";

const FooterTop = ({
  translate,
  languages,
  activeLanguage,
  setActiveLanguage,
  setLanguage,
  updateUser,
  auth,
}) => {
  const handleSetLanguage = (code) => {
    if (auth?.isAuthenticated)
      API.setLanguage(code).then(({ data: res }) => {
        updateUser({ lang: code });
      });

    setLanguage(code);
    setActiveLanguage(code);
  };
  return (
    <div className="top d-flex flex-column flex-md-row justify-content-between align-items-center align-items-md-center">
      <section className="left d-flex flex-column align-items-center align-items-md-start">
        <Link to={getRoute("home")?.path} className="logo d-flex">
          {getIcon("logo", { className: "mh-100" })}
        </Link>
        <div className="lang-select mb-4 mb-md-0 d-flex justify-content-center align-items-center">
          {languages?.map((lang) => (
            <div
              key={lang.code}
              className="lang-button me-2 d-flex justify-content-center align-items-center"
              role="button"
              data-active={lang.code === activeLanguage?.code}
              onClick={() => {
                handleSetLanguage(lang.code);
              }}
            >
              {getIcon(lang.code, { className: "rounded-circle w-100 h-100" })}
            </div>
          ))}
        </div>
      </section>
      <section className="middle d-none flex-column align-items-start">
        <h5 className="section-title">{translate("subscribe")}</h5>
        <div className="input-area d-flex align-items-center">
          <UAInput label={translate("email")} bordered />
          <UAButton label={translate("subscribe")} />
        </div>
        <p className="disclaimer m-0 w-100">
          {translate("footer.subscription_disclaimer")}
        </p>
      </section>
      <section className="right d-flex flex-column align-items-center align-items-md-end">
        <h5 className="section-title">{translate("footer.our_mobile_apps")}</h5>
        <div className="icons d-flex align-items-center justify-content-end">
          <a
            href="https://apps.apple.com/tr/app/urban-active/id1540373103"
            target="_blank"
            rel="noopener noreferrer"
            className="me-3"
          >
            <img src={appleStore} alt="Apple Store Badge" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.epigra.ulive"
            target="_blank"
            rel="noopener noreferrer"
            className="m-0"
          >
            <img src={googlePlay} alt="Google Play Badge" />
          </a>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (code) => dispatch(setLanguage(code)),
  updateUser: (user) => dispatch(updateUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(FooterTop));
