import {
  differenceInMilliseconds,
  formatDistanceToNowStrict,
  isValid,
} from "date-fns";
import { useContext, useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import { TranslateContext } from "../../../../utils/context/TranslateContext";
import checkIfDateIsValid from "../../../../utils/helpers/checkIfDateIsValid";
import fixDateFormatForSafari from "../../../../utils/helpers/fixDateFormatForSafari";
import getIcon from "../../../../utils/helpers/getIcon";
import getRoute from "../../../../utils/helpers/getRoute";

const LiveWorkout = ({ workout }) => {
  const [hasStarted, setHasStarted] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);

  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    if (isValid(new Date(fixDateFormatForSafari(workout?.starts_at)))) {
      setHasStarted(
        differenceInMilliseconds(
          new Date(fixDateFormatForSafari(workout?.starts_at)),
          new Date()
        ) <= 0
      );
    }

    if (isValid(new Date(workout?.ends_at))) {
      setHasEnded(
        differenceInMilliseconds(new Date(workout?.ends_at), new Date()) <= 0
      );
    }
  }, [workout?.ends_at, workout?.starts_at]);

  return (
    <div className="live-workout position-relative d-flex align-items-end">
      <Link
        to={
          getRoute("workout").path.replace(":id", workout?.id) +
          `/${workout?.slug}`
        }
        className="workout-image position-absolute w-100 h-100 overflow-hidden d-flex justify-content-center align-items-center stretched-link"
      >
        <img src={workout?.image} alt={workout?.title} className="h-100" />
      </Link>
      {hasStarted && !hasEnded && (
        <div className="live-badge position-absolute bg--red color--white d-flex justify-content-center align-items-center fw-semibold">
          {uaTranslate("app", "workout_live")}
        </div>
      )}
      <div className="workout-difficulty position-absolute fw-semibold color--gray">
        {uaTranslate("difficulty", workout?.difficulty)}{" "}
        <Translate id="level" />
      </div>
      <div className="workout-info position-relative w-100">
        <h3 className="workout-title fw-bold">
          <Link
            to={
              getRoute("workout").path.replace(":id", workout?.id) +
              `/${workout?.slug}`
            }
            className="stretched-link"
          >
            {workout?.title}
          </Link>
        </h3>
        <div className="info-bottom d-flex justify-content-between align-items-end">
          <div className="left d-flex justify-content-start align-items-center">
            <div className="duration">
              <Translate id="workouts.min" data={{ min: workout?.duration }} />
            </div>
            <div className="trainer">{workout?.trainer}</div>
            <div className="starts-at">
              {!hasStarted ? (
                checkIfDateIsValid(
                  fixDateFormatForSafari(workout?.starts_at)
                ) ? (
                  formatDistanceToNowStrict(
                    new Date(fixDateFormatForSafari(workout?.starts_at)),
                    {
                      addSuffix: true,
                    }
                  )
                ) : null
              ) : hasEnded ? (
                checkIfDateIsValid(workout?.ends_at) ? (
                  formatDistanceToNowStrict(new Date(workout?.ends_at), {
                    addSuffix: true,
                  })
                ) : null
              ) : (
                <Translate id="now" />
              )}
            </div>
          </div>
          <div className="right">
            <div className="notification d-none">
              {!hasStarted && !hasEnded
                ? getIcon("notificationBell", { alternative: "green" })
                : !hasEnded && (
                    <Countdown
                      date={workout?.ends_at}
                      onComplete={() => setHasEnded(true)}
                      zeroPadTime={2}
                      renderer={({ hours, minutes, seconds }) => (
                        <span className="color--red fw-semibold">
                          {zeroPad(minutes + hours * 60)}:{zeroPad(seconds)}
                        </span>
                      )}
                    />
                  )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveWorkout;
