import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import getIcon from "../../../../utils/helpers/getIcon";
import getRoute from "../../../../utils/helpers/getRoute";
import { useContext } from "react";
import { TranslateContext } from "../../../../utils/context/TranslateContext";
import fixDateFormatForSafari from "../../../../utils/helpers/fixDateFormatForSafari";
import { format } from "date-fns";

const Workout = ({ workout }) => {
  const { uaTranslate } = useContext(TranslateContext);

  return (
    <div
      className="workout position-relative overflow-hidden d-flex justify-content-between"
      role="button"
    >
      <div className="workout-bg position-absolute w-100 h-100" />
      <div className="info-area position-relative d-flex flex-column">
        <h3 className="name">
          <Link
            to={
              getRoute("workout").path.replace(":id", workout.id) +
              `/${workout.slug || ""}`
            }
            className="stretched-link"
          >
            {workout.name}
          </Link>
        </h3>
        <div className="details d-flex justify-content-start align-items-center">
          <div className="duration">
            <Translate id="workouts.min" data={{ min: workout.duration }} />
          </div>
          <h4 className="instructor m-0">{workout.instructor.name}</h4>
        </div>
        <div className="actions d-flex justify-content-start align-items-center">
          <div className="bookmark-icon d-none" role="button">
            {getIcon("bookmark")}
          </div>
          <div className="notification-icon d-none" role="button">
            {getIcon("notificationBell")}
          </div>
          <div className="difficulty text-lowercase fw-semibold">
            {uaTranslate("difficulty", workout.difficulty)}
          </div>
        </div>
        <span className="date">
          {format(
            new Date(fixDateFormatForSafari(workout.createdAt)),
            "dd.MM.yyyy"
          )}
        </span>
      </div>
      <div className="right-bg position-absolute" />
      {workout.isFree && (
        <div className="free-badge fw-semibold position-absolute text-uppercase">
          {uaTranslate("app", "workout_free")}
        </div>
      )}
      <div className="image-area d-flex justify-content-center align-items-end flex-shrink-0">
        <Link
          to={
            getRoute("workout").path.replace(":id", workout.id) +
            `/${workout.slug || ""}`
          }
          className="stretched-link"
        >
          <img
            src={workout.instructor.image}
            alt={workout.instructor.name}
            className="mw-100"
          />
        </Link>
      </div>
      <div className="play-icon position-absolute d-flex justify-content-center align-items-center">
        {getIcon("play")}
      </div>
    </div>
  );
};

export default Workout;
