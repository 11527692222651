import { useContext, useEffect } from "react";
import { Navigate, useParams } from "react-router";
import getRoute from "../../utils/helpers/getRoute";
import toastr from "toastr";
import { TranslateContext } from "../../utils/context/TranslateContext";
import API_V2 from "../../utils/API_V2";

const Payment = () => {
  const { type } = useParams();
  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    if (type === "success") {
      API_V2.postData("payment/complete", {}).then(() => {
        toastr.success(uaTranslate("app", "payment_success_message"));
      });
    }
  }, [type, uaTranslate]);

  return <Navigate to={getRoute("subscription")?.path}/>;
};
export default Payment;
