import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const Categories = ({ categories, activeCategory, setActiveCategory }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const activeCategoryElement = document.querySelector(
      ".categories .category[data-is-active='true']"
    );

    if (activeCategoryElement) {
      activeCategoryElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });

      const activeCategoryBgElement = document.querySelector(
        ".categories .active-category-bg"
      );

      activeCategoryBgElement.style.left = `${activeCategoryElement.offsetLeft}px`;
      activeCategoryBgElement.style.top = `${activeCategoryElement.offsetTop}px`;
    }
  }, [activeCategory, windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="categories w-100 d-flex justify-content-start justify-content-md-center align-items-center position-relative">
      <div
        className={`active-category-bg position-absolute ${
          !categories.length ? "opacity-0" : ""
        }`}
      />

      {categories.map((item) => (
        <div
          key={item.id}
          className="category position-relative d-flex flex-column flex-shrink-0 align-items-center justify-content-start"
          data-is-active={activeCategory === item.id}
          role="button"
          onClick={() => setActiveCategory(item.id)}
          data-testid={`category-${item.id}`}
        >
          <div className="category-image">
            <img src={item.icon} alt={item.name} className="mw-100 mh-100" />
          </div>
          <div className="category-name text-center">{item.name}</div>
        </div>
      ))}
    </div>
  );
};

Categories.defaultProps = {
  categories: [],
};

Categories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
  activeCategory: PropTypes.any,
  setActiveCategory: PropTypes.func.isRequired,
};

export default Categories;
