const data = [10, 15, 20, 30, 40, 50, 60, 90];

const Duration = ({ selectedFilters, handleFilterChange, translate }) => {
  return (
    <div className="durations d-grid">
      {data.map((duration, index) => (
        <div
          key={index}
          className="duration d-flex flex-column justify-content-center align-items-center"
          data-is-selected={selectedFilters.some(
            (filter) => filter.id === duration
          )}
          onClick={() => handleFilterChange({ id: duration, name: duration })}
          role="button"
        >
          <div className="number">{duration}</div>
          <span className="title">{translate("minutes")}</span>
        </div>
      ))}
    </div>
  );
};

export default Duration;
