import { NavLink } from "react-router-dom";
import getIcon from "../../../../utils/helpers/getIcon";
import getRoute from "../../../../utils/helpers/getRoute";

const MobileNav = ({ translate }) => {
  return (
    <nav className="mobile-nav d-flex d-lg-none justify-content-between align-items-end position-fixed vw-100">
      <div className="left w-50 d-flex justify-content-around align-items-end">
        <NavLink
          to={getRoute("workouts").path}
          className="mobile-nav-link d-flex flex-column justify-content-center align-items-center fw-semibold position-relative"
        >
          {getIcon("mobileNavWorkouts")}
          <span className="position-absolute">{translate("nav.workouts")}</span>
        </NavLink>
        <NavLink
          to={getRoute("programs").path}
          className="mobile-nav-link d-flex flex-column justify-content-center align-items-center fw-semibold position-relative"
        >
          {getIcon("mobileNavPrograms")}
          <span className="position-absolute">{translate("nav.programs")}</span>
        </NavLink>
      </div>
      <div className="middle flex-shrink-0 d-flex justify-content-center align-items-center">
        <NavLink
          to={getRoute("home").path}
          className="home-link text-uppercase rounded-circle d-flex justify-content-center align-items-center"
          end
        >
          {getIcon("logo", {
            alternative:
              window.location.pathname === "/" ? "singleGreen" : "singleBlack",
          })}
        </NavLink>
      </div>
      <div className="right w-50 d-flex justify-content-around align-items-end">
        <NavLink
          to={getRoute("trainers").path}
          className="mobile-nav-link d-flex flex-column justify-content-center align-items-center fw-semibold position-relative"
        >
          {getIcon("mobileNavTrainers")}
          <span className="position-absolute">{translate("nav.trainers")}</span>
        </NavLink>
        <NavLink
          to={getRoute("community").path}
          className="mobile-nav-link d-flex flex-column justify-content-center align-items-center fw-semibold position-relative"
        >
          {getIcon("mobileNavCommunity")}
          <span className="position-absolute">
            {translate("nav.community")}
          </span>
        </NavLink>
      </div>
    </nav>
  );
};

export default MobileNav;
