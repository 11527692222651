import { Component } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "../i18n/globalTranslations.json";

import pageTitleTR from "../i18n/tr/page_title.json";
import pageTitleEN from "../i18n/en/page_title.json";

import authTR from "../i18n/tr/auth.json";
import authEN from "../i18n/en/auth.json";

import navTR from "../i18n/tr/nav.json";
import navEN from "../i18n/en/nav.json";

import headerTR from "../i18n/tr/header.json";
import headerEN from "../i18n/en/header.json";

import footerTR from "../i18n/tr/footer.json";
import footerEN from "../i18n/en/footer.json";

import workoutsTR from "../i18n/tr/workouts.json";
import workoutsEN from "../i18n/en/workouts.json";

import homeTR from "../i18n/tr/home.json";
import homeEN from "../i18n/en/home.json";

import profileTR from "../i18n/tr/profile.json";
import profileEN from "../i18n/en/profile.json";

class LanguageFiles extends Component {
  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: "Türkçe", code: "tr" },
        { name: "English", code: "en" },
      ],
      translation: globalTranslations,
      options: { renderToStaticMarkup },
    });
  }

  componentDidMount = () => {
    this.props.addTranslationForLanguage(pageTitleTR, "tr");
    this.props.addTranslationForLanguage(pageTitleEN, "en");

    this.props.addTranslationForLanguage(authTR, "tr");
    this.props.addTranslationForLanguage(authEN, "en");

    this.props.addTranslationForLanguage(navTR, "tr");
    this.props.addTranslationForLanguage(navEN, "en");

    this.props.addTranslationForLanguage(headerTR, "tr");
    this.props.addTranslationForLanguage(headerEN, "en");

    this.props.addTranslationForLanguage(footerTR, "tr");
    this.props.addTranslationForLanguage(footerEN, "en");

    this.props.addTranslationForLanguage(workoutsTR, "tr");
    this.props.addTranslationForLanguage(workoutsEN, "en");

    this.props.addTranslationForLanguage(homeTR, "tr");
    this.props.addTranslationForLanguage(homeEN, "en");

    this.props.addTranslationForLanguage(profileTR, "tr");
    this.props.addTranslationForLanguage(profileEN, "en");
  };

  render() {
    return <></>;
  }
}

export default withLocalize(LanguageFiles);
