import { useEffect, useState } from "react";
import API from "../API";

const UAPinsCategory = ({ pins }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    API.getSportCategories().then(({ data: res }) => {
      setCategories(res.data.filter((item) => item.id));
    });
  }, []);

  return categories
    .filter(
      (category) =>
        pins.filter((pin) => pin.sport_category_id === category.id).length
    )
    .map((category) => (
      <div className="category" key={category.id}>
        <div className="category-title text-start fw-semibold">
          {category.value}
        </div>
        <div className="category-pins d-grid w-100">
          {pins
            .filter((pin) => pin.sport_category_id === category.id)
            .sort((a, b) => a.limit - b.limit)
            .map((pin) => (
              <div className="pin w-100" key={pin.id}>
                <div
                  className="d-flex flex-column align-items-center content"
                  data-is-earned={pin.is_badge_earned}
                >
                  <img src={pin.icon} alt="" className="w-100" />
                  <p className="text-center">{pin.name}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    ));
};
export default UAPinsCategory;
