import { useContext } from "react";
import { TranslateContext } from "../../../context/TranslateContext";

const Summary = ({ selectedSubscription }) => {
  const { uaTranslate } = useContext(TranslateContext);
  return (
    <section className="Paywall__content__section d-flex flex-column w-100">
      <h4 className="Paywall__content__section--title">
        {uaTranslate("app", "paywall_web_summary_title")}
      </h4>
      <div className="summary">
        <div className="summary__item d-flex justify-content-between align-items-center">
          <h6 className="summary__item--title m-0">
            {selectedSubscription?.title}{" "}
            {uaTranslate("app", "paywall_web_subscription_fee")}
          </h6>
          <span className="summary__item--price">
            {selectedSubscription?.price} {selectedSubscription?.currency}
          </span>
        </div>
        <div className="summary__item d-flex justify-content-between align-items-center">
          <h6 className="summary__item--title m-0">
            {uaTranslate("app", "paywall_web_summary_tax")}
          </h6>
          <span className="summary__item--price">
            {selectedSubscription?.tax} {selectedSubscription?.currency}
          </span>
        </div>
        <div className="summary__item summary__item__total d-flex justify-content-between align-items-center">
          <h6 className="summary__item__total--title m-0">
            {uaTranslate("app", "paywall_web_summary_total")}
          </h6>
          <span className="summary__item--price">
            {selectedSubscription?.totalPrice} {selectedSubscription?.currency}
          </span>
        </div>
      </div>
    </section>
  );
};

export default Summary;
