import React from "react";
import { socialMediaEnvs } from "../db/socialMediaEnvs";
import PropTypes from "prop-types";
import LoginWithGoogle from "./LoginWithGoogle";
import { handleLoginWithSocialMedia } from "../helpers/loginOrRegisterWithSocialAccount";
import LoginWithApple from "./LoginWithApple";

const SingleSocialMedia = ({ platform, order, className = "" }) => {
  const stableProps = React.useMemo(() => {
    return {
      isOnlyGetToken: true,
      client_id: socialMediaEnvs[platform],
      className: `icon order-${order} ${className}`,
    };
  }, [className, order, platform]);

  switch (platform) {
    case "google":
      return (
        <LoginWithGoogle
          onResolve={({ provider, profile }) =>
            handleLoginWithSocialMedia({ provider, token: profile.credential })
          }
          {...stableProps}
        />
      );

    case "apple":
      return (
        <LoginWithApple
          {...stableProps}
          handleLogin={handleLoginWithSocialMedia}
        />
      );

    default:
      return null;
  }
};

export default SingleSocialMedia;

SingleSocialMedia.propTypes = {
  platform: PropTypes.oneOf(["google", "apple"]).isRequired,
  order: PropTypes.number.isRequired,
  className: PropTypes.string,
};
