import { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import UAProfileShort from "../../../utils/components/UAProfileShort";
import getRoute from "../../../utils/helpers/getRoute";

const CommunityLayout = ({ className }) => {
  const [profile, setProfile] = useState(null);
  const [communityPageIsMyProfile, setCommunityPageIsMyProfile] =
    useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isCommunityPage = Object.values(getRoute("community").paths).some(
      (page) => page === pathname
    );

    if (isCommunityPage) {
      const newPath = getRoute("community").pages?.filter(
        (page) => !page.isIndex
      )[0].path;
      if (newPath) navigate(newPath);
    }

    const isMyProfilePage = pathname?.includes(
      getRoute("community-my-profile", null, getRoute("community"))?.path
    );
    setCommunityPageIsMyProfile(isMyProfilePage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div
      className={`CommunityLayout d-flex flex-column align-items-center ${className}`}
    >
      {!communityPageIsMyProfile && (
        <nav className="page-nav w-100 d-flex justify-content-center align-items-center">
          {getRoute("community")
            ?.pages?.filter((page) => page.onCommunityNav && !page.isIndex)
            ?.map((route) => (
              <NavLink key={route.id} to={route.path} className="item">
                <Translate id={`page_title.${route.name}`} />
              </NavLink>
            ))}
        </nav>
      )}
      <div className="page-content w-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <UAProfileShort
                setCommunityProfile={setProfile}
                communityPageIsMyProfile={communityPageIsMyProfile}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Outlet context={{ profile }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityLayout;
