import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { LocalizeProvider } from "react-localize-redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store";
import LanguageFiles from "./utils/components/LanguageFiles";
import { TranslateProvider } from "./utils/context/TranslateContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizeProvider>
        <BrowserRouter>
          <HelmetProvider>
            <TranslateProvider>
              <LanguageFiles />
              <App />
            </TranslateProvider>
          </HelmetProvider>
        </BrowserRouter>
      </LocalizeProvider>
    </Provider>
  </React.StrictMode>
);
