import React, { useEffect } from "react";
const BASE_URL = process.env.REACT_APP_URL;
const APPLE_ID = process.env.REACT_APP_APPLE_ID;

const LoginWithApple = ({
  client_id,
  onResolve,
  className = "",
  handleLogin,
}) => {
  useEffect(() => {
    if (window.AppleID.auth) {
      window.AppleID.auth.init({
        clientId: APPLE_ID,
        scope: "name email",
        redirectURI: `${BASE_URL}/giris-yap`,
        state: "Initial user authentication request",
        nonce: "authorization_code",
        usePopup: true,
      });

      document.addEventListener("AppleIDSignInOnSuccess", (event) => {
        handleLogin({
          provider: "apple",
          token: event.detail.authorization.code,
          firstname: event.detail.user?.name?.firstName,
          lastname: event.detail.user?.name?.lastName,
        });
      });

      document.addEventListener("AppleIDSignInOnFailure", (event) => {
        console.log("Error ", event);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_id, onResolve, window?.AppleID?.auth]);

  return (
    <div
      id="appleid-signin"
      className={`${className} apple-icon`}
      data-mode="logo-only"
      data-color="white"
      data-border="false"
      data-border-radius="50"
      data-size="42"
    ></div>
  );
};

export default LoginWithApple;
