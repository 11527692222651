import { Link } from "react-router-dom";
import Loading from "../../../../utils/components/Loading";
import UAButton from "../../../../utils/components/UAButton";
import getRoute from "../../../../utils/helpers/getRoute";

const Code = ({
  handleSubmitCode,
  translate,
  emailOrPhone,
  code,
  handleSetCode,
  codeSent,
  codeChecking,
}) => {
  if (codeSent) return null;

  return (
    <form
      onSubmit={handleSubmitCode}
      className="Confirm d-flex flex-column align-items-center"
    >
      <div className="page-desc color--white text-center">
        {translate("confirm.page_desc")}
      </div>

      <div className="phone color--white">{emailOrPhone}</div>

      <div className="code-area d-flex justify-content-center align-items-center">
        {code.map((item, index) => (
          <input
            key={index}
            type="text"
            id={`code-${index}`}
            maxLength={1}
            value={item}
            onChange={(e) => handleSetCode(e, index)}
            className="text-center"
            onKeyDown={(e) => {
              if (
                (e.key === "Backspace" || e.key === "ArrowLeft") &&
                index > 0 &&
                !item
              ) {
                e.preventDefault();
                document.getElementById(`code-${index - 1}`).focus();
                document.getElementById(`code-${index - 1}`).select();
              } else if (e.key === "ArrowRight" && index < 3) {
                e.preventDefault();
                document.getElementById(`code-${index + 1}`).focus();
                document.getElementById(`code-${index + 1}`).select();
              }
            }}
            disabled={codeSent}
            inputMode="numeric"
          />
        ))}
      </div>
      <UAButton
        htmlType="submit"
        disabled={code.some((c) => c === "") || codeChecking}
        label={codeChecking ? <Loading /> : translate("confirm.confirm")}
        classNames="text-uppercase"
      />

      <div className="already-user text-center color--white">
        {translate("forgot_password.login_link", {
          link: (
            <Link to={getRoute("login").path} className="color--secondary">
              {translate("forgot_password.login_link_text")}
            </Link>
          ),
        })}
      </div>
    </form>
  );
};

export default Code;
