import Cookies from "universal-cookie";

const cookie = new Cookies();

const initState = {
  isLoading: false,
  isError: false,
  language: cookie.get("language") || "tr",
  canBeSubscribed:
    process.env.REACT_APP_ENV_TYPE === "stage" ||
    process.env.REACT_APP_ENV_TYPE === "test" ||
    process.env.REACT_APP_ENV_TYPE === "production",
};

const appReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      cookie.set("language", action.language, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
        sameSite: "strict",
      });

      return {
        ...state,
        language: action.language,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_ERROR":
      return {
        ...state,
        isError: action.isError,
      };
    default:
      return state;
  }
};

export default appReducer;
