import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import Categories from "./components/Categories";
import PageHeader from "./components/PageHeader";
import Workout from "./components/Workout";
import API from "../../../utils/API";
import InfiniteScroll from "react-infinite-scroller";
import Loading from "../../../utils/components/Loading";
import UASidebar from "../../../utils/components/UASidebar";
import Filters from "./components/Filters";
import { useLocation, useNavigate, useParams } from "react-router";

import { default as WorkoutDetail } from "./Workout";
import UAModal from "../../../utils/components/UAModal";
import getRoute from "../../../utils/helpers/getRoute";
import { CSSTransition } from "react-transition-group";

import Watch from "./Watch";
import LiveWorkout from "./LiveWorkout";
import UALoginModal from "../../../utils/components/UALoginModal";
import { TranslateContext } from "../../../utils/context/TranslateContext";

const MAP_API_TO_FILTERS = {
  sort: "sort",
  difficulty: "difficulty",
  tmin: "duration",
  tmax: "duration",
  trainer_id: "trainer",
  body_region: "regional",
};

const Workouts = ({ translate, user, language }) => {
  const [activeCategory, setActiveCategory] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [workouts, setWorkouts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFetchingWorkouts, setIsFetchingWorkouts] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [filters, setFilters] = useState({
    difficulty: null,
    duration: null,
    regional: null,
    sort: null,
    trainer: null,
  });
  const [workout, setWorkout] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWorkoutsFiltered, setIsWorkoutsFiltered] = useState(false);

  const { id, watch } = useParams();
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const watchRef = useRef(null);
  const loginModalRef = useRef(null);

  const { uaTranslate } = useContext(TranslateContext);

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.trainerId) {
      setFilters({
        ...filters,
        trainer: location?.state?.trainerId,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state]);

  useEffect(() => {
    if (id) {
      API.getData(`/workouts/${id}`).then(({ data: res }) => {
        setWorkout({
          id,
          title: res.data.title,
          description: res.data.description,
          duration: res.data.duration,
          difficulty: res.data.difficulty,
          cover: res.data.is_live ? res.data.image_list : res.data.image_detail,
          trainer: {
            id: 1,
            name: res.data.trainer_name,
          },
          equipment: res.data.equipments,
          training: res.data.instructions,
          isBookmarked: res.data.is_subscribed,
          slug: res.data.slug,
          isLive: res.data.is_live,
          startsAt: res.data.starts_at,
        });
      });

      setIsModalOpen(true);
    }

    return () => {
      setIsModalOpen(false);
    };
  }, [id, language]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    let filtersFromUrl = searchParams.get("filters");

    if (filtersFromUrl) {
      let filtersArr = filtersFromUrl.split("|");

      const newFilters = {};

      filtersArr.forEach((filter) => {
        const [filterKey, filterValue] = filter.split(":");

        if (MAP_API_TO_FILTERS[filterKey]) {
          if (filterKey === "tmin" || filterKey === "tmax") {
            newFilters.duration = {
              ...newFilters.duration,
              [filterKey === "tmax" ? "max" : "min"]: parseInt(filterValue),
            };
          } else {
            newFilters[MAP_API_TO_FILTERS[filterKey]] = filterValue;
          }
        }
      });

      setFilters(newFilters);
    }
  }, []);

  useEffect(() => {
    API.getSportCategories().then(({ data: res }) => {
      setCategories(
        res.data.map((item) => ({
          id: item.id,
          name: item.value,
          icon: item.icon,
          thumbnail: item.thumbnail,
        }))
      );

      setActiveCategory(res.data[0].id);
    });
  }, [language]);

  useEffect(() => {
    setWorkouts([]);
    setIsFetchingWorkouts(true);
    handleGetWorkouts(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory, filters, language]);

  useEffect(() => {
    const isWorkoutsFiltered = Object.values(filters).some(
      (filter) => filter !== null
    );

    setIsWorkoutsFiltered(isWorkoutsFiltered);
  }, [filters]);

  const handleGetWorkouts = (resetWorkouts = false) => {
    if (activeCategory === undefined) return false;

    const params = {
      sort: filters.sort || "",
      difficulty: filters.difficulty || "",
      body_region: filters.regional || "",
      trainer_id: filters.trainer || "",
      tmax: filters.duration?.max ? filters.duration.max + 1 : "",
      tmin: filters.duration?.min ? filters.duration.min - 1 : "",
      sport_category_id: activeCategory,
      languages_filter: filters.contentLanguage || "",
      page,
    };

    if (!params.tmin && params.tmax) {
      params.tmin = params.tmax - 2;
    }

    let url = "workouts?";
    // let filterUrl = "?filters=";

    Object.entries(params).forEach(([key, value]) => {
      if (value !== "")
        if (url.endsWith("?")) {
          url += `${key}=${value}`;
          // filterUrl += `${key}:${value}`;
        } else {
          url += `&${key}=${value}`;
          // filterUrl += `|${key}:${value}`;
        }
    });

    // navigate(encodeURI(filterUrl));

    API.getData(url)
      .then(({ data: res }) => {
        const prevWorkouts = resetWorkouts ? [] : workouts;
        setWorkouts([
          ...prevWorkouts,
          ...res.data.map((item) => ({
            id: item.id,
            name: item.title,
            duration: item.duration,
            instructor: {
              name: item.trainer_name,
              image: item.image_list,
            },
            slug: item.slug,
            isFree: item.is_free,
            isLive: item.is_live,
            isCompleted: item.is_completed,
            difficulty: item.difficulty,
            createdAt: item.created_at,
          })),
        ]);

        setTotalPages(res.pagination.total_pages);
      })
      .finally(() => setIsFetchingWorkouts(false));
  };

  const loadMore = () => {
    if (isFetchingWorkouts) return;

    if (page < totalPages) {
      setPage(page + 1);
      setIsFetchingWorkouts(true);
      handleGetWorkouts();
    }
  };

  const handleSetFilters = (filters) => {
    const newFilters = {
      duration: null,
      difficulty: filters.difficulty?.id || null,
      regional: filters.regional?.id || null,
      sort: filters.sort?.id || null,
      trainer: filters.trainer?.id || null,
      contentLanguage: filters.contentLanguage?.id || null,
    };

    if (filters.duration?.length) {
      const min = Math.min(...filters.duration.flatMap((item) => item.id));
      const max = Math.max(...filters.duration.flatMap((item) => item.id));

      if (min === max)
        newFilters.duration = {
          min: null,
          max,
        };
      else newFilters.duration = { min, max };
    }

    setFilters(newFilters);
    setIsSidebarOpen(false);
  };

  const beforeCloseWatch = () => API.postData(`workouts/${id}/completed`);

  return (
    <div className="Workouts">
      <Categories
        categories={categories}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <PageHeader
              categoryName={
                categories?.find((category) => category.id === activeCategory)
                  ?.name
              }
              handleShowFilters={() => setIsSidebarOpen(true)}
              uaTranslate={uaTranslate}
            />
          </div>
        </div>
        {workouts.length ? (
          <div className="row">
            <div className="col-12">
              <InfiniteScroll
                loadMore={loadMore}
                hasMore={page < totalPages}
                className="workouts-holder"
              >
                {workouts.map((workout, index) => (
                  <Workout key={index} workout={workout} />
                ))}
              </InfiniteScroll>
            </div>
          </div>
        ) : (
          ""
        )}
        {!isFetchingWorkouts && !workouts.length ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="no-workouts">
                <h3>
                  {isWorkoutsFiltered
                    ? translate("workouts.no_workouts_filter", {
                        cat: categories?.find(
                          (category) => category.id === activeCategory
                        )?.name,
                      })
                    : translate("workouts.no_workouts", {
                        cat: categories?.find(
                          (category) => category.id === activeCategory
                        )?.name,
                      })}
                </h3>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {isFetchingWorkouts && (
          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center">
              <Loading />
            </div>
          </div>
        )}
      </div>
      <UASidebar
        isOpen={isSidebarOpen}
        handleClose={() => setIsSidebarOpen(false)}
      >
        <Filters
          translate={translate}
          handleApply={handleSetFilters}
          language={language}
          setPage={setPage}
        />
      </UASidebar>

      <CSSTransition
        in={isModalOpen}
        timeout={300}
        unmountOnExit
        classNames="UAModal"
        nodeRef={modalRef}
        onExited={() => setWorkout(null)}
      >
        <UAModal
          handleClose={() => navigate(getRoute("workouts").path)}
          propRef={modalRef}
        >
          {workout?.isLive ? (
            <LiveWorkout workout={workout} />
          ) : (
            <WorkoutDetail workout={workout} />
          )}
        </UAModal>
      </CSSTransition>

      <CSSTransition
        in={Boolean(watch) && Boolean(user?.id)}
        timeout={300}
        unmountOnExit
        classNames="Watch"
        nodeRef={watchRef}
        onExited={beforeCloseWatch}
      >
        <Watch
          handleClose={() =>
            navigate(getRoute("workout").path.replace(":id", id))
          }
          propRef={watchRef}
          workout={workout}
          beforeCloseWatch={beforeCloseWatch}
        />
      </CSSTransition>

      <CSSTransition
        in={!!watch && Boolean(!user?.id)}
        timeout={300}
        unmountOnExit
        classNames="UALoginModal"
        nodeRef={loginModalRef}
      >
        <UALoginModal
          propRef={loginModalRef}
          onClose={() => {
            navigate(getRoute("workout").path.replace(":id", id));
          }}
        />
      </CSSTransition>
    </div>
  );
};

export default Workouts;
