import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { TranslateContext } from "../../../../../utils/context/TranslateContext";
import getIcon from "../../../../../utils/helpers/getIcon";

const Difficulty = ({ selectedFilters, handleFilterChange }) => {
  const [data, setData] = useState([]);

  const { getKeys } = useContext(TranslateContext);

  useEffect(() => {
    const keys = getKeys("difficulty");
    setData(
      keys.map((item) => ({
        id: item.key,
        icon: getIcon(`difficulty_${item.key}`),
        name: item.value,
      }))
    );
  }, [getKeys]);

  return (
    <div className="difficulties d-grid">
      {data.map((difficulty) => (
        <div
          key={difficulty.id}
          className="difficulty d-flex flex-column justify-content-center align-items-center"
          data-is-selected={selectedFilters.id === difficulty.id}
          onClick={() => handleFilterChange(difficulty)}
          role="button"
        >
          <div className="icon">{difficulty.icon}</div>
          <div className="name fw-semibold">{difficulty.name}</div>
        </div>
      ))}
    </div>
  );
};

export default Difficulty;
