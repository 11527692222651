import { findFlagByDialCode } from "country-list-with-dial-code-and-flag";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import { createRef, useEffect } from "react";
import { useState } from "react";
import getIcon from "../helpers/getIcon";
import validateInput from "../helpers/validateInput";

const UAInput = ({
  type,
  value,
  onChange,
  name,
  placeholder,
  label,
  id,
  onBlur,
  onFocus,
  success,
  error,
  icon,
  inputProps,
  bordered,
  theme = "light",
  countryCode,
  onCountryCodeChange,
  disabled,
  readOnly,
  min,
  max,
}) => {
  const [inputId] = useState(id || uniqueId("UA-input-"));
  const [showPass, setShowPass] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [countryFlag, setCountryFlag] = useState("");

  const inputRef = createRef(null);

  useEffect(() => {
    setIsFocused(type === "date" || !!value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!countryCode) return;

    const { flag } = findFlagByDialCode(countryCode) || {};

    if (flag) setCountryFlag(flag);
    else setCountryFlag("");
  }, [countryCode]);

  const handleFocus = () => {
    setIsFocused(true);
    onFocus && onFocus();
  };

  const handleBlur = () => {
    if (value === "" && type !== "date") setIsFocused(false);
    onBlur && onBlur();
  };

  return (
    <div
      className="UAInput position-relative d-flex justify-content-start align-items-center"
      data-is-error={Boolean(error)}
      data-is-success={Boolean(success)}
      data-is-bordered={Boolean(bordered)}
      data-theme={theme}
    >
      {type === "tel" && (
        <div className="country-code-area flex-shrink-0 h-100 d-flex justify-content-start align-items-center position-relative">
          <span className="country-flag position-absolute">{countryFlag}</span>
          <input
            type="text"
            name="countryCode"
            id="countrCode"
            value={countryCode}
            onChange={(e) => {
              const valueWithoutPlus = e.target.value.replace("+", "");
              if (
                validateInput(valueWithoutPlus, {
                  isNumber: true,
                }) &&
                validateInput(e.target.value, { notEmpty: true })
              )
                onCountryCodeChange(e.target.value);
            }}
            maxLength="4"
          />
        </div>
      )}
      <div className="input-area w-100 d-flex flex-shrink-1 justify-content-start align-items-center">
        <input
          id={inputId}
          type={type === "password" ? (showPass ? "text" : "password") : type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          className="w-100"
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          min={min}
          max={max}
          onAnimationStart={(e) => {
            if (e.animationName === "onAutoFillStart") setIsFocused(true);
          }}
          {...inputProps}
          ref={inputRef}
        />
        <label
          htmlFor={inputId}
          className="position-absolute"
          data-is-filled={!!placeholder || isFocused}
        >
          {label}
        </label>
        {icon && (
          <div className="UAInput-icon icon position-absolute flex-shrink-0">
            {getIcon(icon)}
          </div>
        )}
        {success && (
          <div className="UAInput-success icon position-absolute flex-shrink-0">
            {getIcon("successCircle")}
          </div>
        )}
        {error && (
          <div className="UAInput-error icon position-absolute flex-shrink-0">
            {getIcon("errorCircle")}
          </div>
        )}
        {type === "password" && !success && !error && (
          <div
            className="UAInput-show-pass icon position-absolute flex-shrink-0"
            role="button"
            onClick={() => setShowPass((prevShowPass) => !prevShowPass)}
          >
            {getIcon(showPass ? "showPass" : "hidePass")}
          </div>
        )}
        {error && (
          <div className="UAInput-error-message color--error position-absolute">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

UAInput.defaultProps = {
  type: "text",
  value: "",
  onChange: () => {},
  name: "",
  theme: "light",
};

UAInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
};

export default UAInput;
