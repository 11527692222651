import { useEffect, useRef } from "react";

const UATabs = ({ activeTab, setActiveTab, tabs }) => {
  const tabLineRef = useRef(null);

  useEffect(() => {
    tabLineAnimation();
  }, [activeTab]);

  useEffect(() => {
    window.addEventListener("resize", tabLineAnimation);
    return () => window.removeEventListener("resize", tabLineAnimation);
  }, []);

  const tabLineAnimation = (el) => {
    if (tabLineRef?.current) {
      const els = [...document.querySelectorAll(".UATabs .tab-item")];
      const activeEl = document.querySelector(
        ".UATabs .tab-item[data-active='true']"
      );

      tabLineRef.current.style.left = `${
        tabLineRef.current.offsetWidth * els.indexOf(activeEl) + 2
      }px`;
    }
  };

  return (
    <div className="UATabs d-flex justify-content-center align-items-center position-relative">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className="tab-item w-50 text-center position-relative"
          data-active={activeTab === tab.value}
          onClick={() => setActiveTab(tab.value)}
          role="button"
        >
          {tab.label}
        </div>
      ))}
      <div className="tab-line position-absolute" ref={tabLineRef}></div>
    </div>
  );
};

export default UATabs;
