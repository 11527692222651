import { uniqueId } from "lodash";
import { useState } from "react";

const UACheckbox = ({
  name,
  label,
  checked,
  onChange,
  id,
  disabled,
  setAsRadio,
}) => {
  const [htmlId] = useState(
    id || (setAsRadio ? uniqueId("UARadiio_") : uniqueId("UACheckbox_"))
  );

  return (
    <div
      className="UACheckbox d-flex justify-content-start align-items-center"
      data-is-radio={setAsRadio}
    >
      <input
        type={setAsRadio ? "radio" : "checkbox"}
        name={name}
        checked={checked || false}
        onChange={onChange}
        id={htmlId}
        className="d-none"
        disabled={disabled}
      />
      <label
        htmlFor={htmlId}
        className="checkbox d-flex justify-content-center align-items-center"
      >
        <div className="box w-100 h-100"></div>
      </label>
      {label && (
        <label htmlFor={htmlId} className="text">
          {label}
        </label>
      )}
    </div>
  );
};

UACheckbox.defaultProps = {
  id: null,
};

export default UACheckbox;
