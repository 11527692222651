import { cloneElement, useContext, useEffect, useState } from "react";
import UAButton from "../../../../utils/components/UAButton";
import { TranslateContext } from "../../../../utils/context/TranslateContext";
import Difficulty from "./Filters/Difficulty";
import Duration from "./Filters/Duration";
import Regional from "./Filters/Regional";
import Sort from "./Filters/Sort";
import Trainer from "./Filters/Trainer";
import ContentLanguage from "./Filters/ContentLanguage";

const Filters = ({ handleApply, translate, language, setPage }) => {
  const { uaTranslate } = useContext(TranslateContext);

  const [filtersList, setFiltersList] = useState([]);

  const [expandedFilter, setExpandedFilter] = useState(filtersList[0]?.id);
  const [selectedFilters, setSelectedFilters] = useState({
    duration: [],
    trainer: {},
    difficulty: {},
    regional: {},
    sort: {},
    contentLanguage: {},
  });

  useEffect(() => {
    handleSetFiltersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uaTranslate, language]);

  const handleFilterChange = (filterId, value) => {
    const newSelectedFilters = { ...selectedFilters };
    const filter = filtersList.find((filter) => filter.id === filterId);

    if (filter.isMulti) {
      const index = newSelectedFilters[filterId].findIndex(
        (filter) => filter.id === value.id
      );

      if (index === -1) newSelectedFilters[filterId].push(value);
      else newSelectedFilters[filterId].splice(index, 1);
    } else {
      if (newSelectedFilters[filterId].id === value.id)
        newSelectedFilters[filterId] = {};
      else newSelectedFilters[filterId] = value;
    }

    setSelectedFilters(newSelectedFilters);
  };

  const handleSetFiltersList = () => {
    setFiltersList([
      {
        id: "duration",
        title: uaTranslate("app", "workouts_filter_time"),
        component: <Duration />,
        maxLabels: 3,
        labelExtra: translate("min"),
        isMulti: true,
      },
      {
        id: "trainer",
        title: uaTranslate("app", "workouts_filter_trainer"),
        component: <Trainer />,
        maxLabels: 2,
        isMulti: false,
      },
      {
        id: "difficulty",
        title: uaTranslate("app", "workouts_filter_difficulty"),
        component: <Difficulty />,
        maxLabels: 1,
        isMulti: false,
      },
      {
        id: "regional",
        title: uaTranslate("app", "workouts_filter_regional"),
        component: <Regional />,
        maxLabels: 1,
        isMulti: false,
      },
      {
        id: "sort",
        title: uaTranslate("app", "workouts_filter_sort"),
        component: <Sort />,
        maxLabels: 1,
        isMulti: false,
      },
      {
        id: "contentLanguage",
        title: uaTranslate("app", "content_language"),
        component: <ContentLanguage />,
        maxLabels: 1,
        isMulti: false,
      },
    ]);
  };

  return (
    <div className="Filters">
      {filtersList.map((filter) => (
        <div
          key={filter.id}
          className="filter overflow-hidden"
          data-is-expanded={expandedFilter === filter.id}
        >
          <div
            className="filter-title d-flex justify-content-between align-items-center"
            role="button"
            onClick={() => {
              if (expandedFilter === filter.id) {
                setExpandedFilter(null);
              } else {
                setExpandedFilter(filter.id);
              }
            }}
          >
            <h3 className="title fw-semibold m-0">{filter.title}</h3>
            <div className="right-side d-flex justify-content-end align-items-center">
              <div className="selected-filters d-flex justify-content-end align-items-center">
                {filter.isMulti ? (
                  selectedFilters[filter.id]
                    ?.slice(0, filter.maxLabels)
                    .map((selectedFilter, index) => (
                      <div key={index} className="selected-filter fw-medium">
                        {selectedFilter?.name} {filter.labelExtra}
                      </div>
                    ))
                ) : (
                  <div className="selected-filter fw-medium">
                    {selectedFilters[filter.id].name}
                  </div>
                )}
                {selectedFilters[filter.id]?.length > filter.maxLabels && (
                  <div className="selected-filter fw-bold more">
                    +
                    {
                      selectedFilters[filter.id]?.slice(
                        filter.maxLabels,
                        selectedFilters[filter.id].length
                      ).length
                    }
                  </div>
                )}
              </div>
              <div className="accordion-trigger">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 11C1 16.5228 5.47715 21 11 21V21C16.5228 21 21 16.5228 21 11V11C21 5.47715 16.5228 1 11 1V1C5.47715 1 1 5.47715 1 11V11Z"
                    stroke="#231F20"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M7 9L11 13L15 9"
                    stroke="#231F20"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="filter-content">
            {cloneElement(filter.component, {
              data: filter.data,
              selectedFilters: selectedFilters[filter.id],
              handleFilterChange: (item) => handleFilterChange(filter.id, item),
              translate,
            })}
          </div>
        </div>
      ))}
      <UAButton
        classNames="apply-button w-100"
        label={uaTranslate("app", "workouts_filter_apply")}
        onClick={() => {
          setPage(1);
          handleApply(selectedFilters);
        }}
      />
    </div>
  );
};

export default Filters;
