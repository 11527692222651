import getIcon from "../../../../utils/helpers/getIcon";
import DefaultAvatar from "../../../../assets/static/profile-placeholder.svg";
import API from "../../../../utils/API";
import { useState } from "react";
import Loading from "../../../../utils/components/Loading";

const Avatar = ({ user, refresh }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdateAvatar = (e) => {
    e.preventDefault();

    if (!e.target.files[0]) return;

    setIsUpdating(true);

    const formData = new FormData();
    formData.append("avatar", e.target.files[0]);

    API.putData("users/avatars", formData)
      .then(() => {
        refresh(() => setIsUpdating(false));
      })
      .catch(() => setIsUpdating(false));
  };

  return (
    <div
      className="avatar-holder position-relative rounded-circle d-flex justify-content-center align-items-center"
      data-is-updating={isUpdating}
    >
      {isUpdating && (
        <span className="updating position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
          <Loading light />
        </span>
      )}
      <img
        src={user?.avatar || DefaultAvatar}
        alt={`${user.fullName} Avatar`}
        className="rounded-circle w-100 h-100"
      />
      <input
        type="file"
        name="avatar"
        onChange={handleUpdateAvatar}
        id="avatar-input"
        className="d-none"
        disabled={isUpdating}
      />
      <label
        htmlFor="avatar-input"
        className="position-absolute rounded-circle d-flex justify-content-center align-items-center"
      >
        {getIcon("camera")}
      </label>
    </div>
  );
};

export default Avatar;
