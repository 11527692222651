import { Link } from "react-router-dom";
import getRoute from "../../../../utils/helpers/getRoute";
import socialMedias from "../../../../utils/db/socialMedias";

const FooterBottom = ({ translate }) => {
  return (
    <div className="bottom d-flex flex-column">
      <section className="top-section d-flex flex-column flex-md-row justify-content-between align-items-center">
        <div className="agreements mb-3 mb-md-0">
          <Link to={getRoute("privacy-policy").path}>
            {translate("footer.privacy_policy")}
          </Link>
          <Link to={getRoute("terms-and-conditions").path}>
            {translate("footer.terms_of_use")}
          </Link>
        </div>
        <p className="disclaimer text-center mb-3 mb-md-0 flex-shrink-0">
          &copy; UrbanActive {new Date().getFullYear()}
        </p>
        <div className="socials d-flex justify-content-center justify-content-md-end align-items-center">
          {socialMedias
            .sort((a, b) => a.order - b.order)
            .map((sm, index) => (
              <a href={sm.link} key={sm?.id} target="_blank" rel="noreferrer">
                <sm.icon />
              </a>
            ))}
        </div>
      </section>
    </div>
  );
};

export default FooterBottom;
