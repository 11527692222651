import { useContext, useState } from "react";
import { TranslateContext } from "../../../../utils/context/TranslateContext";
import UAButton from "../../../../utils/components/UAButton";
import API from "../../../../utils/API";
import toastr from "toastr";

const SingleSubscription = ({ subscription, translate }) => {
  const { uaTranslate } = useContext(TranslateContext);
  const [isCreatingSession, setIsCreatingSession] = useState(false);

  const handleManageSubscription = () => {
    setIsCreatingSession(true);
    API.postData("stripe/create-billing-session")
      .then(({ data: res }) => {
        if (res?.data?.url) {
          window.location.href = res?.data?.url;
        } else {
          toastr.error(translate("request_error"))
          setIsCreatingSession(false);
        }
      })
      .catch(() => {
        setIsCreatingSession(false);
      });
  };

  return (
    <div
      key={subscription.id}
      className="inner-section bg-white d-flex justify-content-between align-items-start"
    >
      <div className="left w-50">
        <div className="info-holder">
          <h6 className="info-title fw-semibold">{subscription.title}</h6>
          {subscription.channel === "web" ? <UAButton
            onClick={handleManageSubscription}
            type="primary"
            classNames="info py-2 px-3 fw-normal fs-6"
            label={uaTranslate("app", "subs_manage_subscription")}
            disabled={isCreatingSession}
            loading={isCreatingSession}
          /> : uaTranslate("app", "web_subscription_information")}
        </div>
      </div>
      <div className="right d-grid w-50">
        <div className="info-holder">
          <h6 className="info-title fw-semibold">
            {uaTranslate("app", "subs_payment_info")}
          </h6>
          <div className="info fw-bold">
            {subscription.price} {subscription.currency} / {subscription.period}
          </div>
        </div>
        <div className="info-holder">
          <h6 className="info-title fw-semibold">
            {uaTranslate("app", "subs_payment_method")}
          </h6>
          <div className="info">**** **** **** ****</div>
        </div>
        <div className="info-holder">
          <h6 className="info-title fw-semibold">
            {uaTranslate("app", "subs_start_date")}
          </h6>
          <div className="info">{subscription.startsAt}</div>
        </div>
        <div className="info-holder">
          <h6 className="info-title fw-semibold">
            {uaTranslate("app", "subs_end_date")}
          </h6>
          <div className="info">{subscription.endsAt}</div>
        </div>
      </div>
    </div>
  );
};

export default SingleSubscription;
