import { format } from "date-fns";
import { useContext, useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { CSSTransition } from "react-transition-group";
import SubPageLayout from "../../../App/Layouts/SubPageLayout/SubPageLayout";
import API from "../../../utils/API";
import UAButton from "../../../utils/components/UAButton";
import UAModal from "../../../utils/components/UAModal";
import checkIfDateIsValid from "../../../utils/helpers/checkIfDateIsValid";
import fixDateFormatForSafari from "../../../utils/helpers/fixDateFormatForSafari";
import ProfileSection from "../Profile/components/ProfileSection";
import AddCreditCard from "./components/AddCreditCard";
import PastSubscriptions from "./components/PastSubscriptions";
import SingleSubscription from "./components/SingleSubscription";
import { useSelector } from "react-redux";
import Paywall from "../../../utils/components/Paywall";
import { TranslateContext } from "../../../utils/context/TranslateContext";
import getRoute from "../../../utils/helpers/getRoute";

const Subscription = ({ translate }) => {
  const [subscription, setSubscription] = useState(null);
  const [pastSubscriptions, setPastSubscriptions] = useState([]);
  const [showCreditCardModal, setShowCreditCardModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [isSubscriptionLoaded, setIsSubscriptionLoaded] = useState(false);

  const addCardRef = useRef(null);
  const subscribeRef = useRef(null);

  const { canBeSubscribed } = useSelector((state) => state.app);

  const location = useLocation();
  const navigate = useNavigate();
  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    if (location?.state?.from === "watch") {
      setShowSubscribeModal(true);
      navigate(location.pathname, {});
    }
  }, [location, navigate]);

  useEffect(() => {
    const req = [
      API.getData("subscriptions?is_history=0"),
      API.getData("subscriptions?is_history=1"),
    ];

    Promise.all(req)
      .then((res) => {
        const data = [res[0]?.data, res[1]?.data];

        const [current, past] = data;

        if (current?.data?.length) {
          const currentSubscription = current.data[0];

          if (currentSubscription) {
            setSubscription(formatSubscription(currentSubscription));
          }
        }

        if (past.data?.length) {
          const pastSubscriptions = past.data;

          if (pastSubscriptions.length) {
            setPastSubscriptions(
              pastSubscriptions.map((subscription) =>
                formatSubscription(subscription)
              )
            );
          }
        }
      })
      .finally(() => setIsSubscriptionLoaded(true))
      .catch((err) => console.error(err));
  }, []);

  const formatSubscription = (subscription) => ({
    id: subscription?.id,
    title: subscription?.title,
    price: subscription?.price,
    startsAt:
      subscription?.starts_at &&
      checkIfDateIsValid(fixDateFormatForSafari(subscription?.starts_at))
        ? format(
            new Date(fixDateFormatForSafari(subscription?.starts_at)),
            "dd.MM.yyyy"
          )
        : null,
    endsAt:
      subscription?.ends_at &&
      checkIfDateIsValid(fixDateFormatForSafari(subscription?.ends_at))
        ? format(
            new Date(fixDateFormatForSafari(subscription?.ends_at)),
            "dd.MM.yyyy"
          )
        : null,
    charge: subscription?.charge,
    currency: subscription?.currency,
    period: subscription?.renewal_period,
    channel: subscription?.channel
  });

  return (
    <SubPageLayout page="profile">
      <div className="Profile Subscription">
        <ProfileSection
          className="border-bottom-0 pb-0"
          title={uaTranslate("app", "subs_title")}
        >
          {subscription && isSubscriptionLoaded && (
            <SingleSubscription subscription={subscription} translate={translate} />
          )}

          {!subscription && isSubscriptionLoaded && (
            <>
              {!canBeSubscribed && (
                <h4 className="mt-2">
                  {translate("subscription.no_subscription_text")}
                </h4>
              )}
              {canBeSubscribed && (
                <UAButton
                  isLink
                  action={getRoute("subscribe")?.path}
                  label={translate("subscription.no_subscription_button")}
                  classNames="text-uppercase"
                />
              )}
            </>
          )}
        </ProfileSection>
        {!!pastSubscriptions?.length && (
          <ProfileSection
            title={translate("subscription.past_subscriptions")}
            className="d-none"
          >
            <PastSubscriptions
              translate={translate}
              subscriptions={pastSubscriptions}
            />
          </ProfileSection>
        )}
        {/* {<ProfileSection
          className="mt-5"
          title={translate("subscription.my_cards")}
        >
          <CreditCards
            translate={translate}
            showCreditCardModal={() => setShowCreditCardModal(true)}
          />
        </ProfileSection>} */}
      </div>

      <CSSTransition
        in={showCreditCardModal}
        unmountOnExit
        timeout={300}
        classNames="UAModal"
        nodeRef={addCardRef}
      >
        <UAModal
          handleClose={() => setShowCreditCardModal(false)}
          propRef={addCardRef}
          classNames="add-new-card-modal"
        >
          <AddCreditCard onClose={() => setShowCreditCardModal(false)} />
        </UAModal>
      </CSSTransition>

      <CSSTransition
        in={showSubscribeModal}
        unmountOnExit
        timeout={300}
        classNames="UAModal"
        nodeRef={subscribeRef}
      >
        <UAModal
          handleClose={() => setShowSubscribeModal(false)}
          propRef={subscribeRef}
          classNames="paywall-modal"
        >
          <Paywall onClose={() => setShowSubscribeModal(false)} />
        </UAModal>
      </CSSTransition>
    </SubPageLayout>
  );
};

export default Subscription;
