import { useContext, useEffect, useState } from "react";
import PaywallImage from "../../../assets/images/paywall-1.png";
import { TranslateContext } from "../../context/TranslateContext";
import API from "../../API";
import formatPrice from "../../helpers/formatPrice";
import Loading from "../Loading";
import Subscriptions from "./components/Subscriptions";
import Summary from "./components/Summary";
import UAButton from "../UAButton";
import Footnote from "./components/Footnote";

const TAX = 0.2;

const Paywall = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingRedirect, setIsFetchingRedirect] = useState(false);

  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    API.getSubscriptionModels().then(({ data: res }) => {
      const productDescription = res.data?.product?.description;
      const subscriptions = res.data.prices
        ?.filter((item) => item.active)
        ?.map(
          ({
            id,
            unit_amount: price,
            currency,
            recurring: { interval, interval_count },
          }) => ({
            id,
            title: `${interval_count} ${uaTranslate(
              "app",
              interval === "month"
                ? "subscription.period.month"
                : "subscription.period.year"
            )}`,
            titleExtra:
              interval_count === 1 && interval === "month"
                ? uaTranslate("app", "paywall_web_package_free_trial")
                : "",
            description: productDescription,
            price: parseFloat(formatPrice(price - price * TAX)).toFixed(2),
            monthlyPrice:
              interval === "month"
                ? parseFloat(formatPrice(price / interval_count)).toFixed(2)
                : parseFloat(formatPrice(price / 12)).toFixed(2),
            tax: parseFloat(formatPrice(price * TAX)).toFixed(2),
            totalPrice: parseFloat(formatPrice(price)).toFixed(2),
            currency: currency.toUpperCase() === "TRY" ? "₺" : "$",
            recurring: interval,
            recurring_count: interval_count,
          })
        );
      setSubscriptions(subscriptions);

      setSelectedSubscription(subscriptions[0]);

      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    });
  }, [uaTranslate]);

  const handleStartSession = () => {
    setIsFetchingRedirect(true);

    const { id: price_id } = selectedSubscription;

    API.postData("stripe/create-checkout-session", {
      price_id,
    })
      .then(({ data: res }) => {
        if (res?.data?.redirect_url) {
          localStorage.setItem(
            "subscription",
            JSON.stringify({
              price: selectedSubscription?.totalPrice,
              currency: selectedSubscription?.currency,
            })
          );

          window.location.href = res?.data?.redirect_url;
        } else {
          setIsFetchingRedirect(false);
        }
      })
      .catch(() => setIsFetchingRedirect(false));
  };

  return (
    <div className="Paywall h-100">
      <div className="container ps-0 h-100">
        <div className="row h-100">
          <div className="col-6 h-100 overflow-hidden">
            <div className="Paywall__image  w-100 h-100 position-relative">
              <img
                src={PaywallImage}
                alt="Paywall"
                className="w-100 h-100 object-fit-cover"
              />
              <div className="Paywall__image__title position-absolute w-100 text-center">
                {uaTranslate("app", "paywall_web_background_title")}
              </div>
            </div>
          </div>
          <div className="col-6 h-100 overflow-auto">
            {isLoading ? (
              <div className="Paywall__content d-flex justify-content-center align-items-center w-100 h-100">
                <div className="mx-auto">
                  <Loading />
                </div>
              </div>
            ) : (
              <div className="Paywall__content">
                <Subscriptions
                  subscriptions={subscriptions}
                  selectedSubscription={selectedSubscription}
                  setSelectedSubscription={setSelectedSubscription}
                />

                <Summary selectedSubscription={selectedSubscription} />

                <UAButton
                  label={uaTranslate("app", "paywall_web_start_payment")}
                  classNames="w-100 pay-button mb-5 text-uppercase"
                  disabled={!selectedSubscription}
                  loading={isFetchingRedirect}
                  onClick={handleStartSession}
                />

                <Footnote />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paywall;
