import axios from "axios";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import API from "../../../utils/API";
import { TranslateContext } from "../../../utils/context/TranslateContext";
import getRoute from "../../../utils/helpers/getRoute";
import Program from "../Programs/components/Program";
import LiveWorkout from "./components/LiveWorkout";
import Section from "./components/Section";
import Slider from "./components/Slider";
import VerticalWorkout from "./components/VerticalWorkout";

const Home = ({ auth, language }) => {
  const [popularPrograms, setPopularPrograms] = useState([]);
  const [liveWorkouts, setLiveWorkouts] = useState([]);
  const [personalWorkouts, setPersonalWorkouts] = useState([]);

  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    const requests = [
      API.getData("programs?is_popular=1"),
      API.getData(`workouts?is_live=1`),
    ];

    axios.all(requests).then(
      axios.spread((...responses) => {
        const [programs, workouts] = responses;

        setPopularPrograms(
          programs.data.data.map((program) => ({
            id: program.id,
            slug: program.slug,
            title: program.title,
            image: program.image_list,
            order: program.order,
            day_count: program.day_count,
            difficulty: program.difficulty,
          }))
        );

        setLiveWorkouts(
          workouts.data.data
            .map((workout) => ({
              id: workout.id,
              slug: workout.slug,
              title: workout.title,
              image: workout.image_list,
              duration: workout.duration,
              difficulty: workout.difficulty,
              trainer: workout.trainer_name,
              starts_at: workout.starts_at,
              ends_at: new Date(workout.starts_at).setMinutes(
                new Date(workout.starts_at).getMinutes() + workout.duration
              ),
              notify: workout.notify,
            }))
            ?.slice(0, 3)
        );
      })
    );

    API.getData("workouts/personal").then(({ data: res }) => {
      setPersonalWorkouts(
        res.data.map((workout) => ({
          id: workout.id,
          title: workout.title,
          duration: workout.duration,
          trainer: {
            name: workout.trainer_name,
            image: workout.image_list,
          },
          slug: workout.slug,
        }))
      );
    });
  }, [auth.isAuthenticated, language]);

  return (
    <div className="Home">
      <Slider language={language} />
      <div className="container">
        <Section
          title={uaTranslate("app", "main_title_live_workouts")}
          on={liveWorkouts.length > 0}
        >
          <div className="LiveWorkouts p-0">
            <div className="live-workouts-list d-grid">
              {liveWorkouts.map((workout) => (
                <LiveWorkout key={workout.id} workout={workout} />
              ))}
            </div>
          </div>
        </Section>
        <Section
          title={uaTranslate("app", "main_title_personal_workouts")}
          extra={
            <Link to={getRoute("workouts").path} className="top-link fw-medium">
              <Translate id="view_all" />
            </Link>
          }
          on={personalWorkouts.length > 0}
        >
          <div className="PersonalWorkouts p-0">
            <div className="personal-workouts-list d-grid">
              {personalWorkouts.map((workout) => (
                <VerticalWorkout key={workout.id} workout={workout} />
              ))}
            </div>
          </div>
        </Section>
        <Section
          title={uaTranslate("app", "main_title_popular_programs")}
          extra={
            <Link to={getRoute("programs").path} className="top-link fw-medium">
              <Translate id="view_all" />
            </Link>
          }
          on={popularPrograms.length > 0}
        >
          <div className="Programs p-0">
            <div className="programs-list d-grid">
              {popularPrograms.map((program) => (
                <Program key={program.id} program={program} />
              ))}
            </div>
          </div>
        </Section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Home);
