import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useOutletContext } from "react-router";
import API from "../../../../utils/API";
import UAPinsCategory from "../../../../utils/components/UAPinsCategory";
import UATabs from "../../../../utils/components/UATabs";
import UAUserWorkouts from "../../../../utils/components/UAUserWorkouts";
import { TranslateContext } from "../../../../utils/context/TranslateContext";

const Profile = () => {
  const { profile } = useOutletContext();
  const [activeTab, setActiveTab] = useState("trainings");
  const [pins, setPins] = useState([]);
  const [workouts, setWorkouts] = useState([]);

  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    if (!profile?.id) return;

    const requests = [
      API.getPins(profile?.id),
      API.getUserWorkouts(profile?.id),
    ];

    Promise.all(requests).then(([{ data: res }, { data: res2 }]) => {
      setPins(res.data.map((item) => ({ ...item })));
      setWorkouts(res2.data);
    });
  }, [profile?.id]);

  return (
    <div className="Community Profile position-relative">
      <Helmet>
        {profile?.fullname && <title>{profile?.fullname} - Profil</title>}
      </Helmet>
      <div className="row">
        <div className="col-12">
          <div className="profile-holder single-content-holder">
            <UATabs
              tabs={[
                {
                  value: "trainings",
                  label: uaTranslate("app", "community_trainer_workouts"),
                },
                {
                  value: "pins",
                  label: uaTranslate("app", "community_trainer_badges"),
                },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            {activeTab === "trainings" && (
              <UAUserWorkouts workouts={workouts} />
            )}

            {activeTab === "pins" && <UAPinsCategory pins={pins} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
