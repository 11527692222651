import { useEffect, useRef } from "react";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import getIcon from "../../../../utils/helpers/getIcon";
import getRoute from "../../../../utils/helpers/getRoute";
import { logout } from "../../../../utils/redux/auth/authActions";
import { authRoutes } from "../../../routes";

const HeaderUserArea = ({ user, logout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClose, false);
    }

    return () => {
      document.removeEventListener("click", handleClose, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleLogout = () => logout();

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.classList.contains("menu-trigger")) return;

    setIsOpen(false);
  };

  return (
    <div
      className="user-area d-flex justify-content-end align-items-center position-relative"
      data-is-active={isOpen}
    >
      <div className="user-avatar rounded-circle d-flex flex-shrink-0 justify-content-center align-items-center">
        {user?.avatar ? (
          <img
            src={user?.avatar}
            alt="avatar"
            className="w-100 h-100 rounded-circle"
          />
        ) : (
          <div className="default-avatar w-100 h-100 d-flex justify-content-center align-items-center rounded-circle p-2">
            {getIcon("logo", { alternative: "single", className: "w-100" })}
          </div>
        )}
      </div>
      <div className="user-name fw-medium">
        {user?.firstName} {user?.lastName?.slice(0, 1)}.
      </div>
      <div
        className="menu-trigger stretched-link"
        role="button"
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <FaChevronDown className="icon" />
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        unmountOnExit
        classNames="user-menu"
        nodeRef={menuRef}
      >
        <div
          className="user-menu position-absolute d-flex flex-column"
          ref={menuRef}
        >
          {authRoutes
            .filter((route) => route.onUserMenu)
            .map((route) => (
              <Link
                key={route.id}
                to={getRoute(route.name)?.path}
                className="list-item"
              >
                <Translate id={`header.${route.name}`} />
              </Link>
            ))}
          <div
            className="list-item logout"
            role="button"
            onClick={handleLogout}
          >
            <Translate id="logout" />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(HeaderUserArea);
