import React, { useContext } from "react";
import { TranslateContext } from "../context/TranslateContext";
import { confirmAlert } from "react-confirm-alert";
import UAConfirmModal from "./UAConfirmModal";

const UAFollowUser = ({ isFollowed, onClick }) => {
  const { uaTranslate } = useContext(TranslateContext);
  const handleConfirmUnfollow = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <UAConfirmModal
          desc={uaTranslate("app", "profile_screen_unfollow_popup_message")}
          buttons={[
            {
              label: uaTranslate("app", "dialog_button_close"),
              type: "secondary",
              onClick: onClose,
            },
            {
              label: uaTranslate("app", "profile_screen_unfollow"),

              type: "primary",
              onClick: () => {
                onClick();
                onClose();
              },
            },
          ]}
        />
      ),
    });
  };

  const handleClick = () => {
    isFollowed ? handleConfirmUnfollow() : onClick();
  };

  return (
    <div className="UAFollowUser d-flex align-items-center gap-3">
      <button
        className="action-button follow"
        onClick={handleClick}
        data-is-followed={isFollowed}
      >
        {uaTranslate(
          "app",
          isFollowed ? `community_trainer_unfollow` : `community_trainer_follow`
        )}
      </button>
    </div>
  );
};

export default UAFollowUser;
