import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";

const socialMedias = [
  {
    id: 1,
    name: "Instagram",
    icon: FaInstagram,
    link: "https://www.instagram.com/urbanactiveplus",
    order: 1,
  },
  {
    id: 2,
    name: "Linkedin",
    icon: FaLinkedinIn,
    link: "https://www.linkedin.com/company/urban-riders",
    order: 2,
  },
  {
    id: 3,
    name: "Youtube",
    icon: FaYoutube,
    link: "https://www.youtube.com/@urbanactive4222",
    order: 3,
  },
  {
    id: 4,
    name: "Facebook",
    icon: FaFacebookF,
    link: "https://www.facebook.com/urbanactiveone",
    order: 4,
  },
];

export default socialMedias;
