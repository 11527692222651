import { createContext, useState } from "react";
import API from "../API";

const initValues = {
  app: [],
  goals: [],
  gender: [],
  body_part: [],
  body_type: [],
  equipment: [],
  difficulty: [],
  body_region: [],
  sport_category: [],
};

const TranslateContext = createContext({ ...initValues });

const TranslateProvider = ({ children }) => {
  const [keys, setKeys] = useState({ ...initValues });

  const getTranslateData = () => {
    API.getTranslations(Object.keys(initValues)).then(({ data: res }) => {
      setKeys(res.data);
    });
  };

  const translate = (category, key) => {
    if (!category || !key) return key;
    if (!keys[category]) return key;
    if (!keys[category].length) return key;

    return keys[category]?.find((item) => item.key === key)?.value || key;
  };

  const getKeys = (category = null) => {
    return category ? keys[category] : keys || [];
  };

  return (
    <TranslateContext.Provider
      value={{ keys, uaTranslate: translate, getKeys, getTranslateData }}
    >
      {children}
    </TranslateContext.Provider>
  );
};

export { TranslateContext, TranslateProvider };
