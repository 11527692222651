import PropTypes from "prop-types";
import { useState } from "react";
import { Translate } from "react-localize-redux";
import UAButton from "../../../../utils/components/UAButton";
import UAInput from "../../../../utils/components/UAInput";

const AddCreditCard = ({ onClose }) => {
  const [cardInfo, setCardInfo] = useState({
    name: "",
    number: "",
    owner: "",
    cvv: "",
    exp: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setCardInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="add-new-card">
      <Translate>
        {({ translate }) => (
          <>
            <div className="row">
              <div className="col-12">
                <h5 className="title fw-bold">
                  {translate("subscription.add_card_modal.title")}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <UAInput
                  name="name"
                  value={cardInfo.name}
                  onChange={handleInputChange}
                  label={translate(
                    "subscription.add_card_modal.card_name_label"
                  )}
                  placeholder={translate(
                    "subscription.add_card_modal.card_name_placeholder"
                  )}
                  theme="dark"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <UAInput
                  name="owner"
                  value={cardInfo.owner}
                  onChange={handleInputChange}
                  label={translate(
                    "subscription.add_card_modal.card_owner_label"
                  )}
                  placeholder={translate(
                    "subscription.add_card_modal.card_owner_placeholder"
                  )}
                  theme="dark"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <UAInput
                  name="number"
                  value={cardInfo.number}
                  onChange={handleInputChange}
                  label={translate(
                    "subscription.add_card_modal.card_number_label"
                  )}
                  placeholder={translate(
                    "subscription.add_card_modal.card_number_placeholder"
                  )}
                  theme="dark"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-7">
                <UAInput
                  name="exp"
                  value={cardInfo.exp}
                  onChange={handleInputChange}
                  label={translate(
                    "subscription.add_card_modal.card_exp_label"
                  )}
                  placeholder={translate(
                    "subscription.add_card_modal.card_exp_placeholder"
                  )}
                  theme="dark"
                />
              </div>
              <div className="col-5">
                <UAInput
                  name="cvv"
                  value={cardInfo.cvv}
                  onChange={handleInputChange}
                  label={translate(
                    "subscription.add_card_modal.card_cvv_label"
                  )}
                  placeholder={translate(
                    "subscription.add_card_modal.card_cvv_placeholder"
                  )}
                  theme="dark"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <UAButton
                  label={translate("save")}
                  classNames="text-uppercase"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <p
                  className="text-center cancel-button m-0"
                  role="button"
                  onClick={onClose}
                >
                  {translate("cancel_alt")}
                </p>
              </div>
            </div>
          </>
        )}
      </Translate>
    </div>
  );
};

AddCreditCard.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddCreditCard;
