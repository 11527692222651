import { useEffect } from "react";
import { Children, cloneElement, isValidElement, useState } from "react";
import { Translate } from "react-localize-redux";
import useCurrentRoute from "../../../utils/hooks/useCurrentRoute";
import SEO from "../../SEO";
import Footer from "./components/Footer";
import Header from "./components/Header";

const GuestLayout = ({ children, language }) => {
  const [pageTitle, setPageTitle] = useState("");
  const currentRoute = useCurrentRoute();

  useEffect(() => {
    if (currentRoute) {
      setPageTitle(currentRoute.name);
    }
  }, [currentRoute]);

  return (
    <Translate>
      {({ translate }) => (
        <div className="GuestLayout bg--gray--dark min-vh-100 d-flex">
          <SEO language={language} translate={translate} />
          <div className="container flex-grow-1">
            <div className="row h-100">
              <div className="col-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4 h-100">
                <div className="content d-grid h-100">
                  <Header pageTitle={translate(`page_title.${pageTitle}`)} />
                  <div className="row">
                    <div className="col-12">
                      <div className="page-content">
                        {Children.map(children, (child) => {
                          // Checking isValidElement is the safe way and avoids a typescript
                          // error too.
                          if (isValidElement(child)) {
                            return cloneElement(child, { translate });
                          }
                          return child;
                        })}
                      </div>
                    </div>
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Translate>
  );
};

export default GuestLayout;
