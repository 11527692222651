import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import propTypeRequiredIf from "../helpers/propTypeRequiredIf";
import Loading from "./Loading";

const UAButton = ({
  label,
  onClick,
  type,
  htmlType,
  disabled,
  classNames,
  isLink,
  action,
  loading,
}) => {
  return isLink ? (
    <Link
      to={action}
      className={`UAButton d-flex justify-content-center align-items-center type--${type} ${classNames}`}
      disabled={disabled || loading}
    >
      <span className="label">{loading ? <Loading /> : label}</span>
    </Link>
  ) : (
    <button
      type={htmlType}
      onClick={onClick}
      className={`UAButton d-flex justify-content-center align-items-center type--${type} ${classNames}`}
      disabled={disabled || loading}
    >
      {<span className="label">{loading ? <Loading /> : label}</span>}
    </button>
  );
};

UAButton.defaultProps = {
  type: "primary",
  htmlType: "button",
  disabled: false,
  classNames: "",
  isLink: false,
};

UAButton.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "empty",
    "dark",
    "danger",
    "gray",
  ]),
  htmlType: PropTypes.oneOf(["button", "submit", "reset"]),
  disabled: PropTypes.bool,
  classNames: PropTypes.string,
  isLink: PropTypes.bool,
  action: propTypeRequiredIf,
};

export default UAButton;
