import { uniqueId } from "lodash";

const Section = ({
  children,
  title,
  extra,
  className = "",
  id = uniqueId("section-"),
  on,
}) =>
  on && (
    <div className="row section-row">
      <div className="col-12">
        <section className={className} id={id}>
          <div className="top d-flex justify-content-between align-items-center">
            <h6 className="section-title m-0">{title}</h6>
            {extra || ""}
          </div>
          <div className="content-holder">{children}</div>
        </section>
      </div>
    </div>
  );

export default Section;
