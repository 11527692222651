import PropTypes from "prop-types";
import { useContext } from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Translate } from "react-localize-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import UAButton from "../../../utils/components/UAButton";
import { TranslateContext } from "../../../utils/context/TranslateContext";
import getIcon from "../../../utils/helpers/getIcon";
import getRoute from "../../../utils/helpers/getRoute";

const Workout = ({ workout }) => {
  const [tabs, setTabs] = useState([]);

  const [selectedTab, setSelectedTab] = useState(null);

  const navigate = useNavigate();
  const { id, slug } = useParams();

  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    if (!id) navigate(getRoute("workouts").path);
    if (!slug && workout?.slug && id === workout.id)
      navigate(
        getRoute("workout").path.replace(":id", id) + `/${workout.slug}`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, workout]);

  useEffect(() => {
    if (workout?.id)
      setTabs([
        {
          id: "equipments",
          title: uaTranslate("app", "workouts_detail_equipment"),
          content:
            workout?.equipment
              ?.map((item) => uaTranslate("equipment", item))
              .join(", ") || "",
        },
        {
          id: "workout",
          title: uaTranslate("app", "workouts_detail_workout"),
          content: workout?.training?.replace(/\r\n/g, "<br />") || "",
        },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout?.id, workout?.equipment, workout?.training]);

  useEffect(() => {
    if (tabs.length) setSelectedTab(tabs[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  useEffect(() => {
    if (selectedTab) {
      const selectedBgEl = document.querySelector(
        `.Workout .tabs .selected-bg`
      );

      const index = tabs.findIndex((tab) => tab.id === selectedTab.id);

      if (selectedBgEl)
        selectedBgEl.style.transform = `translateX(${index * 100}%)`;
    }
  }, [selectedTab, tabs]);

  return (
    <div className="Workout">
      <Helmet>
        <title>{workout?.title}</title>
      </Helmet>
      <div
        className="workout-info-area d-flex flex-column position-relative"
        style={{ backgroundImage: `url(${workout?.cover})` }}
      >
        <Translate>
          {({ translate }) => (
            <Link
              className="play-button w-100 d-flex justify-content-center align-items-center"
              role="button"
              to={getRoute("workout-watch")
                .path.replace(":id", workout?.id)
                .replace(":slug", workout?.slug)
                .replace(":watch", translate("watch").toLowerCase())}
            >
              {getIcon("play", { alternative: "circle" })}
            </Link>
          )}
        </Translate>

        <h1 className="workout-title">{workout?.title}</h1>
        <section className="middle-section d-flex justify-content-between align-items-start">
          <div className="workout-info d-flex flex-column align-items-start">
            <div className="difficulty">
              {uaTranslate("difficulty", workout?.difficulty)}{" "}
              <Translate id="level" />
            </div>
            <div className="more-info d-flex justify-content-start align-items-center">
              <div className="duration">
                {workout?.duration && (
                  <Translate
                    id="workouts.min"
                    data={{ min: workout.duration }}
                  />
                )}
              </div>
              <div className="trainer">{workout?.trainer?.name}</div>
            </div>
          </div>
          <button className="bookmark-button border-0 d-none">
            {getIcon("bookmark")}
          </button>
        </section>
        <p className="description">{workout?.description}</p>
      </div>
      <section className="workout-content-area">
        <div className="tabs d-grid w-100 position-relative">
          <div className="selected-bg position-absolute" />
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`tab text-center ${
                selectedTab?.id === tab?.id ? "active" : ""
              }`}
              onClick={() => setSelectedTab(tab)}
              role="button"
            >
              {tab.title}
            </div>
          ))}
        </div>
        <div
          className="tab-content"
          dangerouslySetInnerHTML={{ __html: selectedTab?.content }}
        />
      </section>
      <div className="start-button w-100 d-flex justify-content-center">
        <Translate>
          {({ translate }) => (
            <UAButton
              classNames="border-alternative text-alternative"
              label={translate("start")}
              action={getRoute("workout-watch")
                .path.replace(":id", workout?.id)
                .replace(":slug", workout?.slug)
                .replace(":watch", translate("watch").toLowerCase())}
              isLink
            />
          )}
        </Translate>
      </div>
    </div>
  );
};

Workout.propTypes = {
  workout: PropTypes.shape({
    id: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    difficulty: PropTypes.string,
    trainer: PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
    }),
    equipment: PropTypes.any,
    training: PropTypes.any,
    cover: PropTypes.string,
    isBookmarked: PropTypes.bool,
    videoLink: PropTypes.string,
  }),
};

export default Workout;
