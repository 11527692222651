import { Translate } from "react-localize-redux";
import validateInput from "./validateInput";

const validatePasswordUpdate = (currentPassword, password, confirmPassword) => {
  if (!validateInput(currentPassword, { notEmpty: true })) {
    return {
      error: "current_password",
      message: <Translate id="password_validation.required" />,
    };
  }

  if (validateInput(currentPassword, { match: password })) {
    return {
      error: "new_password",
      message: <Translate id="password_validation.same" />,
    };
  }

  if (!validateInput(password, { notEmpty: true, minLength: 8 })) {
    return {
      error: "new_password",
      message: (
        <Translate id="password_validation.min_length" data={{ length: 8 }} />
      ),
    };
  }

  if (
    !validateInput(password, {
      has: /^(?=.*[a-zA-Z])(?=.*[0-9])/,
    })
  ) {
    return {
      error: "new_password",
      message: <Translate id="password_validation.complexity" />,
    };
  }

  if (!validateInput(confirmPassword, { notEmpty: true, match: password })) {
    return {
      error: "new_password_confirm",
      message: <Translate id="password_validation.match" />,
    };
  }
  return null;
};

export default validatePasswordUpdate;
