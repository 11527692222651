import { createRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

const ShowSuccess = ({ message, show, setShow }) => {
  const popupRef = createRef();

  useEffect(() => {
    if (show) {
      const el = document.querySelector(".success-popup");

      if (el) {
        window.scrollTo({
          top: el.offsetTop - 100,
          behavior: "smooth",
        });
      }

      setTimeout(() => {
        setShow(false);
      }, 2250);
    }
  }, [setShow, show]);

  return (
    <CSSTransition
      in={show}
      unmountOnExit
      timeout={300}
      nodeRef={popupRef}
      classNames="success-popup"
    >
      <div
        className="success-popup position-absolute text-center text-white fw-semibold"
        ref={popupRef}
      >
        {message}
      </div>
    </CSSTransition>
  );
};

export default ShowSuccess;
