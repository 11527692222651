import { useContext, useEffect, useState } from "react";
import API from "../../../../utils/API";
import Loading from "../../../../utils/components/Loading";
import UAButton from "../../../../utils/components/UAButton";
import UALeadershipPerson from "../../../../utils/components/UALeadershipPerson";
import UATabs from "../../../../utils/components/UATabs";
import UATopPlacePerson from "../../../../utils/components/UATopPlacePerson";
import { TranslateContext } from "../../../../utils/context/TranslateContext";
import getRoute from "../../../../utils/helpers/getRoute";

const LIMIT = 10;

const Leadership = () => {
  const [activeTab, setActiveTab] = useState("monthly");
  const [leadership, setLeadership] = useState([]);
  const [myPlace, setMyPlace] = useState(null);
  const [page, setPage] = useState(1);
  const [leadershipLoading, setLeadershipLoading] = useState(true);

  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    setPage(1);
    getLeadership();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const getLeadership = () => {
    setLeadershipLoading(true);
    setLeadership([]);

    const urlType =
      activeTab === "monthly"
        ? `?year=${new Date().getFullYear()}&month=${new Date().getMonth() + 1}`
        : ``;

    const requests = [
      API.getData(`community-points${urlType}`),
      API.getData(`community-points/me${urlType}`),
    ];

    Promise.all(requests).then(([{ data: res }, { data: res2 }]) => {
      setLeadership(
        res.data?.map((person) => ({
          id: person.id,
          name: person.fullname,
          avatar: person.avatar,
          score: person.total_score,
          username: person.nickname,
          rank: person.rank,
          profileLink:
            getRoute(
              person?.id === res2.data.id
                ? "community-my-profile"
                : "community-profile",
              null,
              getRoute("community")
            )?.path?.replace(":id", person?.id) || "#!",
        }))
      );
      setMyPlace({
        id: res2.data.id,
        name: res2.data.fullname,
        avatar: res2.data.avatar,
        username: res2.data.nickname,
        score: res2.data.total_score,
        rank: res2.data.rank,
        profileLink: getRoute(
          "community-my-profile",
          null,
          getRoute("community")
        )?.path,
      });
      setLeadershipLoading(false);
    });
  };

  return (
    <div className="Community Leadership position-relative">
      <div className="row">
        <div className="col-12">
          <div className="leadership-holder single-content-holder">
            <h4 className="w-100 text-center page-title fw-bold">
              {uaTranslate("app", "community_score")}
            </h4>

            <UATabs
              tabs={[
                {
                  value: "monthly",
                  label: uaTranslate("app", "community_monthly"),
                },
                {
                  value: "all-times",
                  label: uaTranslate("app", "community_all_times"),
                },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            {leadershipLoading && (
              <div className="w-100 d-flex justify-content-center mt-5">
                <Loading />
              </div>
            )}

            {!leadershipLoading && myPlace && (
              <UALeadershipPerson
                person={{
                  name: myPlace?.name,
                  avatar: myPlace?.avatar,
                  score: myPlace?.score,
                  username: myPlace?.username,
                  rank: myPlace?.rank,
                  profileLink: myPlace?.profileLink,
                }}
                self
              />
            )}

            {!leadershipLoading && leadership.length >= 3 ? (
              <div className="top-places d-flex justify-content-center align-items-end">
                <UATopPlacePerson
                  person={{
                    id: leadership[1].id,
                    name: leadership[1].name,
                    avatar: leadership[1].avatar,
                    score: leadership[1].score,
                    username: leadership[1].username,
                    rank: leadership[1].rank,
                    profileLink: leadership[1].profileLink,
                  }}
                />
                <UATopPlacePerson
                  person={{
                    id: leadership[0].id,
                    name: leadership[0].name,
                    avatar: leadership[0].avatar,
                    score: leadership[0].score,
                    username: leadership[0].username,
                    rank: leadership[0].rank,
                    profileLink: leadership[0].profileLink,
                  }}
                />
                <UATopPlacePerson
                  person={{
                    id: leadership[2].id,
                    name: leadership[2].name,
                    avatar: leadership[2].avatar,
                    score: leadership[2].score,
                    username: leadership[2].username,
                    rank: leadership[2].rank,
                    profileLink: leadership[2].profileLink,
                  }}
                />
              </div>
            ) : (
              ""
            )}

            <div className="leadership-rest">
              {!leadershipLoading &&
                leadership
                  .slice(3, page * LIMIT)
                  .map(
                    ({
                      id,
                      name,
                      avatar,
                      score,
                      username,
                      rank,
                      profileLink,
                    }) => (
                      <UALeadershipPerson
                        key={id}
                        person={{
                          id,
                          name,
                          avatar,
                          score,
                          username,
                          rank,
                          profileLink,
                        }}
                      />
                    )
                  )}
            </div>

            {leadership.length > page * LIMIT && (
              <UAButton
                label={uaTranslate("app", "profile_screen_more_earned_things")}
                classNames="show-more-button border-alternative fw-semibold mx-auto"
                onClick={() => setPage(page + 1)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leadership;
