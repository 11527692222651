import { useEffect } from "react";
import { useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import getRoute from "../helpers/getRoute";
import UAConfirmModal from "./UAConfirmModal";

const UACaptchaForm = ({
  onSubmit,
  className,
  language,
  children,
  skipValidation,
  validationError,
  captchaAction = "",
}) => {
  const [captchaError, setCaptchaError] = useState(false);
  const [capcthaToken, setCapcthaToken] = useState(null);

  const navigate = useNavigate();
  const captchaRef = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`;
    script.async = true;
    script.defer = true;
    script.hl = language || "tr";
    script.addEventListener("load", () => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: captchaAction })
          .then((token) => {
            setCapcthaToken(token);
          });
      });
    });

    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (validationError) {
      setCaptchaError(validationError);
    }
  }, [validationError]);

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (capcthaToken) {
      if (skipValidation) {
        onSubmit && onSubmit(e, capcthaToken);
        return;
      }
    }
  };

  if (!process.env.REACT_APP_CAPTCHA_KEY) {
    return (
      <Translate>
        {({ translate }) => (
          <UAConfirmModal
            title={translate("register.captcha_error_title")}
            desc={translate("register.captcha_error_message")}
            buttons={[
              {
                label: translate("go_back"),
                onClick: () => navigate(getRoute("home").path),
              },
            ]}
          />
        )}
      </Translate>
    );
  }

  return (
    <form onSubmit={onFormSubmit} className={className}>
      {children}

      <div
        className="g-recaptcha"
        data-sitekey={process.env.REACT_APP_CAPTCHA_KEY}
        data-size="invisible"
      />

      {captchaError && (
        <Translate>
          {({ translate }) => (
            <UAConfirmModal
              title={translate("register.captcha_error_title")}
              desc={translate("register.errors.captcha_failed")}
              buttons={[
                {
                  label: translate("try_again"),
                  onClick: () => {
                    setCaptchaError(false);
                    captchaRef.current.reset();
                  },
                },
              ]}
            />
          )}
        </Translate>
      )}
    </form>
  );
};

const mapStateToProps = (state) => ({
  language: state.app.language,
});

export default connect(mapStateToProps)(UACaptchaForm);
