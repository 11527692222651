const validateInput = (input, rules) => {
  let isValid = true;
  for (const rule in rules) {
    switch (rule) {
      case "isEmail":
        isValid = isValid && emailValidator(input);
        break;
      case "isPhone":
        isValid = isValid && phoneValidator(input);
        break;
      case "isNumber":
        isValid = isValid && !isNaN(input);
        break;
      case "minLength":
        isValid = isValid && minLengthValidator(input, rules[rule]);
        break;
      case "notEmpty":
        isValid = isValid && notEmptyValidator(input);
        break;
      case "match":
        isValid = isValid && matchValidator(input, rules[rule]);
        break;
      case "has": {
        const pattern = new RegExp(rules[rule]);
        isValid = isValid && pattern.test(input);
        break;
      }
      default:
        isValid = true;
    }
  }
  return isValid;
};

const emailValidator = (input) => {
  const pattern = new RegExp(
    // eslint-disable-next-line no-control-regex
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  );
  return pattern.test(input);
};

const minLengthValidator = (input, minLength) => {
  return input.length >= minLength;
};

const notEmptyValidator = (input) => {
  return input.trim() !== "";
};

const phoneValidator = (input) => {
  const pattern = new RegExp(/^(\+[0-9]{1,3})?([0-9]{9,10})$/);
  return pattern.test(input);
};

const matchValidator = (input, match) => {
  return input === match;
};

export default validateInput;
