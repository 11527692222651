import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import { useEffect, useState } from "react";
import API from "../../../../utils/API";
import { Link } from "react-router-dom";
import getRoute from "../../../../utils/helpers/getRoute";

const targetKeyEnums = {
  workouts: "workout",
  trainers: "trainer",
  programs: "program",
};

const Slider = ({ language }) => {
  const [slides, setSlides] = useState([]);

  const handleBannerRedirectUrl = (targetKey, targetId) => {
    if (!targetKey || !targetKeyEnums[targetKey]) return null;

    return getRoute(targetKeyEnums[targetKey]).path.replace(":id", targetId);
  };

  useEffect(() => {
    API.getBanners().then(({ data: res }) => {
      setSlides(
        res.data
          .sort((a, b) => a.order - b.order)
          .map((slide) => {
            return {
              id: slide.id,
              title: slide.title,
              image: slide.image,
              link:
                slide.target_key === "web"
                  ? slide?.link
                  : handleBannerRedirectUrl(
                      slide?.target_key,
                      slide?.target_id
                    ),
            };
          })
      );
    });
  }, [language]);

  return slides.length ? (
    <div className="Slider">
      <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 7000,
          pauseOnMouseEnter: true,
        }}
        grabCursor
        loop={true}
        autoHeight={true}
        slidesPerView={1}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            {slide.link ? (
              slide.link?.startsWith("http") ? (
                <a
                  href={slide.link}
                  target="_blank"
                  rel="noreferrer"
                  title={slide.title}
                >
                  <img src={slide.image} alt={slide.title} className="w-100" />
                </a>
              ) : (
                <Link to={slide.link} title={slide.title}>
                  <img src={slide.image} alt={slide.title} className="w-100" />
                </Link>
              )
            ) : (
              <img
                src={slide.image}
                alt={slide.title}
                className="w-100"
                title={slide.title}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : null;
};

export default Slider;
