import PropTypes from "prop-types";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { TranslateContext } from "../context/TranslateContext";

const UALeadershipPerson = ({ person, self }) => {
  const { uaTranslate } = useContext(TranslateContext);
  const { name, avatar, username, score } = person;
  return (
    <Link
      to={person?.profileLink}
      className="UALeadershipPerson d-flex justify-content-between align-items-center"
      data-is-self={self}
    >
      <div className="left d-flex justify-content-start align-items-center">
        <div className="rank fw-bold">{person.rank}</div>
        <div className="avatar rounded-circle d-flex justify-content-center align-items-center flex-shrink-0">
          <img src={avatar} alt={name} className="rounded-circle w-100 h-100" />
        </div>
        <div className="user-info-area">
          <h5 className="user-name fw-semibold">{name}</h5>
          <div className="username fw-normal">@{username}</div>
        </div>
      </div>
      <div className="right d-flex flex-column align-items-center">
        <div className="score-title fw-semibold">
          {uaTranslate("app", "community_trainer_score")}
        </div>
        <div className="score fw-bold">{score}</div>
      </div>
    </Link>
  );
};

UALeadershipPerson.propTypes = {
  person: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    rank: PropTypes.number.isRequired,
  }).isRequired,
};

export default UALeadershipPerson;
