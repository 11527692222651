import { Children, cloneElement, isValidElement } from "react";
import { Translate } from "react-localize-redux";
import SEO from "../../SEO";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MobileNav from "./components/MobileNav";

const GlobalLayout = ({ children, language, isAuthenticated, user }) => {
  return (
    <Translate>
      {({ translate }) => (
        <div className="GlobalLayout min-vh-100 d-flex">
          <SEO language={language} translate={translate} />
          <div className="content w-100 d-flex flex-column">
            <Header
              translate={translate}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <MobileNav translate={translate} />
            <div className="page-content main flex-grow-1">
              {Children.map(children, (child) => {
                // Checking isValidElement is the safe way and avoids a typescript
                // error too.
                if (isValidElement(child)) {
                  return cloneElement(child, { translate, user, language });
                }
                return child;
              })}
            </div>
            <Footer translate={translate} />
          </div>
        </div>
      )}
    </Translate>
  );
};

export default GlobalLayout;
