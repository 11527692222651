const ProfileSection = ({ title, children, className = "" }) => {
  return (
    <section className={className}>
      <div className="row">
        <div className="col-12">
          <h5 className="title fw-bold">{title}</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-12">{children}</div>
      </div>
    </section>
  );
};

export default ProfileSection;
