import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../../../../utils/API";
import UAButton from "../../../../../utils/components/UAButton";
import UATabs from "../../../../../utils/components/UATabs";
import { TranslateContext } from "../../../../../utils/context/TranslateContext";
import getRoute from "../../../../../utils/helpers/getRoute";
import useUser from "../../../../../utils/hooks/useUser";

const SocialModal = ({ onClose, clicked, profileId, updateProfile }) => {
  const [followType, setFollowType] = useState(clicked);
  const [followers, setFollowers] = useState([]);
  const [followings, setFollowings] = useState([]);
  const [list, setList] = useState([]);
  const [isFollowRequestLoading, setIsFollowRequestLoading] = useState(false);
  const user = useUser();

  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    if (!profileId) return;

    const requests = [
      API.getData(`follows?user_id=${profileId}`),
      API.getData(`followers?user_id=${profileId}`),
    ];

    Promise.all(requests).then(([{ data: res }, { data: res2 }]) => {
      const profileLink =
        getRoute("community-profile", null, getRoute("community"))?.path ||
        "#!";
      const followings = res.data.map((user) => ({
        id: user.id,
        name: user.fullname,
        avatar: user.avatar,
        username: user.nickname,
        is_follow: user.is_follow,
        profileLink: profileLink.replace(":id", user.id),
      }));
      setFollowings(followings);

      const followers = res2.data.map((user) => ({
        id: user.id,
        name: user.fullname,
        avatar: user.avatar,
        username: user.nickname,
        is_follow: user.is_follow,
        profileLink: profileLink.replace(":id", user.id),
      }));
      setFollowers(followers);

      setList(followType === "following" ? followings : followers);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newList = followType === "following" ? [...followings] : [...followers];

    setList(newList);
  }, [followType, followings, followers, user?.id, profileId]);

  const handleFollow = (id) => {
    setIsFollowRequestLoading(true);
    API.followUser(id).then(({ data: res }) => {
      updateLists(id);

      const isFollowing = followings.find((user) => user.id === res?.data?.id);
      if (isFollowing) return;

      setFollowings((prev) => [
        ...prev,
        {
          id: res?.data?.id,
          name: res?.data?.fullname,
          avatar: res?.data?.avatar,
          username: res?.data?.nickname,
          is_follow: res?.data?.is_follow,
        },
      ]);
    });
  };

  const handleUnfollow = (id) => {
    setIsFollowRequestLoading(true);
    API.unfollowUser(id).then(() => {
      updateLists(id);
    });
  };

  const updateLists = (id) => {
    const newFollowings = followings.map((user) => {
      if (user.id === id) {
        user.is_follow = !user.is_follow;
      }

      return user;
    });

    const newFollowers = followers.map((user) => {
      if (user.id === id) {
        user.is_follow = !user.is_follow;
      }

      return user;
    });

    setFollowings(newFollowings);
    setFollowers(newFollowers);
    setIsFollowRequestLoading(false);
  };

  useEffect(() => {
    if (user?.id === profileId) {
      const follows_count = followings.filter((user) => user.is_follow).length;
      const followers_count = followers.length;

      updateProfile({
        follows_count,
        followers_count,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followings, followers, user?.id, profileId]);

  return (
    <div className="SocialModal">
      <h6 className="social-title w-100 text-center fw-bold">
        {uaTranslate("app", "social")}
      </h6>
      <UATabs
        tabs={[
          {
            value: "following",
            label: uaTranslate("app", "follow_list_title"),
          },
          {
            value: "follower",
            label: uaTranslate("app", "follower_list_title"),
          },
        ]}
        activeTab={followType}
        setActiveTab={setFollowType}
      />
      <div className="follows-holder">
        <h6 className="title fw-bold">
          {profileId !== user?.id
            ? followType === "following"
              ? uaTranslate("app", "members_they_follows")
              : uaTranslate("app", "following_members")
            : followType === "following"
            ? uaTranslate("app", "members_you_follow")
            : uaTranslate("app", "members_who_follow_you")}
        </h6>
        <div className="users overflow-auto">
          {list.map((listUser) => (
            <div
              key={listUser?.id}
              className="user d-flex align-items-center justify-content-between"
            >
              <div className="left d-flex justify-content-start align-items-center">
                <Link
                  to={listUser?.profileLink}
                  className="avatar flex-shrink-0 rounded-circle d-flex justify-content-center align-items-center"
                >
                  <img
                    src={listUser?.avatar}
                    alt={listUser?.name}
                    className="rounded-circle w-100 h-100"
                  />
                </Link>
                <div className="user-info d-flex flex-column align-items-start position-relative">
                  <Link
                    to={listUser?.profileLink}
                    className="name fw-semibold stretched-link"
                  >
                    {listUser?.name}
                  </Link>
                  {listUser?.username && (
                    <h6 className="username fw-normal">
                      @{listUser?.username}
                    </h6>
                  )}
                </div>
              </div>
              <div className="right d-flex justify-content-end align-items-center">
                <UAButton
                  type={listUser?.is_follow ? "gray" : "primary"}
                  label={uaTranslate(
                    "app",
                    listUser?.is_follow
                      ? `community_trainer_unfollow`
                      : `profile_screen_follow`
                  )}
                  classNames="follow-button border-alternative text-nowrap"
                  onClick={() =>
                    listUser?.is_follow
                      ? handleUnfollow(listUser?.id)
                      : handleFollow(listUser?.id)
                  }
                  disabled={isFollowRequestLoading}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <UAButton
        label={uaTranslate("app", "dialog_button_close")}
        onClick={onClose}
        type="dark"
        classNames="border-alternative text-alternative px-5 mx-auto mt-5"
      />
    </div>
  );
};

export default SocialModal;
