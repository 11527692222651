import { useContext, useEffect, useState } from "react";
import { TranslateContext } from "../../../../../utils/context/TranslateContext";

const Regional = ({ selectedFilters, handleFilterChange }) => {
  const [data, setData] = useState([]);

  const { getKeys } = useContext(TranslateContext);

  useEffect(() => {
    const keys = getKeys("body_region");
    setData(
      keys.map((item) => ({
        id: item.key,
        name: item.value,
      }))
    );
  }, [getKeys]);

  return (
    <div className="regionals d-grid">
      {data.map((regional) => (
        <div
          key={regional.id}
          className="regional d-flex flex-column justify-content-center align-items-center"
          data-is-selected={selectedFilters.id === regional.id}
          onClick={() => handleFilterChange(regional)}
          role="button"
        >
          <div className="name fw-semibold">{regional.name}</div>
        </div>
      ))}
    </div>
  );
};

export default Regional;
