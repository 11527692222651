import { authRoutes, globalRoutes, guestRoutes } from "../../App/routes";
import getRoute from "./getRoute";

const getNavRoutes = (footerNav = false) =>
  [...globalRoutes, ...authRoutes, ...guestRoutes]
    .filter((route) => (footerNav ? route.onFooterNav : route.onNav))
    .sort((a, b) => a.order - b.order)
    .map((route) => getRoute(route.name));

export default getNavRoutes;
