import { Link } from "react-router-dom";
import socialMedias from "../../../../utils/db/socialMedias";
import getRoute from "../../../../utils/helpers/getRoute";

const Footer = () => {
  return (
    <div className="guest-layout-footer">
      <div className="row">
        <div className="col-12">
          <div className="sm-holder d-flex justify-content-center align-items-center">
            {socialMedias
              .sort((a, b) => a.order - b.order)
              .map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="sm-item color--gray--light"
                >
                  <item.icon />
                </a>
              ))}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="footer-nav d-none justify-content-center align-items-center">
            <Link
              to={getRoute("home").path}
              className="footer-nav-link color--gray--light"
            >
              uLive
            </Link>
            <Link
              to={getRoute("home").path}
              className="footer-nav-link color--gray--light"
            >
              Koşullar
            </Link>
            <Link
              to={getRoute("home").path}
              className="footer-nav-link color--gray--light"
            >
              İletişim
            </Link>
            <Link
              to={getRoute("home").path}
              className="footer-nav-link color--gray--light"
            >
              SSS
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="copyright m-0 d-flex justify-content-center align-items-center color--gray--light">
            &copy; {new Date().getFullYear()} UrbanActive
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
