import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import API from "../../../utils/API";
import Loading from "../../../utils/components/Loading";
import UAButton from "../../../utils/components/UAButton";
import UAInput from "../../../utils/components/UAInput";
import getRoute from "../../../utils/helpers/getRoute";
import { login } from "../../../utils/redux/auth/authActions";
import Code from "./components/Code";
import { confirmAlert } from "react-confirm-alert";
import UAConfirmModal from "../../../utils/components/UAConfirmModal";

const ResetPassword = ({ translate, login }) => {
  const [code, setCode] = useState(["", "", "", ""]);
  const [codeChecking, setCodeChecking] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [hasAutoSent, setHasAutoSent] = useState(false);
  const [sendcode, setSendcode] = useState(null);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, setErrors] = useState({});

  const [isSending, setIsSending] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (password && confirmPassword) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }
  }, [password, confirmPassword]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (isSending || !password || !confirmPassword) return;

    if (password !== confirmPassword) {
      setErrors({ confirmPassword: translate("passwords_not_match") });
      return;
    }

    setIsSending(true);

    const data = {
      sendcode,
      verification_code: parseInt(code.join("")),
      new_password: password,
      confirm_new_password: confirmPassword,
    };

    API.postData("auth/password", data)
      .then(({ data: res }) => {
        API.getAccountData(res.data.access_token).then(
          ({ data: accountRes }) => {
            confirmAlert({
              customUI: ({ onClose }) => (
                <UAConfirmModal
                  title={translate("reset_password.success_title")}
                  buttons={[
                    {
                      label: translate("okay"),
                      onClick: () => {
                        onClose();
                        login({ ...res.data, ...accountRes.data });
                      },
                    },
                  ]}
                >
                  {translate("reset_password.success_message")}
                </UAConfirmModal>
              ),
            });
          }
        );
      })
      .catch(() => {
        setIsSending(false);
      });
  };

  useEffect(() => {
    const sendcode = new URLSearchParams(window.location.search).get(
      "sendcode"
    );

    if (sendcode) {
      setSendcode(sendcode);
      document.getElementById("code-0").focus();
    } else navigate(getRoute("home").path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendcode]);

  useEffect(() => {
    if (code.join("").length === 4 && !hasAutoSent) {
      setHasAutoSent(true);
      handleSubmitCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const handleSetCode = (e, index) => {
    const { value } = e.target;
    if (value.length > 1 || isNaN(value)) return;

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (index < 3 && value) {
      document.getElementById(`code-${index + 1}`).focus();
    }
  };

  const handleSubmitCode = (e) => {
    if (e) e.preventDefault();

    setCodeChecking(true);

    const codeString = code.join("");

    if (codeString.length !== 4 || isNaN(parseInt(codeString))) return;

    API.postData("auth/password/otp/verification", {
      sendcode,
      verification_code: parseInt(codeString),
    })
      .then(() => {
        setCodeSent(true);
      })
      .catch(() => setCodeChecking(false));
  };

  return (
    <div className="ResetPassword ForgotPassword">
      <Code
        code={code}
        handleSetCode={handleSetCode}
        handleSubmitCode={handleSubmitCode}
        translate={translate}
        codeChecking={codeChecking}
        codeSent={codeSent}
        emailOrPhone={sendcode}
      />
      {codeSent && (
        <form onSubmit={onSubmit} className="w-100 d-flex flex-column">
          <UAInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            label={translate("password")}
            inputProps={{
              inputMode: "password",
            }}
            error={"password" in errors ? errors["password"] : false}
          />

          <UAInput
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            name="password"
            label={translate("confirmPassword")}
            inputProps={{
              inputMode: "password",
            }}
            error={
              "confirmPassword" in errors ? errors["confirmPassword"] : false
            }
          />
          <UAButton
            label={
              isSending ? <Loading /> : translate("forgot_password.send_button")
            }
            htmlType="submit"
            disabled={!isButtonActive || isSending}
            classNames="text-uppercase"
          />
          <div className="already-user text-center color--white">
            {translate("forgot_password.login_link", {
              link: (
                <Link to={getRoute("login").path} className="color--secondary">
                  {translate("forgot_password.login_link_text")}
                </Link>
              ),
            })}
          </div>
        </form>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(login(user)),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
