import { useContext } from "react";
import { TranslateContext } from "../../../context/TranslateContext";

const Footnote = () => {
  const { uaTranslate } = useContext(TranslateContext);
  return (
    <section className="Paywall__content__section d-flex flex-column w-100">
      <div className="footnote-content">
        <p className="first text-center">
          {uaTranslate("app", "paywall_web_intorductory_text")}
        </p>
        <p className="last text-center">
          {uaTranslate("app", "paywall_web_aggrement_text")}
        </p>
      </div>
    </section>
  );
};

export default Footnote;
