import { useEffect, useRef } from "react";
import { useState } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { useNavigate, useParams } from "react-router";
import { CSSTransition } from "react-transition-group";
import API from "../../../utils/API";
import UAModal from "../../../utils/components/UAModal";
import getRoute from "../../../utils/helpers/getRoute";
import Trainer from "./components/Trainer";
import { default as TrainerDetail } from "./Trainer";
import ContentLanguageSelect from "../../../utils/components/ContentLanguageSelect";

const Trainers = ({ activeLanguage }) => {
  const [trainers, setTrainers] = useState([]);
  const [trainer, setTrainer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const modalRef = useRef(null);
  const { id } = useParams();

  const handleSetTrainers = (data) => {
    setTrainers(
      data.map((trainer) => ({
        id: trainer.id,
        slug: trainer.slug,
        name: trainer.fullname,
        image: trainer.image_list,
      }))
    );
  };

  useEffect(() => {
    API.getData(`trainers?languages_filter=${activeLanguage.code}`).then(
      ({ data: res }) => {
        handleSetTrainers(res.data);
      }
    );
  }, [activeLanguage.code]);

  useEffect(() => {
    if (id) {
      API.getData(`/trainers/${id}`).then(({ data: res }) => {
        const { data } = res;

        setTrainer({
          id,
          name: data.fullname,
          bio: data.bio,
          motto: data.motto,
          spotify_link: data.spotify_link,
          instagram_link: data.instagram_link,
          image: data.image_detail,
          slug: data.slug,
        });
      });

      setIsModalOpen(true);
    }

    return () => {
      setIsModalOpen(false);
    };
  }, [id]);

  return (
    <div className="Trainers">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-8">
            <div className="page-title fw-bold">
              <Translate id="page_title.trainers" />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <ContentLanguageSelect
              path="trainers"
              handleSet={handleSetTrainers}
              activeLanguage={activeLanguage}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="trainers-list d-grid">
              {trainers.map((trainer) => (
                <Trainer key={trainer.id} trainer={trainer} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <CSSTransition
        in={isModalOpen}
        timeout={300}
        unmountOnExit
        classNames="UAModal"
        nodeRef={modalRef}
        onExited={() => setTrainer(null)}
      >
        <UAModal
          handleClose={() => navigate(getRoute("trainers").path)}
          propRef={modalRef}
        >
          <TrainerDetail type="recorded" trainer={trainer} />
        </UAModal>
      </CSSTransition>
    </div>
  );
};

export default withLocalize(Trainers);
