import { differenceInMilliseconds, format } from "date-fns";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Translate } from "react-localize-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import UAButton from "../../../utils/components/UAButton";
import getRoute from "../../../utils/helpers/getRoute";
import Countdown from "react-countdown";
import { useContext } from "react";
import { TranslateContext } from "../../../utils/context/TranslateContext";
import checkIfDateIsValid from "../../../utils/helpers/checkIfDateIsValid";

const LiveWorkout = ({ workout }) => {
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);

  const navigate = useNavigate();
  const { id, slug } = useParams();
  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    if (!id) navigate(getRoute("workouts").path);
    if (!slug && workout?.slug && id === workout.id)
      navigate(
        getRoute("workout").path.replace(":id", id) + `/${workout.slug}`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, workout]);

  useEffect(() => {
    if (workout?.id)
      setTabs([
        {
          id: "equipments",
          title: uaTranslate("app", "workouts_detail_equipment"),
          content:
            workout?.equipment
              ?.map((item) => uaTranslate("equipment", item))
              .join(", ") || "",
        },
        {
          id: "workout",
          title: uaTranslate("app", "workouts_workouts"),
          content: workout?.training?.replace(/\r\n/g, "<br />") || "",
        },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout?.id, workout?.equipment, workout?.training]);

  useEffect(() => {
    if (tabs.length) setSelectedTab(tabs[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  useEffect(() => {
    if (selectedTab) {
      const selectedBgEl = document.querySelector(
        `.Workout .tabs .selected-bg`
      );

      const index = tabs.findIndex((tab) => tab.id === selectedTab.id);

      if (selectedBgEl)
        selectedBgEl.style.transform = `translateX(${index * 100}%)`;
    }
  }, [selectedTab, tabs]);

  return (
    <div className="Workout Live">
      <Helmet>
        <title>{workout?.title}</title>
      </Helmet>
      <div className="workout-info-area d-flex flex-column position-relative">
        <h1 className="workout-title text-center">{workout?.title}</h1>
        <div className="cover w-100 d-flex justify-content-center align-items-center overflow-hidden">
          <img
            src={workout?.cover}
            alt={`${workout?.title} Cover`}
            className="h-100"
          />
        </div>
        <section className="middle-section d-flex flex-column align-items-center">
          <div className="live-badge text-white fw-bold">
            <Translate id="live_lesson" />
          </div>
          <div className="date fw-medium">
            {checkIfDateIsValid(workout?.startsAt?.replace(/-/g, "/")) &&
              format(
                new Date(workout?.startsAt?.replace(/-/g, "/")),
                "HH:mm - dd/MM EEEE"
              )}
          </div>
          <h3 className="trainer fw-normal m-0">{workout?.trainer?.name}</h3>
        </section>
      </div>
      <section className="workout-content-area">
        <div className="tabs d-grid w-100 position-relative">
          <div className="selected-bg position-absolute" />
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`tab text-center ${
                selectedTab?.id === tab?.id ? "active" : ""
              }`}
              onClick={() => setSelectedTab(tab)}
              role="button"
            >
              {tab.title}
            </div>
          ))}
        </div>
        <div
          className="tab-content"
          dangerouslySetInnerHTML={{ __html: selectedTab?.content }}
        />
        {checkIfDateIsValid(workout?.startsAt?.replace(/-/g, "/")) &&
          differenceInMilliseconds(
            new Date(workout.startsAt?.replace(/-/g, "/")),
            new Date()
          ) > 0 && (
            <div className="time-left d-flex flex-column align-items-center mx-auto">
              <Translate id="workouts.time_left" />
              <Countdown
                date={new Date(workout.startsAt?.replace(/-/g, "/"))}
                daysInHours={true}
                className="countdown fw-medium"
              />
            </div>
          )}
      </section>
      <div className="start-button w-100 d-flex justify-content-center">
        <Translate>
          {({ translate }) => (
            <UAButton
              classNames={
                !checkIfDateIsValid(workout?.startsAt?.replace(/-/g, "/")) ||
                differenceInMilliseconds(
                  new Date(workout.startsAt?.replace(/-/g, "/")),
                  new Date()
                ) > 0
                  ? "border-alternative text-alternative"
                  : ""
              }
              type={
                !checkIfDateIsValid(workout?.startsAt?.replace(/-/g, "/")) ||
                differenceInMilliseconds(
                  new Date(workout.startsAt?.replace(/-/g, "/")),
                  new Date()
                ) > 0
                  ? "secondary"
                  : "primary"
              }
              label={translate(
                !checkIfDateIsValid(workout?.startsAt?.replace(/-/g, "/")) ||
                  differenceInMilliseconds(
                    new Date(workout.startsAt?.replace(/-/g, "/")),
                    new Date()
                  ) > 0
                  ? "close"
                  : "start"
              )}
              action={
                checkIfDateIsValid(workout?.startsAt?.replace(/-/g, "/"))
                  ? differenceInMilliseconds(
                      new Date(workout.startsAt?.replace(/-/g, "/")),
                      new Date()
                    ) > 0
                    ? getRoute("workouts").path
                    : getRoute("workout-watch")
                        .path.replace(":id", workout?.id)
                        .replace(":slug", workout?.slug)
                        .replace(":watch", translate("watch").toLowerCase())
                  : getRoute("workouts").path
              }
              isLink
            />
          )}
        </Translate>
      </div>
    </div>
  );
};

LiveWorkout.propTypes = {
  workout: PropTypes.shape({
    id: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    difficulty: PropTypes.string,
    trainer: PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
    }),
    equipment: PropTypes.any,
    training: PropTypes.any,
    cover: PropTypes.string,
    isBookmarked: PropTypes.bool,
    videoLink: PropTypes.string,
  }),
};

export default LiveWorkout;
