import { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import Login from "../../pages/guest/Login/Login";

const UALoginModal = ({ onClose, onLogin, propRef, user }) => {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    document.body.classList.add("login-modal-open");

    return () => {
      document.body.classList.remove("login-modal-open");
    };
  }, []);

  useEffect(() => {
    if (user?.id && onLogin) {
      onLogin();
    }
  }, [onLogin, user?.id]);

  return (
    <div
      className="UALoginModal position-fixed vw-100 vh-100 d-flex justify-content-center align-items-center"
      ref={propRef}
    >
      <div
        className="overlay position-absolute vw-100 vh-100"
        onClick={onClose}
        role="button"
      ></div>
      <Translate>
        {({ translate }) => {
          return (
            <div className="modal-content bg--gray--dark position-relative">
              <div
                className="close-btn d-flex position-fixed"
                onClick={onClose}
                role="button"
              >
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 24C14 18.4772 18.4772 14 24 14V14C29.5228 14 34 18.4772 34 24V24C34 29.5228 29.5228 34 24 34V34C18.4772 34 14 29.5228 14 24V24Z"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M26.8337 21.167L21.167 26.8337M21.167 21.167L26.8337 26.8337L21.167 21.167Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h6 className="page-title color--white text-uppercase text-center">
                {translate("page_title.login")}
              </h6>
              <Login translate={translate} />
            </div>
          );
        }}
      </Translate>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(UALoginModal);
