import Vimeo from "@u-wave/react-vimeo";
import { useContext, useEffect, useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import ReactPlayer from "react-player";
import { useParams } from "react-router";
import API from "../../../utils/API";
import Loading from "../../../utils/components/Loading";
import UAButton from "../../../utils/components/UAButton";
import getIcon from "../../../utils/helpers/getIcon";
import PremiumError from "./components/PremiumError";
import { TranslateContext } from "../../../utils/context/TranslateContext";

const initBeforeUnLoad = (showExitPrompt, callback) => {
  window.onbeforeunload = showExitPrompt
    ? (event) => {
        if (showExitPrompt) {
          const e = event || window.event;
          e.preventDefault();
          if (e) {
            callback && callback();
            e.returnValue = "";
          }
          return "";
        }
      }
    : null;
};

const Watch = ({ handleClose, propRef, workout, beforeCloseWatch }) => {
  const [videoState, setVideoState] = useState({
    isPlaying: true,
    isMuted: true,
    time: 0,
    timeLeft: 0,
    percentage: 0,
    isStarted: false,
    isEnded: false,
  });

  const [premiumError, setPremiumError] = useState(false);

  const [video, setVideo] = useState(null);

  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);
  const [controls, setControls] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [hideControls, setHideControls] = useState(false);

  const hideControlTimeout = useRef(null);

  const { id } = useParams();

  const playerRef = useRef(null);

  const { uaTranslate } = useContext(TranslateContext);

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt, beforeCloseWatch);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(showExitPrompt, beforeCloseWatch);

    return () => {
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExitPrompt]);

  useEffect(() => {
    if (workout?.isLive) setControls(false);
    else setControls(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout?.isLive]);

  useEffect(() => {
    if (video && controls) {
      document.addEventListener("keydown", handleKeys, false);
    }
    return () => {
      document.removeEventListener("keydown", handleKeys, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  useEffect(() => {
    document.addEventListener("mousemove", handleHideControls, false);

    return () =>
      document.removeEventListener("mousemove", handleHideControls, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, videoState?.isPlaying]);

  useEffect(() => {
    if (!videoState?.isPlaying) setHideControls(false);
    else handleHideControls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoState?.isPlaying]);

  const handleHideControls = () => {
    setHideControls(false);

    // if (!videoState?.isPlaying || !controls) return;

    if (hideControlTimeout) clearTimeout(hideControlTimeout.current);

    hideControlTimeout.current = setTimeout(() => {
      setHideControls(true);
    }, 3000);
  };

  const handleSeek = async (amount = 10) => {
    setIsLoading(true);
    playerRef.current.pause();
    if (amount < 10) setActionType("rewind");
    else setActionType("fast-forward");

    setIsActionInProgress(true);
    const currentTime = await playerRef.current.getCurrentTime();

    let newTime = currentTime + amount;
    if (newTime < 0) newTime = 0;

    playerRef.current.setCurrentTime(newTime).then(() => {
      setIsLoading(false);
      playerRef.current.play();
    });
  };

  const handleKeys = (e) => {
    if (e.key === "ArrowLeft") {
      handleSeek(-10);
    }
    if (e.key === "ArrowRight") {
      handleSeek(10);
    }

    if (e.key === "m") {
      setVideoState((prevState) => ({
        ...prevState,
        isMuted: !prevState.isMuted,
      }));
    }

    if (e.key === " ") {
      e.preventDefault();
      handlePausePlay();
    }

    if (e.key === "Escape") {
      e.preventDefault();
      handleClose();
    }
  };

  const handlePausePlay = () => {
    if (!controls) return;

    setVideoState((prevState) => ({
      ...prevState,
      isPlaying: !prevState.isPlaying,
    }));
  };

  const handleToggleMute = () => {
    if (!controls) return;

    if (videoState.isMuted) setActionType("unmute");
    else setActionType("mute");

    setIsActionInProgress(true);

    setVideoState((prevState) => ({
      ...prevState,
      isMuted: !prevState.isMuted,
    }));
  };

  useEffect(() => {
    if (isActionInProgress) {
      setTimeout(() => {
        setIsActionInProgress(false);
      }, 250);
      setTimeout(() => {
        setActionType(null);
      }, 500);
    }
  }, [isActionInProgress]);

  useEffect(() => {
    if (id) {
      API.getData(`workouts/${id}/videos/link`)
        .then(({ data: res }) => {
          let link = res.data.link;

          if (link.includes("external"))
            link = link.split("external/")[1].split(".")[0];

          setVideo(link);
        })
        .catch((err) => {
          if (String(err?.status) === "402") {
            setPremiumError(true);
            return;
          }
          handleClose();
        });
    }

    return () => {
      setVideo(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleFullScreen = () => {
    // exit full screen if already in full screen
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }

    // enter full screen
    if (propRef.current.requestFullscreen) {
      propRef.current.requestFullscreen();
    } else if (propRef.current.mozRequestFullScreen) {
      /* Firefox */
      propRef.current.mozRequestFullScreen();
    } else if (propRef.current.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      propRef.current.webkitRequestFullscreen();
    } else if (propRef.current.msRequestFullscreen) {
      /* IE/Edge */
      propRef.current.msRequestFullscreen();
    }
  };

  const handleVideoLineClick = (e) => {
    setIsLoading(true);
    playerRef.current.pause();
    playerRef.current.getDuration().then((duration) => {
      const percentage = (e.clientX / window.innerWidth) * 100;
      const newTime = (duration / 100) * percentage;
      setVideoState((prevState) => ({
        ...prevState,
        percentage,
      }));
      playerRef.current.setCurrentTime(newTime).then(() => {
        setIsLoading(false);
        playerRef.current.play();
      });
    });
  };

  return (
    <div
      className="Watch position-fixed vw-100 vh-100"
      ref={propRef}
      data-is-live={workout?.isLive}
    >
      <div className="content-area w-100 h-100 position-relative d-flex justify-content-center align-items-center">
        <div
          className="overlay w-100 h-100 position-fixed"
          role="button"
          onClick={() => handlePausePlay()}
        />

        {premiumError ? (
          <PremiumError setShowExitPrompt={setShowExitPrompt} />
        ) : (
          ""
        )}

        {isLoading && (
          <div className="w-100 h-100 position-fixed seeking-loader d-flex justify-content-center align-items-center">
            <Loading light />
          </div>
        )}

        {video ? (
          workout?.isLive ? (
            <ReactPlayer
              url={video}
              width="100%"
              height="100%"
              playing={true}
              controls={false}
              style={{ width: "100%" }}
              onReady={(e) => {
                const player = e.getInternalPlayer();
                player.setAttribute("muted", "true");
                setIsPlayerLoaded(true);
              }}
              onEnded={() => {
                setVideoState((prevState) => ({
                  ...prevState,
                  isPlaying: false,
                  isEnded: true,
                }));
              }}
              onPlay={() => {
                setTimeout(() => {
                  setVideoState((prevState) => ({
                    ...prevState,
                    isMuted: false,
                  }));
                }, 200);
              }}
              autoPlay
              playsInline
              muted={videoState.isMuted}
              volume={videoState.isMuted ? 0 : 1}
            />
          ) : (
            <Vimeo
              id="vimeo-player"
              video={video}
              style={{ width: "100%" }}
              responsive={true}
              showTitle={false}
              showPortrait={false}
              showByline={false}
              autoplay={true}
              background={true}
              volume={videoState.isMuted ? 0 : 1}
              paused={!videoState.isPlaying}
              onReady={(player) => {
                if (!player) return;

                setIsPlayerLoaded(true);
                playerRef.current = player;

                player.on("timeupdate", (e) => {
                  setVideoState((prevState) => ({
                    ...prevState,
                    time: e.seconds,
                    percentage: e.percent * 100,
                    timeLeft: e.duration - e.seconds,
                  }));
                });

                player.on("ended", () => {
                  setVideoState((prevState) => ({
                    ...prevState,
                    isPlaying: false,
                    isEnded: true,
                  }));
                });

                player.on("play", () => {
                  setActionType("play");
                  setIsActionInProgress(true);
                  setTimeout(() => {
                    setVideoState((prevState) => ({
                      ...prevState,
                      isPlaying: true,
                      isMuted: false,
                    }));
                  }, 200);
                });

                player.on("pause", () => {
                  setActionType("pause");
                  setIsActionInProgress(true);
                  setTimeout(() => {
                    setVideoState((prevState) => ({
                      ...prevState,
                      isPlaying: false,
                    }));
                  });
                });

                player.on("progress", (e) => {
                  setVideoState((prevState) => ({
                    ...prevState,
                    buffer: e.percent * 100,
                  }));
                });
              }}
            />
          )
        ) : (
          ""
        )}
        {!isPlayerLoaded && (
          <div className="loading-content position-absolute">
            <Loading light />
          </div>
        )}
      </div>
      <span
        className="action-feedback position-absolute"
        data-is-in={isActionInProgress}
      >
        {getIcon(
          actionType === "rewind"
            ? "rewind10"
            : actionType === "fast-forward"
            ? "fastForward10"
            : actionType === "play"
            ? "play"
            : actionType === "pause"
            ? "pause"
            : actionType === "mute" || actionType === "unmute"
            ? "speaker"
            : "",
          { alternative: actionType === "mute" ? `muted` : `player` }
        )}
      </span>
      <div className="video-top-section position-fixed w-100 d-flex justify-content-end"></div>

      {isPlayerLoaded ? (
        <div
          className="video-info position-absolute w-100"
          data-is-hidden={hideControls && videoState?.isPlaying}
        >
          {controls && (
            <div
              className="video-line position-relative w-100"
              style={{
                "--buffered": `${videoState.buffer}%`,
                "--watched": `${videoState.percentage}%`,
              }}
              onClick={(e) => handleVideoLineClick(e)}
              role="button"
            >
              <div className="buffer-line position-absolute"></div>
              <div className="watched-line position-absolute"></div>
              <div className="time-point position-absolute rounded-circle" />
            </div>
          )}
          <div className="info-area d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div className="trainer-info d-flex justify-content-start align-items-center mb-3 mb-md-0">
              <div className="avatar rounded-circle overflow-hidden me-2">
                <img src={workout?.cover} alt="" className="w-100 h-100" />
              </div>
              <div className="info d-flex flex-column align-items-start">
                <div className="workout-title fw-semibold">
                  {workout?.title}
                </div>
                <span className="trainer-name fw-medium">
                  {workout?.trainer?.name}
                </span>
              </div>
            </div>
            <div className="video-actions d-flex justify-content-end align-items-center">
              {controls && (
                <div
                  className="rewind action-button"
                  role="button"
                  onClick={() => handleSeek(-10)}
                >
                  {getIcon("rewind10")}
                </div>
              )}
              {controls && (
                <div
                  className="rewind action-button"
                  role="button"
                  onClick={() => handleSeek(10)}
                >
                  {getIcon("fastForward10")}
                </div>
              )}
              {controls && (
                <div
                  className="playPause action-button"
                  role="button"
                  onClick={() => {
                    handlePausePlay();
                  }}
                >
                  {getIcon(videoState.isPlaying ? "pause" : "play", {
                    alternative: "player",
                  })}
                </div>
              )}
              {controls && (
                <div
                  className="mute action-button"
                  role="button"
                  onClick={() => {
                    handleToggleMute();
                  }}
                >
                  {getIcon("speaker", {
                    alternative: videoState?.isMuted ? `muted` : null,
                  })}
                </div>
              )}
              {workout?.isLive && (
                <div className="live-indicator fw-bold">
                  <Translate id="live" />
                </div>
              )}

              <div
                className="fullScreen action-button"
                role="button"
                onClick={() => handleFullScreen()}
              >
                {getIcon("fullscreen")}
              </div>

              <UAButton
                label={uaTranslate("app", "player_close_popup_action_button")}
                classNames="close-button fw-semibold text-alternative"
                onClick={() => {
                  setShowExitPrompt(false);
                  handleClose();
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="video-info position-absolute w-100 d-flex justify-content-end align-items-center">
          <UAButton
            label={<Translate id="close" />}
            classNames="close-button fw-semibold text-alternative"
            onClick={() => {
              setShowExitPrompt(false);
              handleClose();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Watch;
