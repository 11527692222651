import { useContext, useEffect, useState } from "react";
import { FaInstagram, FaSpotify } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import API from "../../../utils/API";
import UAWorkout from "../../../utils/components/UAWorkout";
import getRoute from "../../../utils/helpers/getRoute";
import { ScrollContainer } from "react-indiana-drag-scroll";
import "react-indiana-drag-scroll/dist/style.css";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { TranslateContext } from "../../../utils/context/TranslateContext";

const Trainer = ({ trainer }) => {
  const [workouts, setWorkouts] = useState([]);

  const navigate = useNavigate();
  const { id, slug } = useParams();

  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    if (!id) navigate(getRoute("trainers").path);
    if (!slug && trainer?.slug && id === trainer.id)
      navigate(
        getRoute("trainer").path.replace(":id", id) + `/${trainer.slug}`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, trainer]);

  useEffect(() => {
    if (id) {
      API.getData(`workouts?trainer_id=${id}`).then(({ data: res }) => {
        setWorkouts(
          res.data.map((item) => ({
            id: item.id,
            slug: item.slug,
            title: item.title,
            duration: item.duration,
            startsAt: item.starts_at,
            image: item.image_detail,
            trainer: item.trainer_name,
          }))
        );
      });
    }
  }, [id]);

  return (
    <div className="TrainerDetail position-relative overflow-hidden">
      <Helmet>
        <title>{trainer?.name}</title>
      </Helmet>
      <div className="trainer-image w-100 h-100 d-flex justify-content-end align-items-start overflow-hidden position-absolute">
        <img src={trainer?.image} className="w-100" alt="" />
      </div>
      <div className="trainer-info-area position-relative d-flex flex-column">
        <h3 className="name fw-bold">{trainer?.name}</h3>
        <div
          className="bio"
          dangerouslySetInnerHTML={{ __html: trainer?.bio }}
        />
        <div className="motto">{trainer?.motto}</div>
        <section className="socials d-flex justify-content-start align-items-center">
          {trainer?.instagram_link && (
            <a
              href={trainer?.instagram_link}
              target="_blank"
              rel="noreferrer"
              className="social-link rounded-circle bg-white d-flex justify-content-center align-items-center instagram"
            >
              <FaInstagram />
            </a>
          )}
          {trainer?.spotify_link && (
            <a
              href={trainer?.spotify_link}
              target="_blank"
              rel="noreferrer"
              className="social-link rounded-circle bg-white d-flex justify-content-center align-items-center spotify"
            >
              <FaSpotify />
            </a>
          )}
        </section>
        {workouts.length && (
          <section className="latest-workouts position-relative">
            <div className="top d-flex justify-content-between">
              <h6 className="section-title text-white m-0">
                {uaTranslate("app", "trainers_detail_last_workouts")}
              </h6>
              <Link
                to={getRoute("workouts").path}
                state={{ trainerId: trainer.id }}
                className="text-white"
              >
                {uaTranslate("app", "see_all_workouts_of_trainer")}
              </Link>
            </div>
            <div className="workouts-slider-holder w-100 overflow-auto">
              <ScrollContainer>
                <div className="workouts-slider d-flex justify-content-start align-items-center">
                  {workouts.map((workout) => (
                    <UAWorkout key={workout.id} workout={workout} />
                  ))}
                </div>
              </ScrollContainer>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default Trainer;
