import { useEffect, useState } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import Router from "./App/Router";

import "./assets/app.scss";
import { login } from "./utils/redux/auth/authActions";

import setDefaultOptions from "date-fns/setDefaultOptions";
import tr from "date-fns/locale/tr";
import en from "date-fns/locale/en-US";
import { useContext } from "react";
import { TranslateContext } from "./utils/context/TranslateContext";
import { setLanguage } from "./utils/redux/app/appActions";
import API from "./utils/API";

const App = ({ setActiveLanguage }) => {
  const [userLoaded, setUserLoaded] = useState(false);

  const { getTranslateData } = useContext(TranslateContext);
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const { language } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    const cookie = new Cookies();

    const token = cookie.get("access_token");

    if (token) {
      const auth_user = JSON.parse(localStorage.getItem("auth_user"));

      if (auth_user && !isAuthenticated) {
        API.getAccountData(token).then(({ data: accountRes }) => {
          dispatch(
            login({ ...auth_user, ...accountRes.data, access_token: token })
          );

          dispatch(setLanguage(auth_user.lang));
          setActiveLanguage(auth_user.lang);

          setUserLoaded(true);
        });
      } else {
        dispatch(setLanguage(language));
        setActiveLanguage(language);
        setUserLoaded(true);
      }
    } else {
      dispatch(setLanguage(language));
      setActiveLanguage(language);
      setUserLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const locale = language === "tr" ? tr : en;
    setDefaultOptions({ locale });
    getTranslateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (user?.lang && user?.lang !== language) {
      setLanguage(user?.lang);
      setActiveLanguage(user?.lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return userLoaded ? (
    <Translate>
      {() => {
        return (
          <div className="App">
            <Router auth={auth} app={app} />
          </div>
        );
      }}
    </Translate>
  ) : (
    "Loading..."
  );
};

export default withLocalize(App);
