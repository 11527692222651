import Cookies from "universal-cookie";

const initState = {
  isAuthenticated: false,
  user: {},
  isSendingVerificationCode: false,
};

const cookie = new Cookies();

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN": {
      if (!action.user.access_token) {
        return state;
      }

      const cookieOptions = {
        path: "/",
        sameSite: "strict",
      };

      if (action.user.rememberMe) cookieOptions.maxAge = 30 * 24 * 60 * 60;

      cookie.set("access_token", action.user.access_token, cookieOptions);

      if (action.user.refresh_token)
        cookie.set("refresh_token", action.user.refresh_token, cookieOptions);

      const localUser = {
        id: action.user.id,
        fullName: `${action.user.firstname} ${action.user.lastname}`,
        firstName: action.user.firstname,
        lastName: action.user.lastname,
        email: action.user.email,
        phone: action.user.gsm,
        avatar: action.user.avatar,
        lang: action.user.locale,
        isPremium: action.user.is_premium,
      };

      localStorage.setItem("auth_user", JSON.stringify(localUser));

      return {
        ...state,
        isAuthenticated: true,
        user: localUser,
      };
    }

    case "SET_USER": {
      const localUser = {
        id: action.user.id,
        fullName: `${action.user.firstname} ${action.user.lastname}`,
        firstName: action.user.firstname,
        lastName: action.user.lastname,
        email: action.user.email,
        phone: action.user.gsm,
        avatar: action.user.avatar,
        lang: action.user.locale,
        isPremium: action.user.is_premium,
      };

      localStorage.setItem("auth_user", JSON.stringify(localUser));

      return {
        ...state,
        user: localUser,
      };
    }

    case "UPDATE_USER": {
      const localUser = JSON.parse(localStorage.getItem("auth_user"));

      const newUser = {
        ...localUser,
        ...action.user,
      };

      localStorage.setItem("auth_user", JSON.stringify(newUser));

      return {
        ...state,
        user: newUser,
      };
    }

    case "LOGOUT": {
      cookie.remove("access_token", { path: "/", sameSite: "strict" });
      cookie.remove("refresh_token", { path: "/", sameSite: "strict" });
      localStorage.removeItem("auth_user");

      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    }

    case "SENDING_VERIFICATION_CODE": {
      return {
        ...state,
        isSendingVerificationCode: action.isSending,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
