/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import Cookies from "universal-cookie";
import store from "../store";
import { logout } from "./redux/auth/authActions";
import toastr from "toastr";

let cancelSource = axios.CancelToken.source();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_V2,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const handleCancelRequest = () => {
  cancelSource.cancel("Operation canceled by the user.");
};

instance.interceptors.request.use(
  function (config) {
    cancelSource = axios.CancelToken.source();
    config.cancelToken = cancelSource.token;

    const cookie = new Cookies();
    const token = cookie.get("access_token");

    if (token && !config.url.includes("refresh_token")) {
      config.headers["Authorization"] = token;
    }

    config.headers["Accept-Language"] = store.getState().app.language;
    //  config.headers["Device-Region"] = navigator.language;
    config.headers["Device-Type"] = "web";
    config.headers["Environment"] = process.env.REACT_APP_ENV_TYPE || "test";

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (axios.isCancel(error)) {
      return Promise.reject(error.message || "Request canceled");
    }
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 500
    ) {
      if (error.response.status === 401) {
        handleCancelRequest();

        const cookie = new Cookies();
        const refresh_token = cookie.get("refresh_token");

        if (refresh_token && !error.config.url.includes("refresh")) {
          instance({
            method: "post",
            url: "auth/refresh",
            data: {
              refresh_token,
            },
          }).then((response) => {
            console.log(response);
          });
        } else {
          store.dispatch(logout());
        }
      }

      if (
        error.config.url.includes("complete") ||
        error.response.status === 402
      )
        return Promise.reject(error?.response?.data);

      console.error(
        error.response?.data?.user_message ||
          `${error.response.status} ${error.response.statusText}`
      );

      if (error.response?.data?.user_message) {
        toastr.clear();
        toastr.error(
          error.response?.data?.user_message ||
            `${error.response.status} ${error.response.statusText}`
        );
      }

      return Promise.reject(error?.response?.data);
    }

    return Promise.reject(error);
  }
);

export default {
  getData: (url) =>
    instance({
      method: "GET",
      url,
    }),

  postData: (url, data = {}) =>
    instance({
      method: "POST",
      url,
      data,
    }),

  putData: (url, data = {}) =>
    instance({
      method: "PUT",
      url,
      data,
    }),

  deleteData: (url, data = {}) =>
    instance({
      method: "DELETE",
      url,
      data,
    }),

  register: (data) => {
    return instance({
      method: "POST",
      url: "users",
      data: {
        phone_language: store.getState().app.language,
        ...data,
      },
    });
  },
};
