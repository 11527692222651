import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../utils/API";
import Loading from "../../../utils/components/Loading";
import { sendingVerificationCode } from "../../../utils/redux/auth/authActions";
import toastr from "toastr";

const Confirm = () => {
  const { isSendingVerificationCode } = useSelector((state) => state.auth);
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token || isSendingVerificationCode) return;

    dispatch(sendingVerificationCode(true));

    API.getData(`email-verification/${token}`)
      .then(({ data: res }) => {
        toastr.success(res.user_message);
        navigate("/");
      })
      .catch(() => navigate("/"));
  }, [dispatch, isSendingVerificationCode, navigate, token]);

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <Loading />
    </div>
  );
};

export default Confirm;
