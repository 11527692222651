export const login = (user) => ({
  type: "LOGIN",
  user,
});

export const logout = () => ({
  type: "LOGOUT",
});

export const setUser = (user) => ({
  type: "SET_USER",
  user,
});

export const updateUser = (user) => ({
  type: "UPDATE_USER",
  user,
});

export const sendingVerificationCode = (isSending) => ({
  type: "SENDING_VERIFICATION_CODE",
  isSending,
});
