import { useEffect } from "react";
import { useState } from "react";
import API from "../../../utils/API";
import getRoute from "../../../utils/helpers/getRoute";
import useCurrentRoute from "../../../utils/hooks/useCurrentRoute";

const Policy = () => {
  const [policy, setPolicy] = useState("");

  const route = useCurrentRoute();

  useEffect(() => {
    if (route) {
      const slug = getRoute(route?.name)?.slug;
      API.getContract(slug).then(({ data: res }) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setPolicy(res?.data?.content);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return (
    <div className="Policy">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
            <div className="policy-holder bg-white">
              <p dangerouslySetInnerHTML={{ __html: policy }} className="m-0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policy;
