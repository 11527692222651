import UAButton from "./UAButton";
import PropTypes from "prop-types";

const UAConfirmModal = ({
  title = null,
  desc = null,
  buttons = [],
  children,
}) => {
  return (
    <div className="UAConfirmModal position-fixed d-flex justify-content-center align-items-center vw-100 vh-100">
      <div className="modal-overlay position-fixed w-100 h-100" />
      <div className="modal-content position-relative">
        <div className="modal-header">
          <h5 className="modal-title m-0">{title}</h5>
        </div>
        <div className="modal-body">
          {desc && <p>{desc}</p>}
          {children}
        </div>
        <div className="modal-footer">
          {buttons.map(
            (
              {
                label,
                type = "primary",
                onClick,
                classNames,
                disabled = false,
              },
              index
            ) => (
              <UAButton
                key={index}
                label={label}
                type={type}
                onClick={onClick}
                classNames={classNames}
                disabled={disabled}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

UAConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        "primary",
        "secondary",
        "empty",
        "dark",
        "danger",
      ]),
      onClick: PropTypes.func,
      classNames: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default UAConfirmModal;
