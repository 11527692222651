import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import getRoute from "../../../../utils/helpers/getRoute";

const Trainer = ({ trainer }) => {
  return (
    <div className="Trainer w-100 position-relative d-flex flex-column align-items-center">
      <div className="trainer-image w-100">
        <img src={trainer.image} className="w-100" alt="" />
      </div>
      <h3 className="trainer-name fw-medium text-center">
        <Link
          to={
            getRoute("trainer").path.replace(":id", trainer.id) +
            `/${trainer.slug}`
          }
          className="stretched-link"
        >
          {trainer.name}
        </Link>
      </h3>
      <div className="trainer-title">
        <Translate id="trainer" />
      </div>
    </div>
  );
};

export default Trainer;
