import { Link } from "react-router-dom";
import getIcon from "../../../../utils/helpers/getIcon";
import getRoute from "../../../../utils/helpers/getRoute";
import HeaderGuestArea from "./HeaderGuestArea";
import HeaderNav from "./HeaderNav";
import HeaderUserArea from "./HeaderUserArea";

const Header = ({ translate, isAuthenticated, user }) => {
  return (
    <header className="w-100 d-flex justify-content-between align-items-center">
      <Link to={getRoute("home").path} className="logo">
        {getIcon("logo", { className: "h-100" })}
      </Link>
      <HeaderNav translate={translate} />
      {!isAuthenticated ? (
        <HeaderGuestArea translate={translate} />
      ) : (
        <HeaderUserArea user={user} />
      )}
    </header>
  );
};

export default Header;
