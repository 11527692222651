import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import SocialModal from "../../pages/auth/Community/Leadership/components/SocialModal";
import API from "../API";
import { TranslateContext } from "../context/TranslateContext";
import getRoute from "../helpers/getRoute";
import useUser from "../hooks/useUser";
import Loading from "./Loading";
import UAModal from "./UAModal";
import UAFollowUser from "./UAFollowUser";

const UAProfileShort = ({ setCommunityProfile, communityPageIsMyProfile }) => {
  const [profile, setProfile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [socialTab, setSocialTab] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { uaTranslate } = useContext(TranslateContext);

  const user = useUser();
  const modalRef = useRef(null);

  const { id } = useParams();

  useEffect(() => {
    setIsModalOpen(false);

    let userId = user?.id;

    if (id) userId = id;

    if (!userId) return;

    setIsLoading(true);

    API.getData(`profile?user_id=${userId}`).then(({ data: res }) => {
      const { data } = res;
      const profile = {
        id: data.id,
        avatar: data.avatar,
        nickname: data.nickname,
        fullname: data.fullname,
        total_score: data.total_score,
        follows_count: data.follows_count,
        followers_count: data.followers_count,
        is_followed: data.is_followed,
        link:
          getRoute(
            data?.id === user?.id
              ? "community-my-profile"
              : "community-profile",
            null,
            getRoute("community")
          )?.path?.replace(":id", data?.id) || "#!",
      };

      setProfile(profile);
      setCommunityProfile(profile);

      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, id]);

  const handleFollowOrUnfollow = () => {
    if (profile) {
      if (profile?.is_followed) {
        API.unfollowUser(profile?.id).then(() => {
          setProfile((prevState) => ({ ...prevState, is_followed: false }));
        });
      } else {
        API.followUser(profile?.id).then(() => {
          setProfile((prevState) => ({ ...prevState, is_followed: true }));
        });
      }
    }
  };

  return (
    <div className="UAProfileShort d-flex justify-content-start align-items-center">
      <Link
        to={profile?.link}
        className="avatar rounded-circle d-flex justify-content-center align-items-center flex-shrink-0"
      >
        {isLoading ? (
          <Loading />
        ) : (
          <img
            src={profile?.avatar}
            alt={profile?.fullname}
            className="rounded-circle w-100 h-100"
          />
        )}
      </Link>
      {!isLoading && (
        <div className="d-flex flex-column w-100 gap-3 gap-md-4 user-info-area">
          <div className="d-flex justify-content-between align-items-md-center">
            <Link
              to={profile?.link}
              className="user-name fw-semibold d-flex m-md-0"
            >
              {profile?.fullname}
            </Link>

            {communityPageIsMyProfile && (
              <div className="d-none d-md-flex">
                <UAFollowUser
                  isFollowed={profile ? profile?.is_followed : false}
                  onClick={handleFollowOrUnfollow}
                />
              </div>
            )}
          </div>
          <div className="info-holder d-flex justify-content-start align-items-center">
            <Link
              to={profile?.link}
              className="info-item d-flex flex-column align-items-center fw-semibold"
            >
              {profile?.total_score}
              <span className="fw-normal">
                {uaTranslate("app", "community_trainer_score")}
              </span>
            </Link>
            <div
              className="info-item d-flex flex-column align-items-center fw-semibold"
              role="button"
              onClick={() => {
                setSocialTab("following");
                setIsModalOpen(true);
              }}
            >
              {profile?.follows_count}
              <span className="fw-normal">
                {uaTranslate("app", "community_trainer_follow")}
              </span>
            </div>
            <div
              className="info-item d-flex flex-column align-items-center fw-semibold"
              role="button"
              onClick={() => {
                setSocialTab("follower");
                setIsModalOpen(true);
              }}
            >
              {profile?.followers_count}
              <span className="fw-normal">
                {uaTranslate("app", "community_trainer_follower")}
              </span>
            </div>
          </div>
          {communityPageIsMyProfile && (
            <div className="d-flex d-md-none">
              <UAFollowUser
                isFollowed={profile ? profile?.is_followed : false}
                onClick={handleFollowOrUnfollow}
              />
            </div>
          )}
        </div>
      )}
      <CSSTransition
        in={isModalOpen}
        timeout={300}
        unmountOnExit
        classNames="UAModal"
        nodeRef={modalRef}
      >
        <UAModal propRef={modalRef} handleClose={() => setIsModalOpen(false)}>
          <SocialModal
            onClose={() => setIsModalOpen(false)}
            clicked={socialTab}
            profileId={profile?.id}
            updateProfile={(newProfile) =>
              setProfile((prevState) => ({ ...prevState, ...newProfile }))
            }
          />
        </UAModal>
      </CSSTransition>
    </div>
  );
};

export default UAProfileShort;
