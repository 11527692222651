import React, { useEffect, useState } from "react";
import SingleSocialMedia from "./SingleSocialMedia";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";

const LoginWithSocialMedias = ({
  customLabel,
  customTranslateKey,
  includePlatforms,
}) => {
  const [platforms, setPlatforms] = useState([
    { id: 1, name: "google" },
    { id: 2, name: "apple" },
  ]);

  useEffect(() => {
    if (Boolean(includePlatforms) && includePlatforms.length > 0)
      setPlatforms(includePlatforms);
  }, [includePlatforms]);

  return (
    <div className="LoginWithSocialMedias d-flex flex-column align-items-center justify-content-center gap-4">
      <span className="alternative text-white">
        {customLabel}
        {<Translate id={customTranslateKey || "or"} />}
      </span>
      <div className="social-media-container d-flex align-items-center justify-content-center flex-wrap gap-3">
        {platforms?.map((platform, index) => (
          <SingleSocialMedia
            key={platform?.id}
            platform={platform?.name}
            order={index + 1}
            className={platform?.className}
          />
        ))}
      </div>
    </div>
  );
};

export default LoginWithSocialMedias;

LoginWithSocialMedias.propTypes = {
  customLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  customTranslateKey: PropTypes.string,
  includePlatforms: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf(["google", "apple"]).isRequired,
      className: PropTypes.string,
    }).isRequired
  ),
};
