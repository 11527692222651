import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Translate } from "react-localize-redux";
import { useNavigate } from "react-router";
import UAButton from "../../../utils/components/UAButton";
import getRoute from "../../../utils/helpers/getRoute";
import WorkoutCard from "./components/WorkoutCard";

const Program = ({ program }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const root = document.querySelector(":root");
    const scrollEl = document.querySelector(".ProgramDetail");

    if (root && scrollEl) {
      root.style.setProperty("--program-bg-color", `rgba(255, 255, 255, 0)`);

      scrollEl.addEventListener("scroll", handleScroll, false);
    }

    return () => {
      scrollEl.removeEventListener("scroll", handleScroll, false);
    };
  }, []);

  const handleScroll = () => {
    const root = document.querySelector(":root");
    const scrollEl = document.querySelector(".ProgramDetail");

    if (root && scrollEl) {
      const programBgColor = `rgba(255, 255, 255, ${scrollEl.scrollTop / 100})`;

      root.style.setProperty("--program-bg-color", programBgColor);
    }
  };

  return (
    <>
      <Helmet>
        <title>{program?.title}</title>
      </Helmet>
      <div
        className="ProgramDetail position-relative overflow-auto h-100 d-flex flex-column align-items-center"
        style={{ backgroundImage: `url("${program?.image}")` }}
      >
        <div className="program-info position-relative w-100">
          <h3 className="title fw-bold">{program?.title}</h3>
          <div className="sub-info">
            <div className="info--days fw-medium">
              {program?.days}{" "}
              <Translate id={program?.days > 1 ? `days` : `day`} />
            </div>
            <div className="info--trainer fw-medium">{program?.trainer}</div>
          </div>
          <p className="desc">{program?.description}</p>
        </div>
        <div className="program-workouts d-grid w-100">
          {program?.workouts
            ?.sort((a, b) => a.order - b.order)
            .map((workout) => (
              <WorkoutCard key={workout.id} workout={workout} />
            ))}
        </div>
        <Translate>
          {({ translate }) => (
            <UAButton
              label={translate("close")}
              classNames="text-alternative border-alternative close-button"
              type="dark"
              onClick={() => navigate(getRoute("programs").path)}
            />
          )}
        </Translate>
      </div>
    </>
  );
};

export default Program;
