import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import API from "../../../utils/API";
import UAButton from "../../../utils/components/UAButton";
import UAInput from "../../../utils/components/UAInput";
import getRoute from "../../../utils/helpers/getRoute";
import validateInput from "../../../utils/helpers/validateInput";

import Loading from "../../../utils/components/Loading";
import { login } from "../../../utils/redux/auth/authActions";
import LoginWithSocialMedias from "../../../utils/components/LoginWithSocialMedias";
import { useDispatch } from "react-redux";

const Login = ({ translate }) => {
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({});

  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo.email && userInfo.password) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }
  }, [userInfo, errors, success]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};
    let success = {};

    Object.keys(userInfo).forEach((key) => {
      if (!userInfo[key]) {
        errors[key] = translate("required");
      }

      let validation = validateInput(userInfo[key], {
        notEmpty: true,
      });

      if (validation && key === "email") {
        validation = validateInput(userInfo[key], {
          isEmail: true,
        });
      }

      if (validation) success[key] = true;
      else {
        if (key === "email") {
          errors[key] = translate("login.errors.email");
        }
      }
    });

    setSuccess(success);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSending(true);

      API.postData("auth/login", {
        email: userInfo.email,
        password: userInfo.password,
      })
        .then(({ data: res }) => {
          API.getAccountData(res.data.access_token).then(
            ({ data: accountRes }) => {
              dispatch(login({ ...res.data, ...accountRes.data }));
            }
          );
        })
        .catch(() => {
          setSuccess({});
          setIsSending(false);
        });
    }
  };

  return (
    <div className="Login d-flex flex-column align-items-center">
      <form
        onSubmit={handleSubmit}
        className="w-100 d-flex flex-column align-items-center"
      >
        {Object.keys(userInfo).map((key, index) => (
          <UAInput
            key={index}
            type={key === "password" ? "password" : "text"}
            value={userInfo[key]}
            onChange={handleChange}
            name={key}
            label={translate(key)}
            inputProps={{
              inputMode: key === "email" ? "email" : "text",
            }}
            error={key in errors ? errors[key] : false}
            success={key in success ? success[key] : false}
          />
        ))}
        <Link
          to={getRoute("forgot-password").path}
          className="color--secondary fw-bold"
        >
          {translate("login.forgot_password")}
        </Link>
        <UAButton
          label={isSending ? <Loading /> : translate("login.login")}
          htmlType="submit"
          disabled={!isButtonActive || isSending}
          classNames="text-uppercase"
        />
      </form>

      <LoginWithSocialMedias />

      <div className="already-user color--white mt-3">
        {translate("login.register_link", {
          link: (
            <Link
              to={getRoute("register").path}
              className="color--secondary"
              state={{ from: location.state?.from }}
            >
              {translate("login.register_link_text")}
            </Link>
          ),
        })}
      </div>
    </div>
  );
};

export default Login;
