import { useEffect } from "react";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Link, useNavigate } from "react-router-dom";
import API from "../../../utils/API";
import Loading from "../../../utils/components/Loading";
import UAButton from "../../../utils/components/UAButton";
import UAInput from "../../../utils/components/UAInput";
import getRoute from "../../../utils/helpers/getRoute";
import UAConfirmModal from "../../../utils/components/UAConfirmModal";

const ForgotPassword = ({ translate }) => {
  const [userInfo, setUserInfo] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({});

  const [isSending, setIsSending] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.email) setIsButtonActive(true);
    else setIsButtonActive(false);
  }, [userInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserInfo({ ...userInfo, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (isSending) return;

    if (!userInfo.email) {
      setErrors({
        email: translate("required"),
      });
      return;
    }

    setIsSending(true);

    let data = { sendcode: "" };

    if (userInfo.email) {
      data.sendcode = userInfo.email;
      setSuccess({ email: true });
    }

    API.postData("auth/password/otp", data)
      .then(() => {
        confirmAlert({
          customUI: ({ onClose }) => (
            <UAConfirmModal
              title={translate("forgot_password.success_title")}
              buttons={[
                {
                  label: translate("okay"),
                  onClick: () => {
                    onClose();
                    navigate(
                      getRoute("reset-password")?.path +
                        `?sendcode=${encodeURIComponent(userInfo.email)}`
                    );
                  },
                },
              ]}
            >
              {translate(`forgot_password.success_message_email`)}
            </UAConfirmModal>
          ),
        });
      })
      .catch(() => {
        setIsSending(false);
      });
  };

  return (
    <div className="ForgotPassword">
      <form onSubmit={onSubmit} className="w-100 d-flex flex-column">
        <UAInput
          type="text"
          value={userInfo.email}
          onChange={handleChange}
          name="email"
          label={translate("email")}
          inputProps={{
            inputMode: "email",
          }}
          error={"email" in errors ? errors["email"] : false}
          success={"email" in success ? success["email"] : false}
        />

        <UAButton
          label={
            isSending ? <Loading /> : translate("forgot_password.send_button")
          }
          htmlType="submit"
          disabled={!isButtonActive || isSending}
          classNames="text-uppercase"
        />
        <div className="already-user text-center color--white">
          {translate("forgot_password.login_link", {
            link: (
              <Link to={getRoute("login").path} className="color--secondary">
                {translate("forgot_password.login_link_text")}
              </Link>
            ),
          })}
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
