import { truncate } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const UATopPlacePerson = ({ person }) => {
  const [size, setSize] = useState("sm");
  const [name, setName] = useState(
    truncate(person.name, {
      length: document.body.offsetWidth < 768 ? 12 : 24,
    })
  );

  useEffect(() => {
    if (person.rank === 1) {
      setSize("lg");
    } else if (person.rank === 2) {
      setSize("md");
    } else if (person.rank === 3) {
      setSize("sm");
    }
  }, [person?.rank]);

  useEffect(() => {
    const handleResize = () => {
      setName(
        truncate(person.name, {
          length: document.body.offsetWidth < 768 ? 12 : 24,
        })
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="UATopPlacePerson d-flex flex-column align-items-center"
      data-size={size}
    >
      <Link
        to={person?.profileLink}
        className="avatar-holder rounded-circle d-flex justify-content-center align-items-center flex-shrink-0 position-relative"
      >
        <img
          src={person.avatar}
          alt={person.name}
          className="rounded-circle w-100 h-100"
        />
        <div className="rank position-absolute d-flex justify-content-center align-items-center">
          <span className="position-relative fw-bold">{person.rank}</span>
        </div>
        <div className="points position-absolute fw-bold d-flex justify-content-center align-items-center">
          {person.score}
        </div>
      </Link>

      <div className="user-info text-center position-relative">
        <Link
          to={person?.profileLink}
          className="name fw-semibold stretched-link"
        >
          {name}
        </Link>
        <div className="username fw-normal">@{person.username}</div>
      </div>
    </div>
  );
};

UATopPlacePerson.propTypes = {
  person: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    rank: PropTypes.number.isRequired,
  }).isRequired,
};

export default UATopPlacePerson;
