const PersonalInfoInterface = {
  firstname: {
    type: "text",
    label: "app.name",
  },
  lastname: {
    type: "text",
    label: "app.surname",
  },
  nickname: {
    type: "text",
    label: "app.profile_update_user_name",
  },
  gender: {
    type: "select",
    label: "app.profile_update_gender",
    options: [
      {
        value: "male",
        label: "app.male",
      },
      {
        value: "female",
        label: "app.female",
      },
      {
        value: "non-binary",
        label: "app.non-binary",
      },
    ],
  },
  email: {
    type: "email",
    label: "app.email",
  },
  date_of_birth: {
    type: "date",
    label: "app.profile_update_birthdate",
    min: "1970-01-01",
    max: new Date().toISOString().split("T")[0],
  },
  gsm: {
    type: "text",
    label: "app.profile_update_gsm",
  },
  current_level: {
    type: "select",
    label: "app.reg_level",
    options: Array.from({ length: 10 }, (_, i) => ({
      value: i + 1,
      label: i + 1,
    })),
  },
  weight: {
    type: "number",
    label: "app.profile_update_weight",
    min: 1,
    max: 200,
    extra: {
      unit: {
        type: "select",
        label: "",
        options: [
          {
            value: "kg",
            label: "KG",
          },
          {
            value: "lb",
            label: "LB",
          },
        ],
        defaultValue: {
          value: "kg",
          label: "KG",
        },
      },
    },
  },
  height: {
    type: "number",
    label: "app.profile_update_height",
    min: 1,
    max: 250,
    extra: {
      unit: {
        type: "select",
        label: "",
        options: [
          {
            value: "cm",
            label: "CM",
          },
          {
            value: "ft",
            label: "FT",
          },
        ],
        defaultValue: {
          value: "cm",
          label: "CM",
        },
      },
    },
  },
};

export default PersonalInfoInterface;
