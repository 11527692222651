import { FaQuestionCircle } from "react-icons/fa";
import icons from "../db/icons";

const getIcon = (
  icon,
  options = { alternative: null, className: null, alt: null }
) => {
  if (icon && icons[icon]) {
    let item = icons[icon].icon;

    if (options?.alternative !== null && icons[icon][options.alternative]) {
      item = icons[icon][options.alternative];
    }

    return (
      <img
        src={item}
        className={options.className || ""}
        alt={options.alt || icons[icon].alt}
      />
    );
  }

  return <FaQuestionCircle />;
};

export default getIcon;
