import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

const Subscribe = () => {
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    const pageEl = document.querySelector(".GlobalLayout .page-content");

    if (pageEl) {
      pageEl.classList.add("p-0");
    }

    return () => {
      if (pageEl) {
        pageEl.classList.remove("p-0");
      }
    };
  }, []);

  if (user.isPremium) {
    return <Navigate to="/" replace/>;
  }

  return (
    <>
      <Helmet>
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      </Helmet>
      <stripe-pricing-table
        class="d-flex justify-content-center align-items-center w-100 h-100 bg-black"
        pricing-table-id={process.env.REACT_APP_STRIPE_TABLE}
        publishable-key={process.env.REACT_APP_STRIPE_KEY}>
      </stripe-pricing-table>
    </>
  );
};

export default Subscribe;
