import { isEqual } from "lodash";
import { useState, useEffect, useContext } from "react";

import { Translate } from "react-localize-redux";
import API from "../../../../utils/API";
import UAButton from "../../../../utils/components/UAButton";
import UAInput from "../../../../utils/components/UAInput";
import UASelect from "../../../../utils/components/UASelect";
import PersonalInfoInterface from "../../../../utils/interfaces/PersonalInfoInterface";
import { TranslateContext } from "../../../../utils/context/TranslateContext";

const PersonalInfo = ({ user, genders, showSuccess }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [personalInfoModel, setPersonalInfoModel] = useState(
    PersonalInfoInterface
  );
  const [personalInfo, setPersonalInfo] = useState({});
  const [defaultPersonalInfo, setDefaultPersonalInfo] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    const newPersonalInfo = Object.keys(PersonalInfoInterface).reduce(
      (acc, key) => {
        if (PersonalInfoInterface[key].extra) {
          Object.keys(PersonalInfoInterface[key].extra).forEach((extraKey) => {
            if (PersonalInfoInterface[key].extra[extraKey].type === "select") {
              acc[`${key}_${extraKey}`] = PersonalInfoInterface[key].extra[
                extraKey
              ].options.find(
                (option) => option.value === user[`${key}_${extraKey}`]
              );
            } else
              acc[`${key}_${extraKey}`] =
                user[`${key}_${extraKey}`] ||
                PersonalInfoInterface[key].extra[extraKey]?.defaultValue ||
                "";
          });
        }

        if (PersonalInfoInterface[key].type === "select")
          acc[key] = PersonalInfoInterface[key].options.find(
            (option) => option.value === user[key]
          );
        else if (PersonalInfoInterface[key].type === "tel") {
          acc[key] = {
            number: user[key] || PersonalInfoInterface[key]?.defaultValue || "",
            countryCode: "+90",
          };
        } else
          acc[key] =
            user[key] || PersonalInfoInterface[key]?.defaultValue || "";
        return acc;
      },
      {}
    );

    setPersonalInfo(newPersonalInfo);
    setDefaultPersonalInfo(newPersonalInfo);
  }, [user]);

  useEffect(() => {
    if (genders) {
      const newPersonalInfoModel = { ...personalInfoModel };

      newPersonalInfoModel.gender.options = genders.map((g) => ({
        value: g.key,
        label: g.value,
      }));

      setPersonalInfoModel(newPersonalInfoModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genders]);

  useEffect(() => {
    const hasUpdated = Object.keys(personalInfo).some(
      (key) => !isEqual(personalInfo[key], defaultPersonalInfo[key])
    );

    setIsButtonDisabled(!hasUpdated);
  }, [personalInfo, defaultPersonalInfo]);

  const handleSave = () => {
    const hasUpdated = Object.keys(personalInfo).some(
      (key) => !isEqual(personalInfo[key], defaultPersonalInfo[key])
    );

    if (!hasUpdated) return;

    let payload = Object.keys(PersonalInfoInterface).reduce((acc, key) => {
      if (PersonalInfoInterface[key].extra) {
        Object.keys(PersonalInfoInterface[key].extra).forEach((extraKey) => {
          if (PersonalInfoInterface[key].extra[extraKey].type === "select") {
            acc[`${key}_${extraKey}`] =
              personalInfo[`${key}_${extraKey}`]?.value;
          } else {
            let value = personalInfo[`${key}_${extraKey}`];

            if (PersonalInfoInterface[key].extra[extraKey].type === "number")
              value = value ? parseFloat(value) : 0;

            acc[`${key}_${extraKey}`] = value;
          }
        });
      }

      if (PersonalInfoInterface[key].type === "select") {
        acc[key] = personalInfo[key]?.value;
      } else if (PersonalInfoInterface[key].type === "tel") {
        acc[key] =
          personalInfo[key]?.countryCode.replace("+", "") +
          personalInfo[key]?.number;
      } else {
        let value = personalInfo[key] || "";

        if (PersonalInfoInterface[key].type === "number")
          value = value ? parseFloat(value) : 0;

        acc[key] = value;
      }
      return acc;
    }, {});

    if (!payload) return;

    payload = Object.keys(payload).reduce((acc, key) => {
      if (
        payload[key] !== null &&
        payload[key] !== undefined &&
        payload[key] !== "" &&
        payload[key] !== defaultPersonalInfo[key] &&
        payload[key] !== 0
      )
        acc[key] = payload[key];
      return acc;
    }, {});

    setIsUpdating(true);

    API.updateUser(payload)
      .then(() => {
        showSuccess();
        setDefaultPersonalInfo(personalInfo);
      })
      .finally(() => setIsUpdating(false));
  };

  return (
    <div className="PersonalInfo">
      <div className="row">
        {Object.keys(personalInfoModel).map((key) => {
          let { type, label, min, max, options, extra } =
            personalInfoModel[key];

          if (typeof label === "string" && label.includes(".")) {
            const [labelKey, labelValue] = label?.split(".") || [
              undefined,
              undefined,
            ];

            label = uaTranslate(labelKey, labelValue);
          }

          options = options?.map((option) => {
            if (
              typeof option?.label !== "string" ||
              !option?.label?.includes(".")
            )
              return option;

            const [optionKey, optionValue] = option.label?.split(".") || [
              undefined,
              undefined,
            ];

            return {
              ...option,
              label: uaTranslate(optionKey, optionValue),
            };
          });

          if (extra?.unit && extra?.unit?.options) {
            extra.unit.options = extra.unit.options.map((option) => {
              if (!option?.label?.includes(".")) return option;

              const [optionKey, optionValue] = option.label?.split(".") || [
                undefined,
                undefined,
              ];

              return {
                ...option,
                label: uaTranslate(optionKey, optionValue),
              };
            });
          }

          return (
            <div key={key} className="col-12 col-md-6">
              <div className="row pt-5 h-100">
                <div className={`h-100 ${extra ? `col-8` : `col-12`}`}>
                  {type === "select" ? (
                    <UASelect
                      label={label}
                      name={key}
                      value={personalInfo[key]}
                      options={options}
                      onChange={(value) => {
                        setPersonalInfo({
                          ...personalInfo,
                          [key]: value,
                        });
                      }}
                    />
                  ) : (
                    <UAInput
                      type={type}
                      label={label}
                      name={key}
                      value={
                        type === "tel"
                          ? personalInfo[key]?.number
                          : personalInfo[key]
                      }
                      options={options}
                      onBlur={() => {
                        if (
                          key === "weight" ||
                          key === "height" ||
                          key === "date_of_birth"
                        ) {
                          const newPersonalInfo = { ...personalInfo };
                          if (personalInfo[key] > max)
                            newPersonalInfo[key] = max;
                          else if (personalInfo[key] < min) {
                            newPersonalInfo[key] = min;
                          }

                          setPersonalInfo(newPersonalInfo);
                        }
                      }}
                      onChange={(e) => {
                        const newPersonalInfo = { ...personalInfo };

                        if (type === "tel") {
                          newPersonalInfo[key].number = e.target.value;
                        } else if (type === "number") {
                          if (e.target.value.length > 3) {
                            newPersonalInfo[key] = e.target.value.slice(0, 3);
                          } else {
                            newPersonalInfo[key] = e.target.value;
                          }
                        } else {
                          newPersonalInfo[key] = e.target.value;
                        }

                        setPersonalInfo(newPersonalInfo);
                      }}
                      theme="dark"
                      countryCode={personalInfo[key]?.countryCode || ""}
                      onCountryCodeChange={(countryCode) => {
                        setPersonalInfo({
                          ...personalInfo,
                          [key]: {
                            ...personalInfo[key],
                            countryCode,
                          },
                        });
                      }}
                      min={min || undefined}
                      max={max || undefined}
                      readOnly={key === "gsm"}
                    />
                  )}
                </div>
                {extra && (
                  <div className="col-4 h-100">
                    {Object.keys(extra).map((extraKey) => {
                      const { type, label, options } = extra[extraKey];

                      return type === "select" ? (
                        <UASelect
                          key={extraKey}
                          label={label}
                          name={`${key}_${extraKey}`}
                          value={personalInfo[`${key}_${extraKey}`]}
                          options={options}
                          onChange={(value) => {
                            setPersonalInfo({
                              ...personalInfo,
                              [`${key}_${extraKey}`]: value,
                            });
                          }}
                        />
                      ) : (
                        <UAInput
                          key={extraKey}
                          type={type}
                          label={label}
                          name={`${key}_${extraKey}`}
                          value={personalInfo[`${key}_${extraKey}`]}
                          options={options}
                          onChange={(e) => {
                            setPersonalInfo({
                              ...personalInfo,
                              [`${key}_${extraKey}`]: e.target.value,
                            });
                          }}
                          theme="dark"
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="row mt-5">
        <div className="col-12 d-flex justify-content-center">
          <UAButton
            label={<Translate id="save" />}
            onClick={() => handleSave("personalInfo")}
            classNames="save-button text-uppercase"
            disabled={isUpdating || isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
