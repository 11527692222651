import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import getIcon from "../../../../utils/helpers/getIcon";
import getRoute from "../../../../utils/helpers/getRoute";

const VerticalWorkout = ({ workout }) => {
  return (
    <div className="workout d-flex flex-column align-items-start justify-content-between position-relative overflow-hidden">
      <div className="workout-bg position-absolute w-100 h-100" />
      <div className="bottom-bg position-absolute w-100"></div>
      <div className="bookmark-icon position-absolute d-none" role="button">
        {getIcon("bookmark")}
      </div>
      <div className="top d-flex flex-column align-items-start">
        <h3 className="title fw-bold d-flex justify-content-start align-items-center">
          <Link
            to={
              getRoute("workout")?.path?.replace(":id", workout?.id) +
              `/${workout?.slug}`
            }
            className="stretched-link"
          >
            {" "}
            {workout?.title}
          </Link>
        </h3>
        <div className="duration fw-semibold">
          <Translate id="workouts.min" data={{ min: workout?.duration }} />
        </div>
        <div className="trainer fw-semibold">{workout?.trainer?.name}</div>
      </div>
      <Link
        to={
          getRoute("workout")?.path?.replace(":id", workout?.id) +
          `/${workout?.slug}`
        }
        className="trainer-image position-relative w-100 d-flex justify-content-center align-items-end"
      >
        <img src={workout?.trainer?.image} alt={workout?.trainer?.name} />
      </Link>

      <div className="play-icon position-absolute d-flex justify-content-center align-items-center stretched-link">
        {getIcon("play")}
      </div>
    </div>
  );
};

export default VerticalWorkout;
