import { useEffect, useState, useContext } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import API from "../../../utils/API";
import ShowSuccess from "../../../utils/components/ShowSuccess";
import updateUser from "../../../utils/helpers/setUser";
import Avatar from "./components/Avatar";
import CheckboxList from "./components/CheckboxList";
import PersonalInfo from "./components/PersonalInfo";
import ProfileSection from "./components/ProfileSection";
import { updateUser as updateUserAction } from "../../../utils/redux/auth/authActions";

import { TranslateContext } from "../../../utils/context/TranslateContext";
import SubPageLayout from "../../../App/Layouts/SubPageLayout/SubPageLayout";
import UACheckbox from "../../../utils/components/UACheckbox";
import { connect } from "react-redux";
import { setLanguage } from "../../../utils/redux/app/appActions";

const Profile = ({
  auth,
  languages,
  activeLanguage,
  setActiveLanguage,
  setLanguage,
  updateUser: updateUserRedux,
}) => {
  const [user, setUser] = useState({});
  const [refreshUser, setRefreshUser] = useState(true);
  const [refreshCallback, setRefreshCallback] = useState(null);
  const [genders, setGenders] = useState([]);
  const [goals, setGoals] = useState([]);
  const [bodyParts, setBodyParts] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const { getKeys, keys } = useContext(TranslateContext);

  useEffect(() => {
    setGenders(getKeys("gender"));
    setGoals(getKeys("goals"));
    setBodyParts(getKeys("body_part"));
    setEquipments(getKeys("equipment"));
    setSuccessMessage(
      getKeys("app").find((key) => key.key === "user_update_success_message")
        ?.value
    );
  }, [getKeys, keys]);

  useEffect(() => {
    if (!refreshUser) return;

    API.getAccountData()
      .then(({ data: res }) => {
        setUser(res.data);
        updateUser(res.data);

        setTimeout(() => {
          if (refreshCallback) refreshCallback();
        }, 20);
      })
      .finally(() => {
        setRefreshCallback(null);
        setRefreshUser(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUser]);

  const handleRefreshUser = (callback) => {
    setRefreshCallback(() => callback);
    setRefreshUser(true);
  };

  const handleSetLanguage = (code) => {
    if (auth?.isAuthenticated)
      API.setLanguage(code).then(({ data: res }) => {
        updateUserRedux({ lang: code });
      });

    setLanguage(code);
    setActiveLanguage(code);
  };

  return (
    <SubPageLayout page="profile">
      <div className="Profile extra-padding position-relative">
        <ShowSuccess
          message={successMessage}
          show={showSuccess}
          setShow={setShowSuccess}
        />
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Avatar
              user={user}
              refresh={handleRefreshUser}
              showSuccess={() => setShowSuccess(true)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ProfileSection title={<Translate id="profile.personal_info" />}>
              <PersonalInfo
                user={user}
                refresh={handleRefreshUser}
                genders={genders}
                showSuccess={() => setShowSuccess(true)}
              />
            </ProfileSection>
            <ProfileSection title={<Translate id="profile.goals" />}>
              <CheckboxList
                user={user}
                checkboxList={goals}
                showSuccess={() => setShowSuccess(true)}
                type="goals"
              />
            </ProfileSection>
            <ProfileSection title={<Translate id="profile.workout_zone" />}>
              <CheckboxList
                user={user}
                checkboxList={bodyParts}
                showSuccess={() => setShowSuccess(true)}
                type="body_parts"
              />
            </ProfileSection>
            <ProfileSection title={<Translate id="profile.equipments" />}>
              <CheckboxList
                user={user}
                checkboxList={equipments}
                showSuccess={() => setShowSuccess(true)}
                type="equipments"
              />
            </ProfileSection>

            <ProfileSection title={<Translate id="profile.app_language" />}>
              <div className="d-flex flex-column gap-3">
                {languages.map((lang) => (
                  <UACheckbox
                    key={lang.code}
                    id={lang.code}
                    name="language"
                    label={lang.name}
                    checked={lang.active || activeLanguage.code === lang.code}
                    defaultChecked={
                      lang.active || activeLanguage.code === lang.code
                    }
                    onChange={() => handleSetLanguage(lang.code)}
                    setAsRadio
                  />
                ))}
              </div>
            </ProfileSection>
          </div>
        </div>
      </div>
    </SubPageLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (code) => dispatch(setLanguage(code)),
  updateUser: (user) => dispatch(updateUserAction(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Profile));
