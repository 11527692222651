const UAUserWorkouts = ({ workouts }) => {
  return (
    <div className="UAUserWorkouts">
      {workouts.map((workout) => (
        <div
          key={workout.id}
          className="workout d-flex flex-column flex-shrink-0 align-items-center"
        >
          <div className="workout-image">
            <img src={workout.icon} alt={workout.category_name} />
          </div>
          <h5 className="workout-title text-center">
            {workout?.count} {workout.category_name}
          </h5>
        </div>
      ))}
    </div>
  );
};

export default UAUserWorkouts;
