import UAButton from "../../../../utils/components/UAButton";
import getRoute from "../../../../utils/helpers/getRoute";

const HeaderGuestArea = ({ translate }) => {
  return (
    <section className="guest-area d-flex align-items-center justify-content-end">
      <UAButton
        label={translate("header.login")}
        type="empty"
        isLink
        action={getRoute("login").path}
      />
      <UAButton
        label={translate("header.register")}
        type="primary"
        isLink
        action={getRoute("register").path}
      />
    </section>
  );
};

export default HeaderGuestArea;
