import { useEffect, useState } from "react";
import API from "../../../../../utils/API";

const Trainer = ({ selectedFilters, handleFilterChange }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    API.getData("trainers").then(({ data: res }) => {
      const trainers = res.data.map((item) => ({
        id: item.id,
        name: item.fullname,
        photo: item.image_list,
      }));

      setData(trainers);
    });
  }, []);

  return (
    <div className="trainers d-grid">
      {data.map((trainer) => (
        <div
          key={trainer.id}
          className="trainer d-flex flex-column justify-content-center align-items-center"
          data-is-selected={selectedFilters.id === trainer.id}
          onClick={() => handleFilterChange(trainer)}
          role="button"
        >
          <div className="photo d-flex justify-content-center align-items-center rounded-circle overflow-hidden">
            <img
              src={trainer.photo}
              alt={trainer.name}
              className="w-100 rounded-circle"
            />
          </div>
          <span className="name text-center fw-semibold">{trainer.name}</span>
        </div>
      ))}
    </div>
  );
};

export default Trainer;
