const PastSubscriptions = ({ subscriptions, translate }) => {
  return (
    <div className="past-subscriptions-holder">
      <div className="past-subscriptions-titles d-grid">
        <h6>
          {translate("subscription.past_subscriptions_list.subscription_info")}
        </h6>
        <h6>
          {translate("subscription.past_subscriptions_list.payment_info")}
        </h6>
        <h6>
          {translate("subscription.past_subscriptions_list.starting_date")}
        </h6>
        <h6>{translate("subscription.past_subscriptions_list.ending_date")}</h6>
      </div>
      <div className="past-subscriptions-list">
        {subscriptions.map((subscription) => (
          <div className="subscription d-grid" key={subscription?.id}>
            <div className="subscription-info">{subscription?.title}</div>
            <div className="subscription-info">
              {subscription.price} {subscription.currency} /{" "}
              {subscription.period}
            </div>
            <div className="subscription-info">{subscription.startsAt}</div>
            <div className="subscription-info">{subscription.endsAt}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PastSubscriptions;
