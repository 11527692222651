import { NavLink } from "react-router-dom";
import getNavRoutes from "../../../../utils/helpers/getNavRoutes";
import { useSelector } from "react-redux";

const HeaderNav = ({ translate }) => {
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  return (
    <nav className="nav d-none d-lg-flex">
      {getNavRoutes()
        ?.filter((route) => {
          if (!isAuthenticated) {
            return true;
          } else if (
            !route.nonPremiumRoute ||
            (route.nonPremiumRoute && !user.isPremium)
          ) {
            return true;
          }

          return false;
        })
        .map((route) => (
          <NavLink
            key={route.name}
            to={route.path}
            className="nav-link text-uppercase"
            end={route.path === "/"}
          >
            {translate(`nav.${route.name}`)}
          </NavLink>
        ))}
    </nav>
  );
};

export default HeaderNav;
