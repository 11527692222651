import React from "react";
import { contentLanguages } from "../../../../../utils/db/contentLanguages";

const ContentLanguage = ({ selectedFilters, handleFilterChange }) => {
  return (
    <div className="content-languages d-grid">
      {contentLanguages.map((lang) => (
        <div
          key={lang.code}
          className="content-language d-flex flex-column justify-content-center align-items-center"
          data-is-selected={selectedFilters.id === lang.code}
          onClick={() =>
            handleFilterChange({
              id: lang.code,
              name: lang.label,
            })
          }
          role="button"
        >
          <div className="name fw-semibold">{lang.label}</div>
        </div>
      ))}
    </div>
  );
};

export default ContentLanguage;
