import { createRef, useEffect } from "react";
import { useState } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { CSSTransition } from "react-transition-group";
import API from "../../../utils/API";
import UAModal from "../../../utils/components/UAModal";
import getRoute from "../../../utils/helpers/getRoute";
import Program from "./components/Program";

import { default as ProgramDetail } from "./Program";
import ContentLanguageSelect from "../../../utils/components/ContentLanguageSelect";

const Programs = ({ activeLanguage }) => {
  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = createRef(null);

  const navigate = useNavigate();
  const { id, slug } = useParams();

  useEffect(() => {
    if (!id) navigate(getRoute("programs").path);
    if ((!slug || slug !== program?.slug) && program?.slug && id === program.id)
      navigate(
        getRoute("program").path.replace(":id", id) + `/${program.slug}`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, program]);

  const handleSetPrograms = (data) => {
    setPrograms(
      data.map((program) => ({
        id: program.id,
        slug: program.slug,
        title: program.title,
        image: program.image_list,
        order: program.order,
        day_count: program.day_count,
        difficulty: program.difficulty,
      }))
    );
  };

  useEffect(() => {
    API.getData(`programs?languages_filter=${activeLanguage.code}`).then(
      ({ data: res }) => {
        handleSetPrograms(res.data);
      }
    );
  }, [activeLanguage.code]);

  useEffect(() => {
    if (id) {
      API.getData(`/programs/${id}`).then(({ data: res }) => {
        const { data } = res;

        setProgram({
          id,
          title: data.title,
          description: data.description,
          image: data.image_detail,
          slug: data.slug,
          days: data.day_count,
          workouts: data.workouts.map((workout) => ({
            id: workout.id,
            title: workout.title,
            day: workout.day,
            order: workout.order,
            image: workout.image_detail,
            trainer_name: workout.trainer_name,
            slug: workout.slug,
          })),
        });
      });

      setIsModalOpen(true);
    }

    return () => {
      setIsModalOpen(false);
    };
  }, [id]);

  return (
    <div className="Programs">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-8">
            <div className="page-title fw-bold">
              <Translate id="page_title.programs" />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-3">
            <ContentLanguageSelect
              path="programs"
              handleSet={handleSetPrograms}
              activeLanguage={activeLanguage}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="programs-list d-grid">
              {programs.map((program) => (
                <Program key={program.id} program={program} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <CSSTransition
        in={isModalOpen}
        timeout={300}
        unmountOnExit
        classNames="UAModal"
        nodeRef={modalRef}
        onExited={() => setProgram(null)}
      >
        <UAModal
          handleClose={() => navigate(getRoute("programs").path)}
          propRef={modalRef}
        >
          <ProgramDetail program={program} />
        </UAModal>
      </CSSTransition>
    </div>
  );
};

export default withLocalize(Programs);
