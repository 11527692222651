import { uniqueId } from "lodash";
import { useState } from "react";
import ReactSelect from "react-select";

const UASelect = ({ options, value, onChange, id, label }) => {
  const [inputId] = useState(id || uniqueId("UASelect-"));
  return (
    <div className="UASelect__container w-100 h-100 position-relative">
      <label htmlFor={inputId} className="position-absolute fw-semibold">
        {label}
      </label>
      <ReactSelect
        className="UASelect"
        classNamePrefix="UASelect"
        options={options}
        value={value}
        onChange={onChange}
        inputId={inputId}
        placeholder="Seçiniz"
      />
    </div>
  );
};

export default UASelect;
