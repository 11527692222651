const Sort = ({ selectedFilters, handleFilterChange, translate }) => {
  const data = [
    {
      id: "like_count",
      name: translate("workouts.sort_by.like"),
    },
    {
      id: "comment_count",
      name: translate("workouts.sort_by.comment"),
    },
    {
      id: "created_date",
      name: translate("workouts.sort_by.date"),
    },
  ];
  return (
    <div className="sorts d-grid">
      {data.map((sort) => (
        <div
          key={sort.id}
          className="sort d-flex flex-column justify-content-center align-items-center"
          data-is-selected={selectedFilters.id === sort.id}
          onClick={() => handleFilterChange(sort)}
          role="button"
        >
          <div className="name fw-semibold">{sort.name}</div>
        </div>
      ))}
    </div>
  );
};

export default Sort;
