import { useEffect } from "react";
import PropTypes from "prop-types";

const UAModal = ({ children, handleClose, propRef, classNames }) => {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      e.stopPropagation();
      e.preventDefault();
      handleClose();
    }
  };

  return (
    <div
      className={`UAModal position-fixed vw-100 vh-100 d-flex justify-content-center align-items-center ${classNames}`}
      ref={propRef}
      data-testid="UA-modal"
    >
      <div
        className="overlay position-fixed vw-100 vh-100"
        role="button"
        onClick={handleClose}
      />
      <div className="content position-relative w-100 d-flex justify-content-end">
        <div
          className="close-btn d-flex position-fixed"
          onClick={handleClose}
          role="button"
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 24C14 18.4772 18.4772 14 24 14V14C29.5228 14 34 18.4772 34 24V24C34 29.5228 29.5228 34 24 34V34C18.4772 34 14 29.5228 14 24V24Z"
              stroke="#231F20"
              strokeWidth="1.5"
            />
            <path
              d="M26.8337 21.167L21.167 26.8337M21.167 21.167L26.8337 26.8337L21.167 21.167Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="inner w-100 overflow-auto">{children}</div>
      </div>
    </div>
  );
};

UAModal.defaultProps = {
  classNames: "",
};

UAModal.propTypes = {
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  propRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  classNames: PropTypes.string,
};

export default UAModal;
