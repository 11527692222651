import logo from "../../assets/static/Logo.svg";
import logoLight from "../../assets/static/LogoLight.svg";
import logoIcon from "../../assets/static/LogoIcon.svg";

import successCircle from "../../assets/static/icons/success-circle.svg";
import errorCircle from "../../assets/static/icons/error-circle.svg";
import showPass from "../../assets/static/icons/show-pass.svg";
import hidePass from "../../assets/static/icons/hide-pass.svg";
import filter from "../../assets/static/icons/filter.svg";
import bookmark from "../../assets/static/icons/bookmark.svg";
import notificationBell from "../../assets/static/icons/notificationBell.svg";
import notificationBellGreen from "../../assets/static/icons/notificationBellGreen.svg";
import notificationBellActive from "../../assets/static/icons/notificationBellActive.svg";
import play from "../../assets/static/icons/play.svg";
import playCircle from "../../assets/static/icons/play-circle.svg";

import workoutsAll from "../../assets/static/icons/workouts/all.svg";
import workoutsCycling from "../../assets/static/icons/workouts/cycling.svg";

import difficultyBeginner from "../../assets/static/icons/difficultyBeginner.svg";
import difficultyIntermediate from "../../assets/static/icons/difficultyIntermediate.svg";
import difficultyAdvanced from "../../assets/static/icons/difficultyAdvanced.svg";

import rewind10 from "../../assets/static/icons/rewind10.svg";
import fastForward10 from "../../assets/static/icons/fastForward10.svg";
import pause from "../../assets/static/icons/pause.svg";
import playerPlay from "../../assets/static/icons/playerPlay.svg";

import tick from "../../assets/static/icons/tick.svg";

import uLogo from "../../assets/static/icons/uLogo.svg";

import chevronRight from "../../assets/static/icons/chevron-right-shadow.svg";

import camera from "../../assets/static/icons/camera.svg";

import mastercard from "../../assets/static/icons/mastercard_logo.svg";

import tr from "../../assets/static/icons/tr.svg";
import en from "../../assets/static/icons/en.svg";

import logoOnlyGreen from "../../assets/static/icons/ua-logo-only-green.svg";
import logoOnlyBlack from "../../assets/static/icons/ua-logo-only-black.svg";

import mobileNavWorkouts from "../../assets/static/icons/workouts-mobile-nav.svg";
import mobileNavPrograms from "../../assets/static/icons/programs-mobile-nav.svg";
import mobileNavTrainers from "../../assets/static/icons/trainers-mobile-nav.svg";
import mobileNavCommunity from "../../assets/static/icons/community-mobile-nav.svg";

import fullscreen from "../../assets/static/icons/fullscreen.svg";

import speaker from "../../assets/static/icons/speaker.svg";
import speakerMuted from "../../assets/static/icons/speaker-muted.svg";
import directMessage from "../../assets/static/icons/direct-message.svg";

import googleLogin from "../../assets/static/icons/google-login.svg";
import appleLogin from "../../assets/static/icons/apple-login.svg";

const icons = {
  logo: {
    icon: logo,
    light: logoLight,
    single: logoIcon,
    singleGreen: logoOnlyGreen,
    singleBlack: logoOnlyBlack,
    alt: "Urban Active Logo",
  },
  successCircle: { icon: successCircle, alt: "Success Icon" },
  errorCircle: { icon: errorCircle, alt: "Error Icon" },
  showPass: { icon: showPass, alt: "Show Password Icon" },
  hidePass: { icon: hidePass, alt: "Hide Password Icon" },
  workoutsAll: { icon: workoutsAll, alt: "All Workouts Icon" },
  workoutsCycling: { icon: workoutsCycling, alt: "Cycling Workouts Icon" },
  filter: { icon: filter, alt: "Filter Icon" },
  bookmark: { icon: bookmark, alt: "Bookmark Icon" },
  notificationBell: {
    icon: notificationBell,
    green: notificationBellGreen,
    alt: "Notification Bell Icon",
  },
  notificationBellActive: {
    icon: notificationBellActive,
    alt: "Notification Bell Active Icon",
  },
  play: {
    icon: play,
    circle: playCircle,
    player: playerPlay,
    alt: "Play Icon",
  },
  difficulty_beginner: {
    icon: difficultyBeginner,
    alt: "Beginner Difficulty Icon",
  },
  difficulty_intermediate: {
    icon: difficultyIntermediate,
    alt: "Intermediate Difficulty Icon",
  },
  difficulty_advanced: {
    icon: difficultyAdvanced,
    alt: "Advanced Difficulty Icon",
  },
  rewind10: {
    icon: rewind10,
    alt: "Rewind 10 Seconds Icon",
  },
  fastForward10: {
    icon: fastForward10,
    alt: "Fast Forward 10 Seconds Icon",
  },
  pause: {
    icon: pause,
    alt: "Pause Icon",
  },
  done: {
    icon: tick,
    alt: "Done Icon",
  },
  uLogo: {
    icon: uLogo,
    alt: "Urban Active U Logo",
  },
  chevronRight: {
    icon: chevronRight,
    alt: "Chevron Right Icon",
  },
  camera: {
    icon: camera,
    alt: "Camera Icon",
  },
  mastercard: {
    icon: mastercard,
    alt: "Mastercard Logo",
  },
  tr: {
    icon: tr,
    alt: "Turkish Flag",
  },
  en: {
    icon: en,
    alt: "English Flag",
  },
  mobileNavWorkouts: {
    icon: mobileNavWorkouts,
    alt: "Workouts Icon",
  },
  mobileNavPrograms: {
    icon: mobileNavPrograms,
    alt: "Programs Icon",
  },
  mobileNavTrainers: {
    icon: mobileNavTrainers,
    alt: "Trainers Icon",
  },
  mobileNavCommunity: {
    icon: mobileNavCommunity,
    alt: "Community Icon",
  },
  fullscreen: {
    icon: fullscreen,
    alt: "Fullscreen Icon",
  },
  speaker: {
    icon: speaker,
    muted: speakerMuted,
    alt: "Speaker Icon",
  },

  directMessage: {
    icon: directMessage,
    alt: "Direct Message Icon",
  },
  googleLogin: { icon: googleLogin, alt: "Google Login Icon" },
  appleLogin: { icon: appleLogin, alt: "Apple Login Icon" },
};

export default icons;
