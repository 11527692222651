import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { localizeReducer } from "react-localize-redux";
import appReducer from "./utils/redux/app/appReducer";
import authReducer from "./utils/redux/auth/authReducer";

const rootReducer = combineReducers({
  routing: routerReducer,
  localize: localizeReducer,
  app: appReducer,
  auth: authReducer,
});

export default rootReducer;
