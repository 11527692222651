import { useEffect } from "react";

const UASidebar = ({ children, isOpen, handleClose }) => {
  useEffect(() => {
    if (isOpen) document.body.classList.add("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);
  return (
    <div
      className="UASidebar position-fixed vw-100 vh-100 d-flex justify-content-end align-items-start top-0"
      data-is-open={isOpen}
    >
      <div
        className="overlay vw-100 vh-100 position-fixed"
        onClick={handleClose}
        role="button"
      />
      <div className="content-holder position-relative vh-100 vw-100 overflow-auto">
        <div className="close-btn" onClick={handleClose} role="button">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 24C14 18.4772 18.4772 14 24 14V14C29.5228 14 34 18.4772 34 24V24C34 29.5228 29.5228 34 24 34V34C18.4772 34 14 29.5228 14 24V24Z"
              stroke="#231F20"
              strokeWidth="1.5"
            />
            <path
              d="M26.8337 21.167L21.167 26.8337M21.167 21.167L26.8337 26.8337L21.167 21.167Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default UASidebar;
