import React, { useEffect } from "react";

const LoginWithGoogle = ({ client_id, onResolve, className = "" }) => {
  useEffect(() => {
    const googleScript = document.getElementById("google-client-script");

    if (window.google) {
      if (window.google?.accounts?.id) {
        window.google.accounts.id.initialize({
          client_id,
          callback: (res) => onResolve({ provider: "google", profile: res }),
        });

        window.google.accounts.id.renderButton(
          document.getElementById("googleLoginButton"),
          {
            type: "icon",
            shape: "circle",
            size: "large",
          }
        );
      }
    }

    googleScript?.addEventListener("load", () => {
      if (window.google) {
        if (window.google?.accounts?.id) {
          window.google.accounts.id.initialize({
            client_id,
            callback: (res) => onResolve({ provider: "google", profile: res }),
          });

          window.google.accounts.id.renderButton(
            document.getElementById("googleLoginButton"),
            {
              type: "icon",
              shape: "circle",
              size: "large",
            }
          );
        }
      }
    });
  }, [client_id, onResolve]);

  return (
    <div className={`LoginWithGoogle ${className}`} id="googleLoginButton" />
  );
};

export default LoginWithGoogle;
