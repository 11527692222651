import React from "react";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import getRoute from "../utils/helpers/getRoute";
import GuestLayout from "./Layouts/GuestLayout/GuestLayout";
import GlobalLayout from "./Layouts/GlobalLayout/GlobalLayout";
import NotFound from "./NotFound";
import {
  globalRoutes,
  authRoutes,
  guestRoutes,
  callbackRoutes,
} from "./routes";

const ProtectedRoute = ({ type, auth, redirectTo, children }) => {
  const location = useLocation();
  return (type === "auth" && auth && auth.isAuthenticated) ||
    (type === "guest" && (!auth || !auth.isAuthenticated)) ? (
    children
  ) : (
    <Navigate
      to={location?.state?.from || redirectTo}
      replace
      state={{
        from: window.location.pathname,
      }}
    />
  );
};

const Router = ({ auth, app }) => {
  const [routes, setRoutes] = React.useState([]);
  const [isReady, setIsReady] = React.useState(false);

  const getPages = (pages, lang) =>
    pages &&
    pages.map((page) => {
      return page.isIndex ? (
        <Route
          key={page.id}
          element={React.createElement(page.component)}
          index
          exact={page.exact}
        />
      ) : (
        <Route
          key={page.id}
          path={page.paths[lang]}
          element={React.createElement(page.component)}
          exact={page.exact}
        />
      );
    });

  React.useEffect(() => {
    const authRouteArr = authRoutes.map((route) =>
      Object.keys(route.paths).map((lang) => (
        <Route
          key={route.id}
          path={route.paths[lang]}
          element={
            <ProtectedRoute
              type="auth"
              auth={auth}
              redirectTo={getRoute("login", lang).path}
            >
              <GlobalLayout
                language={app.language}
                isAuthenticated={auth.isAuthenticated}
                user={auth.user}
              >
                {React.createElement(route.component)}
              </GlobalLayout>
            </ProtectedRoute>
          }
          exact={route.exact}
        >
          {getPages(route.pages, lang)}
        </Route>
      ))
    );

    const globalRouteArr = globalRoutes.map((route) =>
      Object.keys(route.paths).map((lang) => (
        <Route
          key={route.id}
          path={route.paths[lang]}
          element={
            <GlobalLayout
              language={app.language}
              isAuthenticated={auth.isAuthenticated}
              user={auth.user}
            >
              {React.createElement(route.component)}
            </GlobalLayout>
          }
          exact={route.exact}
        >
          {getPages(route.pages, lang)}
        </Route>
      ))
    );

    const guestRouteArr = guestRoutes.map((route) =>
      Object.keys(route.paths).map((lang) => (
        <Route
          key={route.id}
          path={route.paths[lang]}
          element={
            <ProtectedRoute
              type="guest"
              auth={auth}
              redirectTo={getRoute("home", lang).path}
            >
              <GuestLayout language={app.language}>
                {React.createElement(route.component)}
              </GuestLayout>
            </ProtectedRoute>
          }
          exact={route.exact}
        >
          {getPages(route.pages, lang)}
        </Route>
      ))
    );

    const callbackRouteArr = callbackRoutes.map((route) =>
      Object.keys(route.paths).map((lang) => (
        <Route
          key={route.id}
          path={route.paths[lang]}
          element={React.createElement(route.component)}
          exact={route.exact}
        >
          {getPages(route.pages, lang)}
        </Route>
      ))
    );

    setRoutes([
      ...globalRouteArr,
      ...authRouteArr,
      ...guestRouteArr,
      ...callbackRouteArr,
    ]);
  }, [app.language, auth]);

  React.useEffect(() => setIsReady(Boolean(routes.length)), [routes]);

  return (
    isReady && (
      <Routes>
        {routes}
        <Route path="*" element={<NotFound />} />
      </Routes>
    )
  );
};

export default Router;
