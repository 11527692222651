import { useContext, useEffect, useState } from "react";
import API from "../../../../utils/API";
import UAPinsCategory from "../../../../utils/components/UAPinsCategory";
import { TranslateContext } from "../../../../utils/context/TranslateContext";

const Pins = () => {
  const [pins, setPins] = useState([]);

  const { uaTranslate } = useContext(TranslateContext);

  useEffect(() => {
    API.getPins().then(({ data: res }) => {
      setPins(res.data);
    });
  }, []);
  return (
    <div className="Pins">
      <div className="single-content-holder">
        <h4 className="content-title text-center fw-bold">
          {uaTranslate("app", "community_badges_text")}
        </h4>
        <div className="categories">
          <UAPinsCategory pins={pins} />
        </div>
      </div>
    </div>
  );
};

export default Pins;
