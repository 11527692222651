import { formatDistanceToNow } from "date-fns";
import { tr } from "date-fns/locale";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import getIcon from "../helpers/getIcon";
import getRoute from "../helpers/getRoute";

const UAWorkout = ({ workout }) => {
  return (
    <div className="UAWorkout position-relative d-flex justify-content-start align-items-end">
      <div
        className="workout-bg position-absolute w-100 h-100"
        style={{ backgroundImage: `url("${workout.image}")` }}
      />
      <div className="workout-details w-100 d-flex flex-column position-relative">
        <h6 className="title fw-bold">
          <Link
            to={getRoute("workout")
              .path.replace(":id", workout?.id)
              .replace(":slug", workout?.slug)}
            className="stretched-link"
          >
            {workout.title}
          </Link>
        </h6>
        <div className="bottom d-flex justify-content-between align-items-end">
          <div className="left d-flex justify-content-start align-items-end">
            <span className="duration fw-medium">
              <Translate id="workouts.min" data={{ min: workout?.duration }} />
            </span>
            <span className="trainer fw-medium">{workout?.trainer}</span>
            {workout?.startsAt && (
              <span className="starts-at fw-medium">
                {formatDistanceToNow(new Date(workout?.startsAt), {
                  addSuffix: true,
                  locale: tr,
                })}
              </span>
            )}
          </div>
          <div className="right">
            <div className="notification-indicator d-none">
              {getIcon("notificationBell")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UAWorkout;
