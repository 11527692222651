import FooterBottom from "./FooterBottom";
import FooterTop from "./FooterTop";

const Footer = ({ translate }) => {
  return (
    <footer className="w-100 d-flex flex-column">
      <FooterTop translate={translate} />
      <FooterBottom translate={translate} />
    </footer>
  );
};

export default Footer;
