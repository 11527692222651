import Account from "../pages/auth/Account/Account";
import Leadership from "../pages/auth/Community/Leadership/Leadership";
import Pins from "../pages/auth/Community/Pins/Pins";
import Profile from "../pages/auth/Profile/Profile";
import Subscription from "../pages/auth/Subscription/Subscription";
import Payment from "../pages/callback/Payment";
import Home from "../pages/global/Home/Home";
import Policy from "../pages/global/Policy/Policy";
import Programs from "../pages/global/Programs/Programs";
import Trainers from "../pages/global/Trainers/Trainers";
import Workouts from "../pages/global/Workouts/Workouts";
import Confirm from "../pages/auth/Confirm/Confirm";
import ForgotPassword from "../pages/guest/ForgotPassword/ForgotPassword";
import Login from "../pages/guest/Login/Login";
import Register from "../pages/guest/Register/Register";
import ResetPassword from "../pages/guest/ResetPassword/ResetPassword";
import CommunityLayout from "./Layouts/CommunityLayout/CommunityLayout";
import { default as CommunityProfile } from "../pages/auth/Community/Profile/Profile";
import { default as CommunityWorkouts } from "../pages/auth/Community/Workouts/Workouts";
import Subscribe from "../pages/global/Subscribe";

export const authRoutes = [
  {
    id: "auth-profile",
    name: "profile",
    path: "/profile",
    paths: { en: "/profile", tr: "/profil" },
    component: Profile,
    order: 1,
    exact: true,
    onUserMenu: true,
    onProfileNav: true,
  },
  {
    id: "auth-subscription",
    name: "subscription",
    path: "/subscription",
    paths: { en: "/subscription", tr: "/abonelik" },
    component: Subscription,
    order: 2,
    exact: true,
    onUserMenu: true,
    onProfileNav: true,
  },
  {
    id: "auth-account",
    name: "account",
    path: "/account",
    paths: { en: "/account", tr: "/hesabim" },
    component: Account,
    order: 3,
    exact: true,
    onUserMenu: true,
    onProfileNav: true,
  },
  {
    id: `auth-community`,
    name: "community",
    path: "/community",
    paths: { en: "/community", tr: "/topluluk" },
    component: CommunityLayout,
    onNav: true,
    onFooterNav: true,
    order: 5,
    pages: [
      {
        id: `auth-community-index`,
        name: "community-index",
        isIndex: true,
        component: Leadership,
      },
      {
        id: `auth-community-leadership`,
        name: "community-leadership",
        path: "/community/leadership",
        paths: { en: "/community/leadership", tr: "/topluluk/puan-durumu" },
        component: Leadership,
        onNav: false,
        onFooterNav: false,
        onCommunityNav: true,
        order: 1,
      },
      {
        id: `auth-community-pins`,
        name: "community-pins",
        path: "/community/pins",
        paths: { en: "/community/pins", tr: "/topluluk/rozetler" },
        component: Pins,
        onNav: false,
        onFooterNav: false,
        onCommunityNav: true,
        order: 2,
      },
      {
        id: `auth-community-workouts`,
        name: "community-workouts",
        path: "/community/workouts",
        paths: { en: "/community/workouts", tr: "/topluluk/antremanlar" },
        component: CommunityWorkouts,
        onNav: false,
        onFooterNav: false,
        onCommunityNav: true,
        order: 3,
      },
      {
        id: "auth-community-my-profile",
        name: "community-my-profile",
        path: "/community/profile",
        paths: {
          en: "/community/profile",
          tr: "/topluluk/profil",
        },
        component: CommunityProfile,
        onNav: false,
        onFooterNav: false,
        onCommunityNav: false,
      },
      {
        id: "auth-community-profile",
        name: "community-profile",
        path: "/community/profile/:id",
        paths: {
          en: "/community/profile/:id",
          tr: "/topluluk/profil/:id",
        },
        component: CommunityProfile,
        onNav: false,
        onFooterNav: false,
        onCommunityNav: false,
      },
    ],
  },
  {
    id: `auth-subscribe`,
    name: "subscribe",
    path: "subscribe",
    paths: { en: "/subscribe", tr: "/abone-ol" },
    component: Subscribe,
    slug: "subscribe",
    onNav: true,
    onFooterNav: true,
    order: 6,
    nonPremiumRoute: true,
  },
  {
    id: "auth-confirm",
    name: "confirm",
    path: "/confirm",
    paths: { en: "/confirm", tr: "/onayla" },
    component: Confirm,
    exact: true,
  },
  {
    id: "auth-confirm",
    name: "confirm",
    path: "/email-verification/:token",
    paths: {
      en: "/email-verification/:token",
      tr: "/email-verification/:token",
    },
    component: Confirm,
    exact: true,
  },
];

export const guestRoutes = [
  {
    id: "guest-login",
    name: "login",
    path: "/login",
    paths: { en: "/login", tr: "/giris-yap" },
    component: Login,
    exact: true,
  },
  {
    id: "guest-register",
    name: "register",
    path: "/register",
    paths: { en: "/register", tr: "/kayit-ol" },
    component: Register,
    exact: true,
  },
  {
    id: "guest-forgot-password",
    name: "forgot-password",
    path: "/forgot-password",
    paths: { en: "/forgot-password", tr: "/sifremi-unuttum" },
    component: ForgotPassword,
    exact: true,
  },
  {
    id: "guest-reset-password",
    name: "reset-password",
    path: "/reset-password",
    paths: {
      en: "/reset-password",
      tr: "/sifre-sifirla",
    },
    component: ResetPassword,
    exact: true,
  },
];

export const globalRoutes = [
  {
    id: "auth-workout-watch",
    name: "workout-watch",
    path: "/workouts/:id/:slug/:watch",
    paths: {
      en: "/workouts/:id/:slug/:watch",
      tr: "/antrenmanlar/:id/:slug/:watch",
    },
    component: Workouts,
  },
  {
    id: `global-home`,
    name: "home",
    path: "/",
    paths: { en: "/", tr: "/" },
    component: Home,
    onNav: true,
    order: 1,
  },
  {
    id: `global-workouts`,
    name: "workouts",
    path: "/workouts",
    paths: { en: "/workouts", tr: "/antrenmanlar" },
    component: Workouts,
    onNav: true,
    onFooterNav: true,
    order: 2,
  },
  {
    id: `global-workout`,
    name: "workout",
    path: "/workouts/:id",
    paths: { en: "/workouts/:id", tr: "/antrenmanlar/:id" },
    component: Workouts,
    pages: [
      {
        id: `global-workout-detail`,
        name: "workout",
        path: "/workouts/:id/:slug",
        paths: { en: "/workouts/:id/:slug", tr: "/antrenmanlar/:id/:slug" },
        component: Workouts,
      },
    ],
  },
  {
    id: `global-trainers`,
    name: "trainers",
    path: "/trainers",
    paths: { en: "/trainers", tr: "/egitmenler" },
    component: Trainers,
    onNav: true,
    onFooterNav: true,
    order: 3,
  },
  {
    id: `global-trainer`,
    name: "trainer",
    path: "/trainers/:id",
    paths: { en: "/trainers/:id", tr: "/egitmenler/:id" },
    component: Trainers,
    pages: [
      {
        id: `global-trainer-detail`,
        name: "trainer",
        path: "/trainers/:id/:slug",
        paths: { en: "/trainers/:id/:slug", tr: "/egitmenler/:id/:slug" },
        component: Trainers,
      },
    ],
  },
  {
    id: `global-programs`,
    name: "programs",
    path: "/programs",
    paths: { en: "/programs", tr: "/programlar" },
    component: Programs,
    onNav: true,
    onFooterNav: true,
    order: 4,
  },
  {
    id: `global-program`,
    name: "program",
    path: "/programs/:id",
    paths: { en: "/programs/:id", tr: "/programlar/:id" },
    component: Programs,
    pages: [
      {
        id: `global-program-detail`,
        name: "program",
        path: "/programs/:id/:slug",
        paths: { en: "/programs/:id/:slug", tr: "/programlar/:id/:slug" },
        component: Programs,
      },
    ],
  },
  {
    id: `global-contact`,
    name: "contact",
    path: "/contact",
    paths: { en: "/contact", tr: "/iletisim" },
    component: Home,
    onFooterNav: true,
    order: 6,
  },
  {
    id: `global-prices`,
    name: "prices",
    path: "/prices",
    paths: { en: "/prices", tr: "/fiyatlar" },
    component: Home,
    onFooterNav: true,
    order: 7,
  },
  {
    id: `global-faq`,
    name: "faq",
    path: "/faq",
    paths: { en: "/faq", tr: "/sss" },
    component: Home,
    onFooterNav: true,
    order: 8,
  },
  {
    id: `global-terms-and-conditions`,
    name: "terms-and-conditions",
    path: "terms-and-conditions",
    paths: {
      en: "/policy/terms-and-conditions",
      tr: "/sozlesme/kullanim-kosullari",
    },
    component: Policy,
    slug: "terms-and-conditions",
  },
  {
    id: `global-privacy-policy`,
    name: "privacy-policy",
    path: "privacy-policy",
    paths: {
      en: "/policy/privacy-policy",
      tr: "/sozlesme/gizlilik-politikasi",
    },
    component: Policy,
    slug: "privacy-policy",
  },
  // {
  //   id: `global-subscribe`,
  //   name: "subscribe",
  //   path: "subscribe",
  //   paths: { en: "/subscribe", tr: "/abone-ol" },
  //   component: Subscribe,
  //   slug: "subscribe",
  //   onNav: true,
  //   onFooterNav: true,
  //   order: 6,
  // },
];

export const callbackRoutes = [
  {
    id: "callback-payment",
    name: "payment",
    path: "payment/:type",
    paths: { en: "/payment/:type", tr: "/payment/:type" },
    component: Payment,
  },
];
