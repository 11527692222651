import { Helmet } from "react-helmet-async";
import useCurrentRoute from "../utils/hooks/useCurrentRoute";

const mainDescription = {
  tr: "Urban Active+, sınırsız egzersizlerle bedeninizi ve zihninizi şekillendirin. Kardiyo, yoga ve fitnessin tadını çıkarın.",
  en: "Unlimited Cardio, yoga and gym excercises accesible 24/7",
};

const SEO = ({ language, translate }) => {
  const currentRoute = useCurrentRoute();

  return (
    <Helmet titleTemplate="%s | Urban Active +" defaultTitle="Urban Active +">
      <html lang={language} className="theme-urbanactive" />
      <meta http-equiv="Content-Language" content={language} />
      <meta name="google" content="notranslate" />

      <title>
        {currentRoute &&
          currentRoute.path !== "/" &&
          translate(`page_title.${currentRoute?.name}`)}
      </title>
      <meta name="description" content={mainDescription[language]} />
    </Helmet>
  );
};

export default SEO;
