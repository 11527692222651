import { Translate } from "react-localize-redux";
import { NavLink } from "react-router-dom";
import getSubNavRoutes from "../../../utils/helpers/getSubNavRoutes";

const SubPageLayout = ({ children, page, className }) => {
  return (
    <div
      className={`SubPageLayout d-flex flex-column align-items-center ${className}`}
    >
      <nav className="page-nav w-100 d-flex justify-content-center align-items-center">
        {getSubNavRoutes(page)?.map((route) => (
          <NavLink key={route.id} to={route.path} className="item">
            <Translate id={`page_title.${route.name}`} />
          </NavLink>
        ))}
      </nav>
      <div className="page-content w-100">{children}</div>
    </div>
  );
};

export default SubPageLayout;
