import axios from "axios";
import { useEffect } from "react";
import { createRef } from "react";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CSSTransition } from "react-transition-group";
import SubPageLayout from "../../../App/Layouts/SubPageLayout/SubPageLayout";
import API from "../../../utils/API";
import ShowSuccess from "../../../utils/components/ShowSuccess";
import UAButton from "../../../utils/components/UAButton";
import UAConfirmModal from "../../../utils/components/UAConfirmModal";
import UAInput from "../../../utils/components/UAInput";
import UAModal from "../../../utils/components/UAModal";
import validatePasswordUpdate from "../../../utils/helpers/validatePasswordUpdate";
import { logout } from "../../../utils/redux/auth/authActions";
import ProfileSection from "../Profile/components/ProfileSection";
import Loading from "../../../utils/components/Loading";

const Account = ({ translate, logout }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [selectedAgreement, setSelectedAgreement] = useState({
    type: "",
    content: "",
  });
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [agreementsLoading, setAgreementsLoading] = useState(false);

  const [updatePassword, setUpdatePassword] = useState({
    current_password: "",
    new_password: "",
    new_password_confirm: "",
  });
  const [updatePasswordErrors, setUpdatePasswordErrors] = useState({
    current_password: "",
    new_password: "",
    new_password_confirm: "",
  });
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

  const agreementModal = createRef();
  const navigate = useNavigate();

  const { language } = useSelector((state) => state.app);

  useEffect(() => {
    if (!language || agreementsLoading) return;

    setAgreementsLoading(true);

    const requests = [
      API.getData("contracts/terms-and-conditions"),
      API.getData("contracts/privacy-policy"),
    ];
    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          const [termsAndConditions, privacyPolicy] = responses;
          setTermsAndConditions(termsAndConditions?.data?.data?.content);
          setPrivacyPolicy(privacyPolicy?.data?.data?.content);
        })
      )
      .then(() => setAgreementsLoading(false))
      .catch(() => setAgreementsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const confirmDeleteAccount = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <UAConfirmModal
          title={translate("account.account_deletion.title")}
          desc={translate("account.account_deletion.description")}
          buttons={[
            {
              label: translate("cancel"),
              onClick: onClose,
              type: "empty",
              disabled: isDeleting,
            },
            {
              label: translate("account.delete_account"),
              type: "danger",
              onClick: () => handleDeleteAccount(onClose),
              classNames: "btn-danger",
              disabled: isDeleting,
            },
          ]}
        />
      ),
    });
  };

  const handleDeleteAccount = (close) => {
    setIsDeleting(true);
    API.deleteData("users")
      .then(() => {
        close();
        handlePostDeleteAccount();
      })
      .catch(() => setIsDeleting(false));
  };

  const handlePostDeleteAccount = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <UAConfirmModal
          title={translate("account.account_deletion.success_title")}
          desc={translate("account.account_deletion.success_message")}
          buttons={[
            {
              label: translate("okay"),
              onClick: () => {
                logout();
                navigate("/");
                onClose();
              },
              type: "empty",
            },
          ]}
        />
      ),
    });
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();

    const validate = validatePasswordUpdate(
      updatePassword.current_password,
      updatePassword.new_password,
      updatePassword.new_password_confirm
    );

    if (validate) {
      setUpdatePasswordErrors((prevState) => ({
        ...prevState,
        [validate?.error]: validate?.message,
      }));
      return;
    }

    setIsUpdatingPassword(true);

    const { current_password, new_password } = updatePassword;

    API.putData("auth/password", {
      current_password,
      new_password,
    })
      .then(() => {
        setShowSuccess(true);
        setUpdatePassword({
          current_password: "",
          new_password: "",
          new_password_confirm: "",
        });

        setUpdatePasswordErrors({
          current_password: "",
          new_password: "",
          new_password_confirm: "",
        });
      })
      .catch((err) => {
        if (err?.message) {
          Object.keys(err?.message).forEach((key) => {
            setUpdatePasswordErrors((prevState) => ({
              ...prevState,
              [key]: err?.message[key] ? err?.message[key][0] : "",
            }));
          });
        }
      })
      .finally(() => setIsUpdatingPassword(false));
  };

  return (
    <SubPageLayout page="profile">
      <div className="Profile Account position-relative">
        <ShowSuccess
          message={translate("updates_saved")}
          show={showSuccess}
          setShow={setShowSuccess}
        />

        <CSSTransition
          in={showAgreementModal}
          nodeRef={agreementModal}
          unmountOnExit
          timeout={300}
          classNames="UAModal"
          onExited={() => setSelectedAgreement("")}
        >
          <UAModal
            propRef={agreementModal}
            handleClose={() => setShowAgreementModal(false)}
          >
            <div className="agreement-modal d-flex flex-column align-items-center">
              <h5 className="agreement-title text-center fw-bold">
                {translate(`account.${selectedAgreement?.type}`)}
              </h5>
              <div
                className="agreement-content bg-white overflow-auto"
                dangerouslySetInnerHTML={{ __html: selectedAgreement?.content }}
              />
              <UAButton
                label={translate("close")}
                type="secondary"
                onClick={() => setShowAgreementModal(false)}
                classNames="border-alternative"
              />
            </div>
          </UAModal>
        </CSSTransition>

        <div className="row">
          <div className="col-12">
            <ProfileSection title={translate("account.update_password")}>
              <form className="update-password" onSubmit={handleUpdatePassword}>
                {Object.keys(updatePassword).map((key) => (
                  <div key={key} className="row">
                    <div className="col-12 col-md-6">
                      <UAInput
                        type="password"
                        name={key}
                        value={updatePassword[key]}
                        onChange={({ target }) => {
                          setUpdatePassword((prevState) => ({
                            ...prevState,
                            [key]: target.value,
                          }));

                          if (updatePasswordErrors[key]) {
                            setUpdatePasswordErrors((prevState) => ({
                              ...prevState,
                              [key]: "",
                            }));
                          }
                        }}
                        label={translate(`account.${key}`)}
                        placeholder={translate(`account.${key}_placeholder`)}
                        theme="dark"
                        error={updatePasswordErrors[key]}
                      />
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <UAButton
                      label={translate("save")}
                      classNames="save-button text-uppercase"
                      htmlType="submit"
                      disabled={isUpdatingPassword}
                    />
                  </div>
                </div>
              </form>
            </ProfileSection>
            <ProfileSection title={translate("account.user_agreement")}>
              {agreementsLoading ? (
                <div
                  className="agreement-holder overflow-hidden d-flex align-items-center justify-content-center"
                  style={{ height: "150px" }}
                >
                  <Loading />
                </div>
              ) : (
                <div
                  className="agreement-holder overflow-hidden"
                  dangerouslySetInnerHTML={{
                    __html: termsAndConditions,
                  }}
                />
              )}
              <div className="w-100 d-flex justify-content-center">
                <UAButton
                  label={translate("read_all")}
                  classNames="save-button text-uppercase"
                  disabled={agreementsLoading}
                  onClick={() => {
                    setSelectedAgreement({
                      type: "user_agreement",
                      content: termsAndConditions,
                    });
                    setShowAgreementModal(true);
                  }}
                />
              </div>
            </ProfileSection>
            <ProfileSection title={translate("account.privacy_agreement")}>
              {agreementsLoading ? (
                <div
                  className="agreement-holder overflow-hidden d-flex align-items-center justify-content-center"
                  style={{ height: "150px" }}
                >
                  <Loading />
                </div>
              ) : (
                <div
                  className="agreement-holder overflow-hidden"
                  dangerouslySetInnerHTML={{ __html: privacyPolicy }}
                />
              )}
              <div className="w-100 d-flex justify-content-center">
                <UAButton
                  label={translate("read_all")}
                  classNames="save-button text-uppercase"
                  disabled={agreementsLoading}
                  onClick={() => {
                    setSelectedAgreement({
                      type: "privacy_agreement",
                      content: termsAndConditions,
                    });
                    setShowAgreementModal(true);
                  }}
                />
              </div>
            </ProfileSection>
            <ProfileSection title={translate("account.account_management")}>
              <UAButton
                label={translate("account.delete_account")}
                type="secondary"
                classNames="save-button text-uppercase"
                onClick={confirmDeleteAccount}
              />
            </ProfileSection>
          </div>
        </div>
      </div>
    </SubPageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(Account);
