import { concat } from "lodash";
import { matchRoutes, useLocation } from "react-router";
import { authRoutes, globalRoutes, guestRoutes } from "../../App/routes";

const useCurrentRoute = () => {
  const routes = concat(globalRoutes, authRoutes, guestRoutes);

  const location = useLocation();
  const [{ route }] = matchRoutes(
    [
      ...routes.map((route) => ({ ...route, path: route?.paths?.tr })),
      ...routes.map((route) => ({ ...route, path: route?.paths?.en })),
      ...routes
        .filter((route) => route.pages)
        .map((route) =>
          route.pages.map((page) => ({ ...page, path: page?.paths?.tr }))
        )
        .flat(1),
      ...routes
        .filter((route) => route.pages)
        .map((route) =>
          route.pages.map((page) => ({ ...page, path: page?.paths?.en }))
        )
        .flat(1),
    ],
    location
  ) || [{}];

  return route || null;
};

export default useCurrentRoute;
