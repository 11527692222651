import store from "../../store";
import { setUser } from "../redux/auth/authActions";

const updateUser = (user) => {
  // dispatch from helper function
  const { dispatch } = store;

  const userModel = {
    id: user.id,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    gsm: user.gsm,
    avatar: user.avatar,
    locale: user.locale,
  };

  dispatch(setUser(userModel));
};

export default updateUser;
