import store from "../../store";
import API from "../API";
import { login } from "../redux/auth/authActions";

export const handleLoginWithSocialMedia = ({
  provider,
  token,
  firstname = null,
  lastname = null,
}) => {
  if (provider && token) {
    API.loginOrRegisterWithSocialAccount({
      provider,
      token,
      firstname,
      lastname,
    }).then(({ data: res }) => {
      API.getAccountData(res.data.access_token).then(({ data: accountRes }) => {
        store.dispatch(login({ ...res.data, ...accountRes.data }));
      });
    });
  }
};
