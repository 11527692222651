import { useContext } from "react";
import { TranslateContext } from "../../../context/TranslateContext";

const Subscriptions = ({
  subscriptions,
  setSelectedSubscription,
  selectedSubscription,
}) => {
  const { uaTranslate } = useContext(TranslateContext);
  return (
    <section className="Paywall__content__section d-flex flex-column w-100">
      <h4 className="Paywall__content__section--title">
        {uaTranslate("app", "paywall_web_package_title")}
      </h4>
      <div className="packages">
        {subscriptions.map((item) => (
          <div
            key={item?.id}
            className="package-item d-flex justify-content-start align-items-center"
            role="button"
            onClick={() => setSelectedSubscription(item)}
            data-active={selectedSubscription?.id === item.id}
          >
            <div className="selected-icon flex-shrink-0"></div>
            <div className="package-term d-flex flex-column align-items-start w-100 flex-grow-1">
              <h5 className="package-title">
                {item.title}
                {item?.titleExtra && (
                  <p className="h6 m-0">
                    <small>{item.titleExtra}</small>
                  </p>
                )}
              </h5>
              <p className="package-subtitle mb-0">
                {uaTranslate("app", "paywall_web_package_content")}
              </p>
            </div>
            <div className="package-price d-flex flex-column align-items-end flex-shrink-0">
              <span className="price d-flex justify-content-end align-items-end">
                <span className="number">{parseInt(item.monthlyPrice)}</span>,
                <span>{String(item.monthlyPrice)?.split(".")?.[1]}</span>{" "}
                {item.currency}
              </span>
              {item.recurring !== "month" ||
              (item.recurring === "month" && item.recurring_count !== 1) ? (
                <span className="price-description">
                  {uaTranslate("app", "paywall_monthly_price_format")}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Subscriptions;
