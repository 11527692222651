import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import getIcon from "../../../../utils/helpers/getIcon";
import getRoute from "../../../../utils/helpers/getRoute";

const Program = ({ program }) => {
  return (
    <div className="Program position-relative d-flex align-items-end">
      <Link
        to={
          getRoute("program").path.replace(":id", program.id) +
          "/" +
          program.slug
        }
        className="program-bg w-100 h-100 position-absolute"
        style={{ backgroundImage: `url("${program?.image}")` }}
      ></Link>
      <Link
        to={
          getRoute("program").path.replace(":id", program.id) +
          "/" +
          program.slug
        }
        className="detail-link d-flex justify-content-center align-items-center position-absolute"
        role="button"
      >
        {getIcon("chevronRight")}
      </Link>
      <div className="info-area d-flex flex-column align-items-end position-relative w-100">
        <h3 className="program-title w-100 fw-bold d-flex align-items-end justify-content-start">
          <Link
            to={
              getRoute("program").path.replace(":id", program.id) +
              "/" +
              program.slug
            }
            className="stretched-link"
          >
            {program?.title}
          </Link>
        </h3>
        <div className="more-info d-flex justify-content-between align-items-center w-100">
          <div className="program-days fw-medium">
            {program?.day_count}{" "}
            <Translate id={program?.day_count > 1 ? `days` : `day`} />
          </div>
          <div className="program-difficulty fw-medium">
            {program?.difficulty} <Translate id="level" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program;
