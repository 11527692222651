import { useEffect } from "react";
import { useState } from "react";
import UACheckbox from "../../../../utils/components/UACheckbox";
import UAButton from "../../../../utils/components/UAButton";
import { Translate } from "react-localize-redux";
import API from "../../../../utils/API";

const CheckboxList = ({ user, checkboxList, showSuccess, type }) => {
  const [checkboxes, setCheckboxes] = useState(null);
  const [defaultCheckboxes, setDefaultCheckboxes] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (checkboxList?.length) {
      const checkboxes = {};
      checkboxList.forEach((checkbox) => {
        checkboxes[checkbox.key] = user[type]?.includes(checkbox.key);
      });
      setCheckboxes(checkboxes);
      setDefaultCheckboxes(checkboxes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxList, user]);

  useEffect(() => {
    let isButtonDisabled = true;

    if (checkboxes && defaultCheckboxes)
      Object.keys(checkboxes).forEach((checkbox) => {
        if (checkboxes[checkbox] !== defaultCheckboxes[checkbox]) {
          isButtonDisabled = false;
        }
      });

    setIsButtonDisabled(isButtonDisabled);
  }, [defaultCheckboxes, checkboxes]);

  const handleSave = () => {
    const newCheckboxes = Object.keys(checkboxes).filter(
      (checkbox) => checkboxes[checkbox]
    );

    setIsUpdating(true);

    API.updateUser({ [type]: newCheckboxes })
      .then(() => {
        showSuccess();
      })
      .finally(() => {
        setDefaultCheckboxes(checkboxes);
        setIsUpdating(false);
      });
  };

  return (
    <div className="Equipments">
      <div className="row">
        {checkboxList
          ?.filter((checkbox) => checkboxes && [checkbox.key] in checkboxes)
          .map((checkbox) => (
            <div className="col-12 col-sm-6 mt-4" key={checkbox.key}>
              <UACheckbox
                label={checkbox.value}
                name={checkbox.key}
                checked={checkboxes[checkbox.key]}
                onChange={(e) => {
                  setCheckboxes({
                    ...checkboxes,
                    [checkbox.key]: e.target.checked,
                  });
                }}
                disabled={isUpdating}
              />
            </div>
          ))}
      </div>
      <div className="row mt-5">
        <div className="col-12 d-flex justify-content-center">
          <UAButton
            label={<Translate id="save" />}
            onClick={handleSave}
            classNames="save-button text-uppercase"
            disabled={isUpdating || isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckboxList;
