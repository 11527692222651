import { Link } from "react-router-dom";
import getIcon from "../../../../utils/helpers/getIcon";
import getRoute from "../../../../utils/helpers/getRoute";

const Header = ({ pageTitle }) => {
  return (
    <div className="guest-layout-header">
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Link to={getRoute("home").path}>
            {getIcon("logo", { alternative: "light" })}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <h6 className="page-title color--white text-uppercase">
            {pageTitle}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Header;
