import { authRoutes, globalRoutes, guestRoutes } from "../../App/routes";

const getRoute = (name, lang, parentRoute) => {
  let route = {};
  if (parentRoute) {
    route = parentRoute.pages.find((page) => page.name === name);
    route["parent"] = parentRoute;
  } else {
    const global = globalRoutes.find((route) => route.name === name);
    const auth = authRoutes.find((route) => route.name === name);
    const guest = guestRoutes.find((route) => route.name === name);

    route = global || auth || guest || authRoutes[0];
  }

  if (route?.paths) route["path"] = route.paths[lang || "tr"];

  if (route.pages) {
    route.pages
      .filter((page) => !page?.isIndex)
      .forEach((page) => {
        page["path"] = page.paths[lang || "tr"];
      });
  }

  return route;
};

export default getRoute;
