import React, { useContext, useState } from "react";
import API from "../API";
import UASelect from "./UASelect";
import { contentLanguages } from "../db/contentLanguages";
import { TranslateContext } from "../context/TranslateContext";

const ContentLanguageSelect = ({ path, handleSet, activeLanguage }) => {
  const [selectedContentLanguage, setSelectedContentLanguage] = useState({
    value: activeLanguage.code,
    label: activeLanguage.name,
  });

  const { uaTranslate } = useContext(TranslateContext);

  const handleSelect = (selectedLanguage) => {
    setSelectedContentLanguage(selectedLanguage);

    API.getData(`${path}?languages_filter=${selectedLanguage.value}`).then(
      ({ data: res }) => {
        handleSet(res.data);
      }
    );
  };

  return (
    <div className="ContentLanguageSelect d-flex flex-column flex-md-row justify-content-md-end align-items-start align-items-md-center gap-3">
      <span className="fw-semibold flex-shrink-0">
        {uaTranslate("app", "content_language")}
      </span>
      <UASelect
        name="contentLanguage"
        value={selectedContentLanguage}
        options={contentLanguages.map((language) => ({
          value: language.code,
          label: language.label,
        }))}
        onChange={(value) => handleSelect(value)}
      />
    </div>
  );
};

export default ContentLanguageSelect;
