import { authRoutes, globalRoutes, guestRoutes } from "../../App/routes";
import getRoute from "./getRoute";

const getSubNavRoutes = (page) => {
  let routes = [...globalRoutes, ...authRoutes, ...guestRoutes];

  switch (page) {
    case "profile":
      routes = routes.filter((route) => route.onProfileNav);
      break;
    case "community":
      routes = routes.filter((route) => route.onCommunityNav);
      break;
    default:
      routes = [];
      break;
  }

  return routes
    .sort((a, b) => a.order - b.order)
    .map((route) => getRoute(route.name));
};

export default getSubNavRoutes;
